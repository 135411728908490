import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  getAllBlogsDataAction,
  getAllUsersDataAction,
  getFilesDataAction,
} from "../../../../../redux/master/actions";
import { arrRange } from "../../../../../helpers/Utils";
import { FileCategoryTypeOptions } from "../../../../../constants/selectOptions";

const BlogFilterSection = (props) => {
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const { searchParams, setSearchParams } = props;
  const dispatch = useDispatch();
  const { users: allUsers } = useSelector((state) => state.master.allUsers);

  useEffect(() => {
    dispatch(getAllUsersDataAction());
  }, []);

  useEffect(() => {
    let allRows = [];
    allUsers?.map((x) => {
      allRows.push({
        label: (
          <span className="">
            <strong>
              {x.first_name} {x.last_name}
            </strong>
            <br /> {x.username}
          </span>
        ),
        value: x.id,
        email: x.username,
        name: x.first_name + " " + x.last_name,
      });
    });
    setUserSelectOptions(allRows);
  }, [allUsers]);

  useEffect(() => {
    var newObj = {};
    Object.keys(searchParams).map((key) => {
      if (searchParams[key]) {
        if (searchParams[key].value.toString().length > 0)
          newObj[key] = searchParams[key].value;
      }
    });
    if (
      (!newObj.file_name || newObj.file_name?.length > 2) &&
      Object.keys(searchParams).length > 0
    ) {
      dispatch(getFilesDataAction(newObj));
    }
  }, [searchParams]);

  const customFilter = (option, searchText) => {
    if (
      option.data.email.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex">
        <div className="student-filter-section">
          <div className="w-250px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">
              Select File Author
            </label>
            <Select
              options={userSelectOptions}
              isClearable
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  user: data,
                });
              }}
              filterOption={customFilter}
            />
          </div>

          <div className="w-150px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Label</label>
            <Select
              options={FileCategoryTypeOptions}
              isClearable
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  category: data,
                });
              }}
            />
          </div>
          <div className="w-150px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Year</label>
            <Select
              options={
                arrRange(2023, new Date().getFullYear(), 1).selectOptions
              }
              isClearable
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  year: data,
                });
              }}
            />
          </div>

          <div className="w-150px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Month</label>
            <Select
              options={arrRange(1, 12, 1).selectOptions}
              isClearable
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  month: data,
                });
              }}
            />
          </div>

          <div className="w-150px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Date</label>
            <Select
              options={arrRange(1, 31, 1).selectOptions}
              isClearable
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  date: data,
                });
              }}
            />
          </div>

          <div className="w-200px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Search</label>
            <input
              type={"text"}
              className="form-control br-10px"
              placeholder="Search..."
              value={
                searchParams.file_name ? searchParams.file_name?.value : ""
              }
              onChange={(data) =>
                setSearchParams({
                  ...searchParams,
                  file_name: { value: data.target.value },
                })
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlogFilterSection;
