import React, { Suspense, useEffect } from "react";
import { withRouter, Switch, Redirect, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import MasterAdminPanel from "./masteradmin";
import StudentPanel from "./student";
import InstitutePanel from "./institute";

import { useSelector } from "react-redux";
import { ProtectedRoute } from "../../helpers/authHelper";
import AlertMessage from "../../helpers/alerts";
const queryString = require("query-string");

const MainApp = (props) => {
  const { match } = props;
  const history = useHistory();
  let { token } = queryString.parse(props.location.search);
  if (token) {
    localStorage.setItem("authToken", token);
    history.push(props.location.pathname);
  }

  let isError = useSelector((state) => state.master.error);

  let FinalComponent = null;
  if (
    props.subDomain === process.env.REACT_APP_ADMIN_SUB_DOMAIN ||
    props.subDomain === process.env.REACT_APP_STAFF_SUB_DOMAIN
  ) {
    FinalComponent = MasterAdminPanel;
  } else if (props.subDomain === process.env.REACT_APP_INSTITUTE_SUB_DOMAIN) {
    FinalComponent = InstitutePanel;
  } else if (props.subDomain === process.env.REACT_APP_STUDENT_SUB_DOMAIN) {
    FinalComponent = StudentPanel;
  } else {
    // window.location.href = process.env.REACT_APP_LOGIN_URL;
  }

  useEffect(() => {
    if (isError) {
      AlertMessage(isError.msg, "error", isError.subTitle);
    }
  }, [isError]);

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <ProtectedRoute
          subDomain={props.subDomain}
          path={`${match.url}`}
          component={FinalComponent}
        />
        <Redirect to="/dashboard/" />
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};

export default withRouter(connect(mapStateToProps, {})(MainApp));
