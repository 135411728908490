import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import Switch from "rc-switch";
import downloadIcon from "../../../../../assets/img/icons/solid/download.svg";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  addEnquireDataAction,
  deleteEnquireDataAction,
  deleteFBLeadsDataAction,
  errorHandler,
  getEnquireDataAction,
  getFBLeadFormDataAction,
  getFBLeadsDataAction,
  updateEnquireDataAction,
} from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import {
  formateDate,
  processSearchParamsObj,
  requiredFieldValidation,
} from "../../../../../helpers/Utils";
import CustomeTable from "../../../../../components/common/table";
import { enquiriesToContactAPI } from "../../../../../services/whatsapp";
import {
  createNewFBLeadsAPI,
  updateFBLeadsAPI,
  uploadEnquireAPI,
} from "../../../../../services/master";
import { getWAPGroupDataAction } from "../../../../../redux/whatsapp/actions";
import downloadCSV from "../../../../../helpers/csvDownload";

const FBEnquires = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [leadFormSelectOption, setLeadFormSelectOption] = useState([]);
  const [wapGroupSelectOptions, setWapGroupSelectOptions] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  let defaultValues = {
    form: null,
    name: "",
    email: "",
    mobile: "",
    city: "",
    profession: "",
    institute: "",
    created_time: new Date(),
    remark: "",
    next_followup_date: "",
    is_closed: false,
  };
  const [values, setValues] = useState(defaultValues);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const dispatch = useDispatch();
  const { data: fetchedData, count: totalCount } = useSelector(
    (state) => state.master.fbLeads
  );
  const allGroups = useSelector((state) => state.wap.allGroups);
  const fbLeadForms = useSelector((state) => state.master.fbForms);

  useEffect(() => {
    let allOptions = [];
    fbLeadForms.map((data, index) => {
      allOptions.push({ label: data.name, value: data.id, key: index });
    });

    setLeadFormSelectOption(allOptions);
  }, [fbLeadForms]);

  useEffect(() => {
    let allRows = [];
    allGroups?.map((x) => {
      allRows.push({
        label: x.name,
        value: x.id,
      });
    });
    setWapGroupSelectOptions(allRows);
  }, [allGroups]);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: (
          <span>
            #{" "}
            <input
              checked={selectedIds?.length === fetchedData?.length}
              type="checkbox"
              onChange={() => processChatIds("", true)}
            />
          </span>
        ),
        field: "sn",
        width: 80,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },
      {
        label: "Name",
        field: "name",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        width: 250,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Remark",
        field: "remark",
        width: 210,
      },
      {
        label: "City",
        field: "city",
        width: 150,
      },
      {
        label: "Institute",
        field: "institute",
        width: 150,
      },
      {
        label: "Course",
        field: "course",
        width: 300,
      },
      {
        label: "Who You Are ?",
        field: "profession",
        width: 210,
      },
      {
        label: "Date",
        field: "created_time",
        width: 210,
      },
      {
        label: "FollowUp Date",
        field: "next_followup_date",
        width: 210,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  const onSubmitHandler = async () => {
    let [isExist, field] = requiredFieldValidation(values, [
      "email",
      "city",
      "profession",
      "institute",
      "remark",
      "next_followup_date",
      "is_closed",
    ]);
    if (!isExist) {
      AlertMessage(`Field - ${field}`, "error", "This field required");
      return;
    }

    let newValues = { ...values };
    if (values.form?.value) {
      newValues = { ...newValues, form: values.form?.value };
    }

    let finalMethod = createNewFBLeadsAPI;
    let obj = {};
    if (isEditing) {
      finalMethod = updateFBLeadsAPI;
      obj._id = currentData.id;
    }

    try {
      await finalMethod(newValues, obj);
      obj = processSearchParamsObj(searchParams);
      dispatch(getFBLeadsDataAction(obj));
      setIsAdding(false);
      setIsEditing(false);
      setCurrentData(null);
      setValues(defaultValues);
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const processChatIds = (_id, isAll = false) => {
    if (!isAll) {
      if (selectedIds.includes(_id)) {
        let remainIds = selectedIds.filter((x) => x !== _id);
        setSelectedIds(remainIds);
      } else {
        setSelectedIds([...selectedIds, _id]);
      }
    } else {
      if (selectedIds?.length > 0) {
        setSelectedIds([]);
      } else {
        let allIds = [];
        fetchedData?.map((x) => allIds.push(x.id));
        setSelectedIds(allIds);
      }
    }
  };

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: (
          <span>
            {index + 1}{" "}
            <input
              onChange={() => processChatIds(data.id)}
              checked={selectedIds.includes(data.id)}
              type="checkbox"
            />
          </span>
        ),
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        institute: data.institute,
        course: data.course,
        created_time: formateDate(data.created_time),
        city: data.city,
        profession: data.profession,
        remark: data.remark,
        next_followup_date: data.next_followup_date
          ? formateDate(data.next_followup_date)
          : "",
        action: (
          <span>
            <i
              onClick={() => {
                onEditClick(data);
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    let allColumns = datatable.columns;
    let oneColumn = allColumns[0];
    oneColumn = {
      ...oneColumn,
      label: (
        <span>
          #{" "}
          <input
            checked={selectedIds?.length === fetchedData?.length}
            type="checkbox"
            onChange={() => processChatIds("", true)}
          />
        </span>
      ),
    };

    allColumns[0] = oneColumn;

    setDatatable({
      ...datatable,
      rows: allRows,
      columns: allColumns,
    });
  }, [fetchedData, leadFormSelectOption, selectedIds]);

  useEffect(() => {
    dispatch(getFBLeadFormDataAction());
    dispatch(getWAPGroupDataAction());
  }, []);

  const onDeleteClick = (_id) => {
    if (!_id && selectedIds?.length > 0) {
      selectedIds.map((_id) => {
        dispatch(deleteFBLeadsDataAction(_id));
      });
      setSelectedIds([]);
    } else {
      dispatch(deleteFBLeadsDataAction(_id));
    }

    setCurrentData(null);
    setIsDeleting(false);
    setTimeout(
      () => {
        let obj = processSearchParamsObj(searchParams);
        dispatch(getFBLeadsDataAction(obj));
      },
      !_id && selectedIds?.length > 0 ? 1000 : 200
    );
  };

  const onEnquiriesToWAPAccount = async () => {
    try {
      if (!selectedGroup) {
        AlertMessage("Whatsapp Group Required", "error");
        return;
      }
      let values = {
        enquiryIds: selectedIds,
        selectedGroup,
        model: "fbenquiry",
      };
      await enquiriesToContactAPI(values);
      setSelectedIds([]);
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const onPageChange = (e) => {
    setSearchParams({ ...searchParams, ...e });
  };

  const fetchLeadsOnParamsChange = () => {
    let obj = processSearchParamsObj(searchParams);
    if (
      (!obj.search || obj.search?.length > 2) &&
      Object.keys(searchParams).length > 0
    ) {
      dispatch(getFBLeadsDataAction(obj));
    }
  };

  useEffect(() => {
    fetchLeadsOnParamsChange();
  }, [searchParams]);

  const onEditClick = (data) => {
    let valuesObj = {
      name: data.name,
      email: data.email,
      mobile: data.mobile,
      institute: data.institute,
      city: data.city,
      profession: data.profession,
      remark: data.remark,
      next_followup_date: data.next_followup_date,
      form: leadFormSelectOption.find((x) => x.value === data.form?.id),
    };

    setValues(valuesObj);
    setIsEditing(true);
    setCurrentData(data);
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  const downloadFilteredData = () => {
    let newData = [];
    fetchedData?.map((oneEle) => {
      let oneObj = {
        name: oneEle.name,
        mobile: oneEle.mobile,
        email: oneEle.email,
        course: oneEle.course || "",
        city: oneEle?.city || "",
        institute: oneEle.institute,
        year: oneEle.year,
      };
      newData.push(oneObj);
    });
    if (newData?.length > 0) {
      downloadCSV(newData);
    }
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of FaceBook Leads
              <p className="fs-14px c_black-60p">
                Here we have a list of leads on facebook, which is coming from
                different types of ads.
              </p>
            </span>
            <div className="d-flex align-items-center">
              {selectedIds.length === 0 && (
                <button
                  onClick={() => {
                    setIsAdding(true);
                  }}
                  className="shadow-box create-new-button"
                >
                  Add New Lead
                </button>
              )}

              {selectedIds.length > 0 && (
                <div className="mb-10px">
                  <button
                    className="shadow-box create-new-button bg-black-60p ml-10px"
                    onClick={() => {
                      onDeleteClick();
                    }}
                  >
                    Delete Selected Enquires
                  </button>
                </div>
              )}
            </div>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <div className="d-flex mb-20px mt-n20px align-items-center flex-wrap">
        <div className="w-332px mr-10px">
          <label className="input-label fw-400 fs-14px">Search</label>
          <input
            type={"text"}
            className="form-control br-10px"
            placeholder="Search..."
            value={searchParams.search ? searchParams.search?.value : ""}
            onChange={(data) =>
              setSearchParams({
                ...searchParams,
                search: { value: data.target.value },
              })
            }
          />
        </div>

        <div className="w-332px mr-10px">
          <label className="input-label fw-400 fs-14px">Select Lead Form</label>
          <Select
            isClearable
            options={leadFormSelectOption}
            className="react-select"
            classNamePrefix="react-select"
            value={searchParams.form ? searchParams.form : ""}
            onChange={(data) =>
              setSearchParams({
                ...searchParams,
                form: data,
              })
            }
          />
        </div>
      </div>
      <Row>
        {!isAdding && !isEditing && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div
                  className="card-body accordion-content pt-0"
                  style={{ maxWidth: "100%" }}
                >
                  {selectedIds?.length > 0 && (
                    <div className="mr-10px d-flex justify-content-flex-end mb-n50px mt-10px">
                      <Select
                        placeholder="Select Group"
                        className="w-303px mr-10px"
                        isClearable
                        options={wapGroupSelectOptions}
                        onChange={(data) => {
                          setSelectedGroup(data?.value || null);
                        }}
                      />
                      <button
                        className="shadow-box create-new-button bg-ed7117"
                        onClick={onEnquiriesToWAPAccount}
                      >
                        Add to Whatsapp
                      </button>
                    </div>
                  )}
                  <div className="position-absolute w-100percent d-flex justify-content-flex-end mt-20px pr-30px">
                  {datatable?.rows?.length > 0 && (
                    <img
                      src={downloadIcon}
                      alt="filter-icon"
                      onClick={() => {
                        downloadFilteredData();
                      }}
                      className="w-20px filter-icon mr-20px"
                    />
                  )}
                  </div>
                  <CustomeTable
                    onPageChange={onPageChange}
                    searchParams={searchParams}
                    datatable={datatable}
                    total={totalCount}
                  />
                </div>
              </div>
            </Card>
          </Colxx>
        )}
        {(isAdding || isEditing) && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div className="card-body accordion-content pt-0">
                  <Row className="mt-30px">
                    <Colxx lg="12" xl="4">
                      <label className="input-label fw-400 fs-14px">
                        Select Lead Form
                      </label>
                      <Select
                        isClearable
                        options={leadFormSelectOption}
                        className="react-select"
                        classNamePrefix="react-select"
                        value={values.form}
                        onChange={(data) =>
                          setValues({ ...values, form: data })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">Name</label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. AR - VR"
                        value={values.name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Email
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="TechSimPlus"
                        value={values.email}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            email: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Mobile
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="Prateek Mishra"
                        value={values.mobile}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            mobile: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">City</label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. training@techsimplus.com"
                        value={values.city}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            city: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Who Are You ?
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. +91 9893762256"
                        value={values.profession}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            profession: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Institute
                      </label>
                      <input
                        className="form-control br-10px"
                        placeholder="ex. Institute Name."
                        value={values.institute}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            institute: data.target.value,
                          })
                        }
                      />
                    </Colxx>
                    <hr />
                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Remark
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. student want to join next batch"
                        value={values.remark}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            remark: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Next Followup Date
                      </label>
                      <DatePicker
                        selected={
                          values.next_followup_date
                            ? new Date(values.next_followup_date)
                            : null
                        }
                        dateFormat="dd-MMM-yy"
                        onChange={(d) => {
                          setValues({ ...values, next_followup_date: d });
                        }}
                      />
                    </Colxx>

                    <Colxx lg="12" xl="1" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        {!values.is_closed ? "Close Enquire" : "Re-Open Again"}
                      </label>
                      <Switch
                        className="custom-switch custom-switch-3-inverse custom-switch"
                        checked={!values.is_closed}
                        onChange={(status) =>
                          setValues({ ...values, is_closed: !status })
                        }
                      />
                    </Colxx>

                    <div className="d-flex justify-content-flex-end w-100percent">
                      <Button
                        onClick={() => {
                          setIsAdding(false);
                          setIsEditing(false);
                          setCurrentData(null);
                          setValues(defaultValues);
                        }}
                        size={"sm"}
                        color="dark"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onSubmitHandler}
                        size={"sm"}
                        color="secondry"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Create
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Card>
          </Colxx>
        )}
      </Row>
    </React.Fragment>
  );
};

export default FBEnquires;
