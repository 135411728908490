import React, { useState, useEffect } from "react";
import { Button, Card, Row, Collapse } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../../components/common/CustomBootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  courseLabelOptions,
  masterCourseKeyOptions,
  yesNoOptions,
} from "../../../../../../constants/selectOptions";
import AlertMessage from "../../../../../../helpers/alerts";
import {
  addNewCourseAPI,
  updateCourseDetailsAPI,
} from "../../../../../../services/master";
import { requiredFieldValidation } from "../../../../../../helpers/Utils";

const CourseBasicDetails = (props) => {
  const { isAdding, isEditing } = props;

  const currentCourse = useSelector((state) => state.master.oneCourse);
  let defaultValues = {
    label: "",
    name: "",
    html_title: "",
    image_sm: "",
    image: "",
    duration: "",
    actual_fee: "",
    discounted_fee: "",
    actual_fee_doller: "",
    discounted_fee_doller: "",
    number_of_students: "",
    url_1: "",
    url_2: "",
    order: "",
    basic_description: "",
    long_description: "",
    job_tags_description: "",
    master_program_key: "",
    projects: 5,
    course_mode: "Live Classes",
    intro_video: "",
    is_active: { label: "Yes", value: true },
    wap_group:""
  };
  const [values, setValues] = useState(defaultValues);

  const onSubmitHandler = async () => {
    let [isExist, field] = requiredFieldValidation(values, [
      "basic_description",
      "long_description",
      "master_program_key",
      "job_tags_description",
      "intro_video",
      "image",
      "image_sm",
      'wap_group'
    ]);
    if (!isExist) {
      AlertMessage(`Field - ${field}`, "error", "This field required");
      return;
    }

    let newValues = {
      ...values,
      label: values.label.value,
      is_active: values.is_active.value,
    };
    let finalMethod = addNewCourseAPI;
    if (isEditing) {
      finalMethod = updateCourseDetailsAPI;
    }
    finalMethod(newValues, isAdding ? {} : { _id: currentCourse.id }).then(
      (res) => {
        // props.setIsAdding(false);
        // props.setIsEditing(false);
        if (isEditing) {
          AlertMessage("Course Basic Details Updated");
        }
        props.history.push(`${props.history.location.pathname}`);
      },
      (error) => {
        AlertMessage("Internal Server Error. Try again latter");
      }
    );
  };

  useEffect(() => {
    if (currentCourse) {
      let oneObj = {};
      Object.keys(defaultValues).map((key) => {
        if (currentCourse[key] === null || currentCourse[key] === "") {
          oneObj[key] = "";
        } else {
          oneObj[key] = currentCourse[key];
        }
      });

      let oneCourseLabel = null;
      let oneActive = { label: "No", value: false };
      if (oneObj.label) {
        oneCourseLabel = courseLabelOptions.find(
          (x) => x.value === oneObj.label
        );
      }

      if (oneObj.is_active) {
        oneActive = yesNoOptions.find((x) => x.value === oneObj.is_active);
      }

      if (oneCourseLabel) {
        oneObj = { ...oneObj, label: oneCourseLabel, is_active: oneActive };
      }
      if (isEditing) setValues(oneObj);
    }
  }, [currentCourse]);

  return (
    <div className="card-body accordion-content pt-0">
      <Row className="">
        <Colxx lg="12" xl="6">
          <label className="input-label fw-600 fs-14px">Course Name</label>
          <input
            placeholder="Python and django development"
            className="form-control  br-4px"
            value={values.name}
            onChange={(e) => {
              setValues({
                ...values,
                name: e.target.value,
                url_1: e.target.value?.trim().split(" ").join("-"),
              });
            }}
          />
        </Colxx>

        <Colxx lg="12" xl="6">
          <label className="input-label fw-600 fs-14px">HTML Title</label>
          <input
            placeholder="PyDjango: Python and django development"
            className="form-control  br-4px"
            value={values.html_title}
            onChange={(e) =>
              setValues({ ...values, html_title: e.target.value })
            }
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">Course Label</label>
          <Select
            isClearable
            options={courseLabelOptions}
            className="react-select"
            // classNamePrefix="react-select"
            value={values.label}
            onChange={(e) => setValues({ ...values, label: e })}
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">Course Duration</label>
          <input
            placeholder="2 months"
            value={values.duration}
            onChange={(e) => setValues({ ...values, duration: e.target.value })}
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">Course Mode</label>
          <input
            placeholder="Live Clsses"
            value={values.course_mode}
            onChange={(e) =>
              setValues({ ...values, course_mode: e.target.value })
            }
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">Course Order</label>
          <input
            type={"number"}
            placeholder="A2"
            value={values.order}
            onChange={(e) => setValues({ ...values, order: e.target.value })}
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Number of Students
          </label>
          <input
            type={"text"}
            value={values.number_of_students}
            onChange={(e) =>
              setValues({ ...values, number_of_students: e.target.value })
            }
            placeholder="1245"
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Number of Projects
          </label>
          <input
            type={"text"}
            value={values.projects}
            onChange={(e) => setValues({ ...values, projects: e.target.value })}
            placeholder="1245"
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="6" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Course Banner Image
          </label>
          <input
            value={values.image}
            onChange={(e) => setValues({ ...values, image: e.target.value })}
            placeholder="https://"
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="6" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Course Logo Image
          </label>
          <input
            value={values.image_sm}
            onChange={(e) => setValues({ ...values, image_sm: e.target.value })}
            placeholder="https://"
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">Actual Fee</label>
          <input
            type={"number"}
            placeholder="Rs. 5500"
            value={values.actual_fee}
            onChange={(e) =>
              setValues({ ...values, actual_fee: e.target.value })
            }
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">Discounted Fee</label>
          <input
            type={"number"}
            placeholder="Rs. 3500"
            value={values.discounted_fee}
            onChange={(e) =>
              setValues({ ...values, discounted_fee: e.target.value })
            }
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">Actual Fee ($)</label>
          <input
            type={"number"}
            placeholder="$5500"
            value={values.actual_fee_doller}
            onChange={(e) =>
              setValues({ ...values, actual_fee_doller: e.target.value })
            }
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Discounted Fee ($)
          </label>
          <input
            type={"number"}
            placeholder=" $3500"
            value={values.discounted_fee_doller}
            onChange={(e) =>
              setValues({ ...values, discounted_fee_doller: e.target.value })
            }
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Master Course Key
          </label>
          <Select
            isClearable
            options={masterCourseKeyOptions}
            className="react-select"
            // classNamePrefix="react-select"
            value={masterCourseKeyOptions.find(
              (x) => x.value === values.master_program_key
            )}
            onChange={(e) =>
              setValues({ ...values, master_program_key: e ? e.value : e })
            }
          />
        </Colxx>

        <Colxx lg="12" xl="2" className="mt-3">
          <label className="input-label fw-600 fs-14px">Is Active</label>

          <Select
            options={yesNoOptions}
            className="react-select"
            // classNamePrefix="react-select"
            value={values.is_active}
            onChange={(e) => setValues({ ...values, is_active: e })}
          />
        </Colxx>
        

        <Colxx lg="12" xl="6" className="mt-3">
          <label className="input-label fw-600 fs-14px">Course URL-1</label>
          <input
            placeholder="python-django-course-with-aws"
            value={values.url_1}
            onChange={(e) => setValues({ ...values, url_1: e.target.value })}
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="6" className="mt-3">
          <label className="input-label fw-600 fs-14px">Course URL-2</label>
          <input
            placeholder="web-development-with-python-django-course-with-aws"
            value={values.url_2}
            onChange={(e) => setValues({ ...values, url_2: e.target.value })}
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="6" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Basic Description
          </label>
          <textarea
            placeholder="Description"
            value={values.basic_description}
            onChange={(e) =>
              setValues({ ...values, basic_description: e.target.value })
            }
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="6" className="mt-3">
          <label className="input-label fw-600 fs-14px">Long Description</label>
          <textarea
            placeholder="Description"
            value={values.long_description}
            onChange={(e) =>
              setValues({ ...values, long_description: e.target.value })
            }
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="12" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Job Tag Description
          </label>
          <textarea
            placeholder="Description"
            value={values.job_tags_description}
            onChange={(e) =>
              setValues({ ...values, job_tags_description: e.target.value })
            }
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="12" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Course Intro Video
          </label>
          <input
            value={values.intro_video}
            onChange={(e) =>
              setValues({ ...values, intro_video: e.target.value })
            }
            placeholder="https://"
            className="form-control  br-4px"
          />
        </Colxx>

        <Colxx lg="12" xl="12" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Whatsapp Group Link
          </label>
          <input
            value={values.wap_group}
            onChange={(e) =>
              setValues({ ...values, wap_group: e.target.value })
            }
            placeholder="https://"
            className="form-control  br-4px"
          />
        </Colxx>

        <div className="d-flex justify-content-flex-end w-100percent">
          <Button
            size={"sm"}
            color="secondry"
            onClick={onSubmitHandler}
            className="br-20px c_white shadow-box create-new-button mt-30px"
          >
            {isAdding && "Create"}
            {isEditing && "Update"}
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default CourseBasicDetails;
