import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../components/common/CustomBootstrap";
import { useSelector, useDispatch } from "react-redux";
import Switch from "rc-switch";
import {
  deleteUserDataAction,
  errorHandler,
  generateBulkCertificatesAction,
  getAllBatchDataAction,
  getAllCourseDataAction,
  getAllInstitutesDataAction,
  getTeamMemberDataAction,
} from "../../../../redux/master/actions";
import Select from "react-select";
import AlertMessage from "../../../../helpers/alerts";
import { createNewBatcheAPI } from "../../../../services/master";
import {
  certificateTagLineOptions,
  studentCompletedOptions,
} from "../../../../constants/selectOptions";
import { requiredFieldValidation } from "../../../../helpers/Utils";

const GenerateBulkCertificates = (props) => {
  const {
    isOpen,
    setIsOpen,
    studentIds = [],
    enrollmentId = null,
    searchObj,
  } = props;

  let defaultValues = {
    course: "",
    batch: "",
    what_you_did: "",
    tag_line: "",
    learning_period: "",
    project_name: "",
    issue_date: "",
  };

  studentIds?.length && (defaultValues["studentIds"] = studentIds);
  enrollmentId && (defaultValues["enrollmentId"] = enrollmentId);

  const [courseSelectOptions, setCourseSelectOptions] = useState([]);
  const [courseBatchSelectOptions, setCourseBatchSelectOptions] = useState([]);
  const [values, setValues] = useState(defaultValues);
  const isApiSuccess = useSelector((state) => state.master.isApiSuccess);
  const dispatch = useDispatch();
  const allCourses = useSelector((state) => state.master.allCourses);
  const allBatch = useSelector((state) => state.master.allBatch);

  useEffect(() => {
    dispatch(getAllCourseDataAction());
    dispatch(getAllBatchDataAction());
  }, []);

  useEffect(() => {
    if (searchObj?.course_id) {
      let selectedCourse = allCourses.find(
        (course) => course.id === searchObj?.course_id
      );
      if (selectedCourse)
        setValues((prevState) => ({
          ...prevState,
          course: { label: selectedCourse.name, value: selectedCourse.id },
        }));

      if (selectedCourse && searchObj?.batch_id) {
        let selectedBatch = allBatch?.find(
          (batch) => batch.id === searchObj?.batch_id
        );
        if (selectedBatch) {
          setValues((prevState) => ({
            ...prevState,
            batch: { label: selectedBatch.name, value: selectedBatch.id },
          }));
        }
      }
    }
  }, [searchObj, allCourses, allBatch]);

  useEffect(() => {
    if (isApiSuccess) {
      setIsOpen(!isOpen);
      setValues(defaultValues);
    }
  }, [isApiSuccess]);

  useEffect(() => {
    let allRows = [];
    allCourses?.map((x) => allRows.push({ label: x.name, value: x.id }));
    setCourseSelectOptions(allRows);
  }, [allCourses]);

  const onSubmitHandler = () => {
    let [isExist, field] = requiredFieldValidation(values);
    if (!isExist) {
      AlertMessage(`Field - ${field}`, "error", "This field required");
      return;
    }
    let newValues = {
      ...values,
      batch: values.batch?.value,
      course: values.course?.value,
      tag_line: values.tag_line.value,
      what_you_did: values.what_you_did.value,
    };

    dispatch(generateBulkCertificatesAction(newValues));
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
        backdrop={true}
        size="xl"
      >
        <Colxx lg="12" xl="12">
          <div className="d-flex flex-grow-1 min-width-zero">
            <div className="card-body accordion-content pt-0">
              <Row className="mt-30px">
                <Colxx lg="4" xl="4" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Select Course
                    <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <Select
                    isClearable
                    value={values.course}
                    onChange={(data) => {
                      setValues({
                        ...values,
                        course: data,
                        batch: null,
                      });
                      let allOpt = [];
                      allBatch.map((x) => {
                        if (x.course.id === data?.value) {
                          allOpt.push({ label: x.name, value: x.id });
                        }
                      });
                      setCourseBatchSelectOptions(allOpt);
                    }}
                    options={courseSelectOptions}
                  />
                </Colxx>
                <Colxx lg="4" xl="4" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Select Batch
                    <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <Select
                    isClearable
                    value={values.batch}
                    onChange={(data) => {
                      setValues({
                        ...values,
                        batch: data,
                      });
                    }}
                    options={courseBatchSelectOptions}
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Student Completed ?
                    <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <Select
                    value={values.what_you_did}
                    onChange={(data) =>
                      setValues({ ...values, what_you_did: data })
                    }
                    options={studentCompletedOptions}
                  />
                </Colxx>

                <Colxx lg="6" xl="6" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Main Tag Line
                    <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <Select
                    value={values.tag_line}
                    onChange={(data) =>
                      setValues({ ...values, tag_line: data })
                    }
                    options={certificateTagLineOptions}
                  />
                </Colxx>

                <Colxx lg="6" xl="6" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Learning Period <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control br-10px"
                    placeholder="ex. 1 Jan 2023 to 31 Jan 2023 (4 Weeks)"
                    value={values.learning_period}
                    onChange={(data) =>
                      setValues({
                        ...values,
                        learning_period: data.target.value,
                      })
                    }
                  />
                </Colxx>

                <Colxx lg="6" xl="6" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Project Name <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control br-10px"
                    placeholder="ex. Chat App Development with RN"
                    value={values.project_name}
                    onChange={(data) =>
                      setValues({
                        ...values,
                        project_name: data.target.value,
                      })
                    }
                  />
                </Colxx>

                <Colxx lg="4" xl="4" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Issue Date <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control br-10px"
                    placeholder="ex. xyz"
                    value={values.issue_date}
                    onChange={(data) =>
                      setValues({
                        ...values,
                        issue_date: data.target.value,
                      })
                    }
                  />
                </Colxx>

                <div className="d-flex justify-content-flex-end w-100percent">
                  <Button
                    onClick={() => {
                      setValues(defaultValues);
                      setIsOpen(!isOpen);
                    }}
                    size={"sm"}
                    color="dark"
                    className="br-20px c_white shadow-box create-new-button mt-30px"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={onSubmitHandler}
                    size={"sm"}
                    color="secondry"
                    className="br-20px c_white shadow-box create-new-button mt-30px"
                  >
                    Create
                  </Button>
                </div>
              </Row>
            </div>
          </div>
        </Colxx>
      </Modal>
    </React.Fragment>
  );
};

export default GenerateBulkCertificates;
