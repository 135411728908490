import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  errorHandler,
  getAllBatchDataAction,
  getAllCourseDataAction,
  getAllStudentsDataAction,
  getAllUsersDataAction,
} from "../../../../../redux/master/actions";
import { paymentStatusOptions } from "../../../../../constants/selectOptions";
import { useAuthRole } from "../../../../../helpers/hooks";
import AlertMessage from "../../../../../helpers/alerts";
import { bulkUploadStudentsAPI } from "../../../../../services/master";

const StudentFilterSection = (props) => {
  const { setSearchObj, isBulkUpload, setIsBulkUpload } = props;
  const [courseSelectOption, setCourseSelectOptions] = useState([]);
  const [courseBatchSelectOptions, setCourseBatchSelectOptions] = useState([]);
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [filePath, setFilePath] = useState("");
  const dispatch = useDispatch();
  const allBatch = useSelector((state) => state.master.allBatch);
  const allCourses = useSelector((state) => state.master.allCourses);
  const { users: allUsers } = useSelector((state) => state.master.allUsers);
  const currentUser = useSelector((state) => state.authUser.currentUser);
  const { isAdmin } = useAuthRole(currentUser);

  useEffect(() => {
    dispatch(getAllCourseDataAction());
    dispatch(getAllBatchDataAction());
    dispatch(getAllUsersDataAction({ perPage: 100, is_staff: true }));
  }, []);

  useEffect(() => {
    let allRows = [];
    allUsers?.map((x) => {
      allRows.push({
        label: x.first_name + " " + x.last_name,
        value: x.id,
        email: x.username,
      });
    });
    setUserSelectOptions(allRows);
  }, [allUsers]);

  useEffect(() => {
    let allRows = [];
    allCourses?.map((x) => allRows.push({ label: x.name, value: x.id }));
    setCourseSelectOptions(allRows);
  }, [allCourses]);

  const fetchStudentsData = () => {
    var newObj = {};
    Object.keys(searchParams).map((key) => {
      if (searchParams[key]) {
        if (searchParams[key].value.toString().length > 0)
          newObj[key] = searchParams[key].value;
      }
    });
    if (
      (!newObj.query || newObj.query?.length > 2) &&
      Object.keys(newObj).length > 0 &&
      !isBulkUpload
    ) {
      dispatch(getAllStudentsDataAction(newObj));
      setSearchObj(newObj);
    }

    if (Object.keys(searchParams)?.length > 0) {
      sessionStorage.setItem(
        "studentSearchParams",
        JSON.stringify(searchParams)
      );
    }
  };
  useEffect(fetchStudentsData, [searchParams]);

  useEffect(() => {
    let searchObject = sessionStorage.getItem("studentSearchParams");
    if (searchObject) {
      searchObject = JSON.parse(searchObject);
      setSearchParams(searchObject);
    }
  }, []);

  const onBulkUpload = async () => {
    console.log(
      "searchParams",
      searchParams,
      !searchParams?.course_id?.value,
      !searchParams?.batch_id?.value
    );
    if (!searchParams?.course_id?.value || !searchParams?.batch_id?.value) {
      AlertMessage("Course and Batch required", "error");
      return;
    }

    let newPayload = {
      course: searchParams?.course_id?.value,
      batch: searchParams?.batch_id?.value,
      filePath,
    };
    try {
      const res = await bulkUploadStudentsAPI(newPayload);
      setIsBulkUpload(false);
      AlertMessage("Students Uploaded Successfully");
      fetchStudentsData();
    } catch (error) {
      setIsBulkUpload(false);
      dispatch(errorHandler(error));
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex">
        <div className="student-filter-section">
          <div className="w-250px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Select Course</label>
            <Select
              options={courseSelectOption}
              isClearable
              value={searchParams.course_id}
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  course_id: data,
                  batch_id: null,
                  query: "",
                });
                let allOpt = [];
                allBatch.map((x) => {
                  if (x.course.id === data?.value) {
                    allOpt.push({ label: x.name, value: x.id });
                  }
                });
                setCourseBatchSelectOptions(allOpt);
              }}
            />
          </div>

          <div className="w-250px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Select Batch</label>
            <Select
              options={courseBatchSelectOptions}
              isClearable
              value={searchParams.batch_id}
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  batch_id: data,
                  query: "",
                });
              }}
            />
          </div>
          {!isBulkUpload && (
            <React.Fragment>
              <div className="w-250px mb-10px mr-10px">
                <label className="input-label fw-400 fs-14px">Fee Status</label>
                <Select
                  options={paymentStatusOptions}
                  isClearable
                  value={searchParams.status}
                  onChange={(data) => {
                    setSearchParams({
                      ...searchParams,
                      status: data,
                      query: "",
                    });
                  }}
                />
              </div>
              {isAdmin && (
                <div className="w-250px mb-10px mr-10px">
                  <label className="input-label fw-400 fs-14px">
                    Assigned To
                  </label>
                  <Select
                    options={userSelectOptions}
                    isClearable
                    value={searchParams.assignedTo}
                    onChange={(data) => {
                      setSearchParams({
                        ...searchParams,
                        assignedTo: data,
                        query: "",
                      });
                    }}
                  />
                </div>
              )}

              {isAdmin && (
                <div className="w-250px mb-10px mr-10px">
                  <label className="input-label fw-400 fs-14px">
                    Other Options
                  </label>
                  <Select
                    options={[
                      { label: "Is Blogger", value: "is_blogger" },
                      { label: "Not Enrolled", value: "is_unenrolled" },
                      { label: "Is CA", value: "is_ca" },
                    ]}
                    isClearable
                    value={searchParams.other}
                    onChange={(data) => {
                      setSearchParams({
                        ...searchParams,
                        other: data,
                        query: "",
                      });
                    }}
                  />
                </div>
              )}

              <div className="w-303px mb-10px mr-10px">
                <label className="input-label fw-400 fs-14px">Search</label>
                <input
                  type={"text"}
                  className="form-control br-10px"
                  placeholder="Search..."
                  value={searchParams.query ? searchParams.query?.value : ""}
                  onChange={(data) =>
                    setSearchParams({
                      ...searchParams,
                      query: { value: data.target.value },
                    })
                  }
                />
              </div>
            </React.Fragment>
          )}

          {isBulkUpload && (
            <>
              <div className="w-303px mb-10px mr-10px">
                <label className="input-label fw-400 fs-14px">Search</label>
                <input
                  type={"text"}
                  className="form-control br-10px"
                  placeholder="File Path: /uploads"
                  value={filePath}
                  onChange={(data) => setFilePath(data.target.value)}
                />
              </div>
              <button
                onClick={onBulkUpload}
                className="mt-16px shadow-box create-new-button bg-black-60p mr-20px"
              >
                Upload Students
              </button>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default StudentFilterSection;
