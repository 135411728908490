/* eslint-disable react/no-array-index-key */
import React from "react";
import IconCard from "../../components/cards/IconCard";

import GlideComponent from "../../components/carousel/GlideComponent";
import { useSelector } from "react-redux";
import { useAuthRole } from "../../helpers/hooks";

const IconCardsCarousel = (props) => {
  const currentUser = useSelector((state) => state.authUser.currentUser);
  const { isAdmin, isStaff } = useAuthRole(currentUser);

  const {
    className = "icon-cards-row",
    perView = 5,
    cardData = null,
    values = null,
  } = props;

  let data = isAdmin
    ? [
        {
          title: "Total Courses",
          icon: "simple-icon-book-open",
          value: values ? values.courses : 0,
          link: "/dashboard/courses",
        },

        {
          title: "Total Batches",
          icon: "simple-icon-calendar",
          value: values ? values.batches : 0,
          link: "/dashboard/batches",
        },
        {
          title: "Enquires",
          icon: "simple-icon-call-in",
          value: values ? values.total_enquires : 0,
          link: "/dashboard/master-data/enquire",
        },
        {
          title: "FB Leads",
          icon: "simple-icon-layers",
          value: values ? values.total_leads : 0,
          link: "/dashboard/master-data/facebook-leads",
        },
        {
          title: "Total Students",
          icon: "iconsminds-students",
          value: values ? values.total_students : 0,
          link: "/dashboard/students",
        },
        {
          title: "Unread Chats",
          icon: "simple-icon-bubbles",
          value: values ? values.unpaid_students : 0,
          link: "/dashboard/whatsapp/chat",
        },
        {
          title: "Number of Blogs",
          icon: "iconsminds-blogger",
          value: values ? values.blogs : 0,
          link: "/dashboard/blogs",
        },
      ]
    : [
        {
          title: "Enquires",
          icon: "simple-icon-call-in",
          value: values ? values.total_enquires : 0,
          link: "/dashboard/master-data/enquire",
        },
        {
          title: "Total Students",
          icon: "iconsminds-students",
          value: values ? values.total_students : 0,
          link: "/dashboard/students",
        },
      ];

  return (
    <div className={className}>
      {isAdmin ? (
        <GlideComponent
          settings={{
            gap: 5,
            perView: perView,
            type: "carousel",
            breakpoints: {
              335: { perView: perView > 1 ? 1 : perView },
              476: { perView: perView > 2 ? 2 : perView },
              700: { perView: perView > 3 ? 3 : perView },
              900: { perView: perView > 4 ? 4 : perView },
              1200: { perView: perView > 5 ? 5 : perView },
              1400: { perView: perView > 6 ? 6 : perView },
            },
            hideNav: true,
          }}
        >
          {data?.map((item, index) => {
            return (
              <div
                onClick={() => {
                  props.history.push(item.link);
                }}
                key={`icon_card_${index}`}
              >
                <IconCard {...item} className="mb-4" />
              </div>
            );
          })}
        </GlideComponent>
      ) : (
        <div className="d-flex">
          {data?.map((item, index) => {
            return (
              <div
                onClick={() => {
                  props.history.push(item.link);
                }}
                key={`icon_card_${index}`}
              >
                <IconCard {...item} className="mb-4 w-303px mx-10px" />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default IconCardsCarousel;
