import React, { useEffect, useState } from "react";
import { Input, Button } from "reactstrap";
import { sendOneMessageAPI } from "../../../../../services/whatsapp";
import { useDispatch, useSelector } from "react-redux";
import { errorHandler } from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import { getOneUserChatAction } from "../../../../../redux/whatsapp/actions";

const SaySomething = (props) => {
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.wap.activeUser);
  const [messages, setMessage] = useState("");

  const sendMessage = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (messages && messages !== "") {
        try {
          setMessage("");
          await sendOneMessageAPI({
            mobile: activeUser.mobile,
            text: messages,
          });
          dispatch(getOneUserChatAction({ _id: activeUser._id }));
        } catch (e) {
          dispatch(errorHandler(e));
        }
      }
    }
  };


  const messageHandler = (e) => {
    setMessage(e.target.value);
  };

  return (
    <div className="chat-input-container d-flex justify-content-between">
      <textarea
        id="say-something"
        className="say-something-input shadow-box br-14px"
        placeholder="Type a Message"
        value={messages}
        onChange={messageHandler}
        onKeyDown={sendMessage}
      />
    </div>
  );
};
export default React.memo(SaySomething);
