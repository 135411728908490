const vehicalTypeOptions = [
  { label: "2 Wheeler", value: "2 Wheeler", key: 0 },
  { label: "4 Wheeler", value: "4 Wheeler", key: 1 },
];

const genderTypeOptions = [
  { label: "Male", value: "Male", key: 0 },
  { label: "Female", value: "Female", key: 1 },
];

export const companyCategoryOptions = [
  { label: "Placed", value: "Placed", key: 0 },
  { label: "Hairing", value: "Hairing", key: 1 },
  { label: "Other", value: "Other", key: 2 },
];

export const CourseContentOptions = [
  { label: "Content", value: "Content", key: 0 },
  { label: "Project", value: "Project", key: 1 },
  { label: "Other", value: "Other", key: 2 },
];

export const courseLabelOptions = [
  { label: "Institute_STTP", value: "Institute_STTP", key: 0 },
  { label: "Institute_FDP", value: "Institute_FDP", key: 1 },
  { label: "Institute_LTTP", value: "Institute_LTTP", key: 2 },
  { label: "TechSim_Basic", value: "TechSim_Basic", key: 3 },
  { label: "TechSim_Mega", value: "TechSim_Mega", key: 4 },
  { label: "Pre_Recorded", value: "Pre_Recorded", key: 5 },
  { label: "TechSim_Events", value: "TechSim_Events", key: 6 },
];

export const teamCategoryOptions = [
  { label: "Trainer", value: "Trainer", key: 0 },
  { label: "Mentor", value: "Mentor", key: 1 },
  { label: "Placed", value: "Placed", key: 2 },
  { label: "Other", value: "Other", key: 3 },
];

export const learningModeOptions = [
  { label: "Online", value: "Online", key: 0 },
  { label: "Offline", value: "Offline", key: 1 },
  { label: "In Institute", value: "In Institute", key: 2 },
];

export const studentCompletedOptions = [
  { label: "Internship", value: "Internship", key: 1 },
  { label: "Project", value: "Project", key: 2 },
  { label: "Training", value: "Training", key: 0 },
  { label: "Workshop", value: "Workshop", key: 2 },
];

export const batchTypeOptions = [
  { label: "Weekdays", value: "Weekdays", key: 0 },
  { label: "Weekend", value: "Weekend", key: 1 },
  { label: "Other", value: "Other", key: 2 },
];

export const feeCollectionTypeOptions = [
  { label: "Per Student", value: "Per Student", key: 0 },
  { label: "Bulk", value: "Bulk", key: 1 },
];

export const feeByTypeOptions = [
  { label: "Student", value: "Student", key: 0 },
  { label: "Institute", value: "Institute", key: 1 },
];

export const FileCategoryTypeOptions = [
  { label: "Blog", value: "Blog", key: 0 },
  { label: "Certificate", value: "Certificate", key: 1 },
  { label: "Company", value: "Company", key: 2 },
  { label: "Course_Banner", value: "Course_Banner", key: 3 },
  { label: "Gallery", value: "Gallery", key: 4 },
  { label: "Icons", value: "Icons", key: 5 },
  { label: "Logos", value: "Logos", key: 6 },
  { label: "Profile", value: "Profile", key: 7 },
  { label: "Technology", value: "Technology", key: 8 },
  { label: "Other", value: "Other", key: 8 },
];

export const defaultData = {
  workingProfessional: "Working Professional",
  student: "Student",
  other: "Other",
  passOut: "Pass Out",
  it: "IT",
  nonIt: "Non-IT",
};

export const backgroundOptions = [
  { label: defaultData.it, value: defaultData.it },
  { label: defaultData.nonIt, value: defaultData.nonIt },
];

export const yesNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const wapTemplateTypeOptions = [
  { label: "Text", value: "text" },
  { label: "Media", value: "media" },
  { label: "Interactive", value: "interactive" },
  { label: "Flow", value: "flow" },
];

export const wapTemplateHeaderTypeOptions = [
  { label: "Text", value: "text" },
  { label: "Image", value: "image" },
  { label: "Video", value: "video" },
];

export const masterCourseKeyOptions = [{ label: "MERN", value: "MERN" }];

export const paymentStatusOptions = [
  { label: "Paid", value: "paid" },
  { label: "Remain", value: "remain" },
  { label: "Unpaid", value: "unpaid" },
];

export const whoYouAre = [
  { label: defaultData.student, value: defaultData.student },
  {
    label: defaultData.workingProfessional,
    value: defaultData.workingProfessional,
  },
  { label: defaultData.other, value: defaultData.other },
];

export const graduationYear = [
  { label: defaultData.passOut, value: defaultData.passOut },
  { label: 2022, value: "2022" },
  { label: 2023, value: "2023" },
  { label: 2024, value: "2024" },
  { label: 2025, value: "2025" },
  { label: 2026, value: "2026" },
];

export const perPageOptions = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 250, value: 250 },
  { label: 500, value: 500 },
];

export const degreeOptions = [
  { label: "BE/B.Tech", value: "BE/B.Tech" },
  { label: "ME/M.Tech", value: "ME/M.Tech" },
  { label: "BCA/MCA", value: "BCA.MCA" },
  { label: "Other", value: "Other" },
];

export const certificateTagLineOptions = [
  {
    label: "Has participated in training program held from",
    value: "has participated in training program held from",
  },
  {
    label: "Has participated in internship program held from",
    value: "has participated in internship program held from",
  },
];

export const blogReviewStatusOptions = [
  { label: "Not Submited", value: "Not Submited" },
  { label: "Under Review", value: "Under Review" },
  { label: "Rejected", value: "Rejected" },
  { label: "Approved", value: "Approved" },
];

export { vehicalTypeOptions, genderTypeOptions };
