import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import downloadIcon from "../../../../../assets/img/icons/solid/download.svg";
import Select from "react-select";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import { useSelector, useDispatch } from "react-redux";
import { errorHandler } from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";

import FileFilterSection from "./fileFilter";
import CustomeTable from "../../../../../components/common/table";
import {
  formateDate,
  processSearchParamsObj,
  requiredFieldValidation,
} from "../../../../../helpers/Utils";
import {
  createWAPUsersAPI,
  enquiriesToContactAPI,
  updateWAPGroupAPI,
  updateWAPUserAPI,
} from "../../../../../services/whatsapp";
import {
  deleteWAPUserAction,
  getWAPUsersAction,
} from "../../../../../redux/whatsapp/actions";
import downloadCSV from "../../../../../helpers/csvDownload";
const queryString = require("query-string");

const WAPContacts = (props) => {
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [currentData, setCurrentData] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [newGroup, setNewGroup] = useState([]);
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const allGroups = useSelector((state) => state.wap.allGroups);
  const [values, setValues] = useState({
    name: "",
    email: "",
    mobile: "",
    is_bot_active: false,
  });

  let {
    groupId,
    broadcastId,
    status: msgStatus,
  } = queryString.parse(props.location.search);

  const { users: fetchedData, count: totalCount } = useSelector(
    (state) => state.wap.allUsers
  );

  const onDeleteClick = (_id) => {
    if (!_id && selectedIds?.length > 0) {
      selectedIds.map((_id) => {
        dispatch(deleteWAPUserAction({ _id }));
      });
      setSelectedIds([]);
    } else {
      dispatch(deleteWAPUserAction({ _id }));
    }

    setCurrentData(null);
    setIsDeleting(false);
    setTimeout(
      () => {
        let obj = processSearchParamsObj(searchParams);
        dispatch(getWAPUsersAction(obj));
      },
      !_id && selectedIds?.length > 0 ? 1000 : 200
    );
  };

  const removeFromGroupHandler = async () => {
    if (!groupId) return;
    let values = { userIds: selectedIds, removeOrAdd: "remove" };
    await updateWAPGroupAPI(values, { _id: groupId });
    setTimeout(() => {
      let obj = processSearchParamsObj(searchParams);
      dispatch(getWAPUsersAction(obj));
    }, 1000);
  };

  useEffect(() => {
    if (isEditing && currentData) {
      setValues({
        ...values,
        name: currentData.name,
        email: currentData.email,
        mobile: currentData.mobile,
      });
    }
  }, [isEditing, currentData]);

  useEffect(() => {
    let allRows = [];
    allGroups?.map((x) => {
      allRows.push({
        label: (
          <span className="">
            <strong>{x.name}</strong>
          </span>
        ),
        value: x.id,
      });
    });
    setUserSelectOptions(allRows);
  }, [allGroups]);

  useEffect(() => {
    if (groupId) {
      setSearchParams({ ...searchParams, groupId: { value: groupId } });
    }
  }, [groupId]);

  useEffect(() => {
    if (broadcastId && msgStatus) {
      setSearchParams({
        ...searchParams,
        broadcastId: { value: broadcastId },
        msgStatus: { value: msgStatus },
      });
    } else if (broadcastId && !msgStatus) {
      setSearchParams({ ...searchParams, broadcastId: { value: broadcastId } });
    }
  }, [broadcastId]);

  const processChatIds = (_id, isAll = false) => {
    if (!isAll) {
      if (selectedIds.includes(_id)) {
        let remainIds = selectedIds.filter((x) => x !== _id);
        setSelectedIds(remainIds);
      } else {
        setSelectedIds([...selectedIds, _id]);
      }
    } else {
      if (selectedIds?.length > 0) {
        setSelectedIds([]);
      } else {
        let allIds = [];
        fetchedData?.map((x) => allIds.push(x.id));
        setSelectedIds(allIds);
      }
    }
  };

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: (
          <span>
            #{" "}
            <input
              checked={selectedIds?.length === fetchedData?.length}
              type="checkbox"
              onChange={() => processChatIds("", true)}
            />
          </span>
        ),
        field: "sn",
        width: 70,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Name",
        field: "name",
        width: 170,
      },

      {
        label: "Mobile Number",
        field: "mobile",
        width: 170,
      },

      {
        label: "Email",
        field: "email",
        width: 300,
      },
      {
        label: "Joined Date",
        field: "date",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  const downloadFilteredData = () => {
    let newData = [];
    fetchedData?.map((oneEle) => {
      let oneObj = {
        name: oneEle.name,
        mobile: oneEle.mobile,
        email: oneEle.email,
      };
      newData.push(oneObj);
    });
    if (newData?.length > 0) {
      downloadCSV(newData);
    }
  };

  const onSubmitHandler = async () => {
    let [allFieldExist, fieldName] = requiredFieldValidation(values, [
      "email",
      "is_bot_active",
    ]);

    if (!allFieldExist) {
      AlertMessage("Field - " + fieldName, "error", "This field required.");
      return;
    }
    let queryObj = {};
    let finalMethod = createWAPUsersAPI;
    if (isEditing) {
      finalMethod = updateWAPUserAPI;
      queryObj = { _id: currentData.id };
    }

    try {
      await finalMethod(values, queryObj);
      AlertMessage("New Contact Created Successfully.", "success");
      let obj = processSearchParamsObj(searchParams);
      dispatch(getWAPUsersAction(obj));
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const chatStart = (_id) => {
    props.history?.push(`/dashboard/whatsapp/chat?_id=${_id}`);
  };

  useEffect(() => {
    let allRows = [];

    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: (
          <span>
            {index + 1}{" "}
            <input
              onChange={() => processChatIds(data.id)}
              checked={selectedIds.includes(data.id)}
              type="checkbox"
            />
          </span>
        ),
        name: (
          <span
            className="cursor-pointer"
            onClick={() => {
              chatStart(data.id);
            }}
          >
            {data.name}{" "}
            {data.unread_messages ? `( ${data.unread_messages} )` : ""}
          </span>
        ),
        mobile: (
          <span
            className="cursor-pointer"
            onClick={() => {
              chatStart(data.id);
            }}
          >
            {data.mobile}
          </span>
        ),
        email: data?.email,
        date: formateDate(data.createdAt),
        action: (
          <span>
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsEditing(true);
                setCurrentData(data);
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    let allColumns = datatable.columns;
    let oneColumn = allColumns[0];
    oneColumn = {
      ...oneColumn,
      label: (
        <span>
          #{" "}
          <input
            checked={selectedIds?.length === fetchedData?.length}
            type="checkbox"
            onChange={() => processChatIds("", true)}
          />
        </span>
      ),
    };

    allColumns[0] = oneColumn;

    setDatatable({
      ...datatable,
      rows: allRows,
      columns: allColumns,
    });
  }, [fetchedData, selectedIds]);

  const onPageChange = (e) => {
    setSearchParams({ ...searchParams, ...e });
  };

  const onStudentsToWAPAccount = async () => {
    try {
      if (false) {
        AlertMessage("Whatsapp Group Required", "error");
        return;
      }

      let values = {
        enquiryIds: selectedIds,
        selectedGroup: newGroup,
        model: "wapContacts",
      };
      await enquiriesToContactAPI(values);
      setSelectedIds([]);
      AlertMessage("Added Successfully");
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              All WhatsApp Users
              <p className="fs-14px c_black-60p">
                Here We have all the user, may be they belongs from And Group or
                Individual
              </p>
            </span>
            {selectedIds.length > 0 && (
              <div>
                <button
                  className="shadow-box create-new-button bg-black-60p"
                  onClick={() => {
                    onDeleteClick();
                  }}
                >
                  Delete Selected Contacts
                </button>
                {groupId && (
                  <button
                    className="shadow-box ml-10px create-new-button bg-primary-variant"
                    onClick={removeFromGroupHandler}
                  >
                    Remove From the Group
                  </button>
                )}
              </div>
            )}
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <FileFilterSection
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          groupId={groupId}
          userSelectOptions={userSelectOptions}
          {...props}
        />
        {datatable?.rows?.length > 0 && (
          <img
            src={downloadIcon}
            alt="filter-icon"
            onClick={() => {
              downloadFilteredData();
            }}
            className="w-20px filter-icon mr-20px"
          />
        )}
      </Row>
      {selectedIds?.length > 0 && (
        <div className="mr-10px d-flex justify-content-flex-start mb-10px mt-10px">
          <Select
            placeholder="Select Group"
            className="w-303px mr-10px"
            isClearable
            options={userSelectOptions}
            onChange={(data) => {
              setNewGroup(data?.value || null);
            }}
          />
          <button
            onClick={onStudentsToWAPAccount}
            className="shadow-box create-new-button bg-ed7117"
          >
            Add to Whatsapp Group
          </button>
        </div>
      )}
      <Row>
        <Colxx lg="12" xl="8">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div
                className="card-body accordion-content pt-0"
                style={{ maxWidth: "100%" }}
              >
                <CustomeTable
                  onPageChange={onPageChange}
                  datatable={datatable}
                  total={totalCount}
                />
              </div>
            </div>
          </Card>
        </Colxx>
        <Colxx lg="12" xl="4">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div className="card-body accordion-content pt-0">
                <Row className="mt-30px">
                  <Colxx lg="12" xl="12" className="mt-20px">
                    <label className="input-label fw-400 fs-14px">Name</label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. Prateek Mishra"
                      value={values.name}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          name: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <Colxx lg="12" xl="12" className="mt-20px">
                    <label className="input-label fw-400 fs-14px">
                      Mobile Number
                    </label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. 9143546565564"
                      value={values.mobile}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          mobile: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <Colxx lg="12" xl="12" className="mt-20px">
                    <label className="input-label fw-400 fs-14px">Email</label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. info@techsimplus.com"
                      value={values.email}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          email: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <div className="d-flex justify-content-flex-end w-100percent">
                    <Button
                      onClick={onSubmitHandler}
                      size={"sm"}
                      color="secondry"
                      className="br-20px c_white shadow-box create-new-button mt-30px"
                    >
                      Create
                    </Button>
                  </div>
                </Row>
              </div>
            </div>
          </Card>
        </Colxx>
      </Row>
    </React.Fragment>
  );
};

export default WAPContacts;
