import { combineReducers } from "redux";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import company from "./company/reducer";
import master from "./master/reducer";
import wap from "./whatsapp/reducer";

const reducers = combineReducers({
  menu,
  authUser,
  company,
  master,
  wap,
});

export default reducers;
