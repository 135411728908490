import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { ProcessParams } from "./master";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const sendOneMessageAPI = async (data, Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.post(`/wap/send-one-msg/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const getAllWAPUsersAPI = async (Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.get(`/wap/contacts/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createWAPUsersAPI = async (data, Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.post(`/wap/contacts/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};


export const updateWAPUserAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/wap/contacts/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteWAPUsersAPI = async (Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.delete(`/wap/contacts/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};


export const enquiriesToContactAPI = async (data, Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.post(`/wap/enquiries-to-contact/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};


export const getOneUserChatAPI = async (Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.get(`/wap/get-wap-chat/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};



// Whatsapp Group APIs

export const getAllWAPGroupAPI = async (Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.get(`/wap/broadcast-group/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createWAPGroupAPI = async (data, Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.post(`/wap/broadcast-group/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};


export const updateWAPGroupAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/wap/broadcast-group/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteWAPGroupAPI = async (Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.delete(`/wap/broadcast-group/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};





// Whatsapp Broadcasts APIs

export const getAllWAPBroadCastsAPI = async (Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.get(`/wap/new-broadcast/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createWAPBroadCastsAPI = async (data, Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.post(`/wap/new-broadcast/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};


export const deleteWAPBroadCastsAPI = async (Obj = {}) => {
  let paramString = ProcessParams(Obj);
  try {
    return trackPromise(
      axios.delete(`/wap/new-broadcast/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};