import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { deleteWAPBroadcastAction, getWAPBroadcastsAction, getWAPGroupDataAction, getWAPUsersAction } from "../../../../../redux/whatsapp/actions";
import { Button, Card, Row } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import {
    Colxx,
    Separator,
} from "../../../../../components/common/CustomBootstrap";
import { arrRange, processSearchParamsObj } from "../../../../../helpers/Utils";
import { errorHandler, getAllCourseDataAction, getWAPTemplatesDataAction } from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import { createWAPBroadCastsAPI, deleteWAPBroadCastsAPI } from "../../../../../services/whatsapp";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
const queryString = require("query-string");

const defaultValues = {
    group: null,
    contact: null,
    template: null,
    mobile: null,
    template_string: "",
    templateParams: {}
}



const NewWAPBroadCast = (props) => {
    const dispatch = useDispatch();
    let { groupId } = queryString.parse(props.location.search);
    const [values, setValues] = useState(defaultValues)
    const [wapGroupSelectOptions, setWapGroupSelectOptions] = useState([])
    const [wapTeplateSelectOptions, setWapTemplateSelectOptions] = useState([])
    const [wapContactSelectOptions, setWapContactSelectOptions] = useState([])
    const [relatedCourseSelectOptions, setRelatedCourseSelectOptions] = useState([])
    const [searchParams, setSearchParams] = useState({});
    const [isPublished, setIsPublished] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [currentData, setCurrentData] = useState(null);

    const fetchedData = useSelector((state) => state.wap.allBroadcasts);
    const allCourses = useSelector((state) => state.master.allCourses);
    const allGroups = useSelector((state) => state.wap.allGroups);
    const { users: allUsers } = useSelector(
        (state) => state.wap.allUsers
    );
    const allTemplates = useSelector((state) => state.master.wapTemplates);

    const [datatable, setDatatable] = React.useState({
        columns: [
            {
                label: "#",
                field: "sn",
                width: 80,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "id",
                },
                sort: "disabled",
            },
            {
                label: "Name",
                field: "name",
                width: 200,
            },
            {
                label: "Group Name",
                field: "group",
                width: 200,
            },
            {
                label: "Total Contacts",
                field: "total",
                width: 200,
            },
            {
                label: "Pending",
                field: "pending",
                width: 100,
            },
            {
                label: "Sent",
                field: "sent",
                width: 100,
            },

            {
                label: "Delivered",
                field: "delivered",
                width: 150,
            },
            {
                label: "Read",
                field: "read",
                width: 100,
            },
            {
                label: "Failed",
                field: "failed",
                width: 100,
            },
            {
                label: "Date",
                field: "date",
                width: 120,
            },
            {
                label: "Action",
                field: "action",
                width: 100,
                sort: "disabled",
            },
        ],
        rows: [],
    });



    useEffect(() => {
        dispatch(getWAPGroupDataAction())
        dispatch(getWAPTemplatesDataAction())
        dispatch(getWAPBroadcastsAction())
        dispatch(getAllCourseDataAction());
    }, []);

    useEffect(() => {
        let allRows = [];
        allGroups?.map((x) => {
            allRows.push({
                label: x.name,
                value: x.id,
            });
        });
        setWapGroupSelectOptions(allRows);
    }, [allGroups]);

    useEffect(() => {
        let allRows = [];
        allCourses?.map((x) => {
            if(x.label === "TechSim_Events")
                allRows.push({ label: x.name, value: x.id })
        });
        setRelatedCourseSelectOptions(allRows);
      }, [allCourses]);

    useEffect(() => {
        let allRows = [];
        allTemplates?.map((x) => {
            allRows.push({
                label: (
                    <span className="">
                        <strong>
                            {x.name}
                        </strong>
                    </span>
                ),
                value: x.id,
                ...x
            });
        });
        setWapTemplateSelectOptions(allRows);

        console.log(allRows)
    }, [allTemplates]);

    useEffect(() => {
        let allContacts = []
        allUsers?.map((item) => allContacts.push({
            label: item.name,
            value: item.id
        }))
        setWapContactSelectOptions(allContacts)
    }, [allUsers])


    const fetchUsersOnParamsChange = () => {
        let obj = processSearchParamsObj(searchParams)
        if (obj?.search?.length > 2) {
            dispatch(getWAPUsersAction(obj));
        }
    }

    useEffect(() => {
        fetchUsersOnParamsChange()
    }, [searchParams]);


    const onSubmitHandler = async () => {
        if (!values.group && !values.contact && !values.mobile) {
            AlertMessage("Atleast One Option Required to Send Message")
            return;
        }

        if (!values.template && !values.template_string) {
            AlertMessage("Template or Template String Required")
            return;
        }

        let newValues = {}
        if (values.contact) {
            newValues = { ...newValues, contact: values.contact.value }
        } else if (values.group) {
            newValues = { ...newValues, group: values.group.value }
        } else if (values.mobile) {
            newValues = { ...newValues, mobile: values.mobile }
        }

        if (values.template) {
            newValues = { ...newValues, template: values.template.value, templateParams: values.templateParams }
        } else {
            newValues = { ...newValues, template_string: values.template_string }
        }

        if(values.course){
            newValues = { ...newValues, course: values.course.value }
        }
        try {
            let response = await createWAPBroadCastsAPI(newValues)
            setIsPublished(true)
        } catch (error) {
            dispatch(errorHandler(error))
        }
    }


    const onDeleteClick = (_id) => {
        dispatch(deleteWAPBroadcastAction({ _id }));
        setCurrentData(null);
        setIsDeleting(false);
        setTimeout(() => {
            dispatch(getWAPBroadcastsAction());
        }, 200);
    };


    const onGroupChange = (data) => {
        if (data) {
            props.history.push(
                `${props.history.location.pathname}?groupId=${data.value}`
            );
        } else {
            props.history.push(
                `${props.history.location.pathname}`
            );
        }
    }

    useEffect(() => {
        if (groupId) {
            dispatch(getWAPBroadcastsAction({ groupId: groupId }))
        } else {
            dispatch(getWAPBroadcastsAction())
        }
    }, [groupId])




    useEffect(() => {
        let allRows = [];
        fetchedData?.map((data, index) => {
            let oneRow = {
                sn: index + 1,
                name: <span
                    onClick={() => {
                        props.history?.push(`/dashboard/whatsapp/contact?broadcastId=${data.id}`)
                    }}
                    className="cursor-pointer">{data.name}</span>,
                group: data.broadcast?.name,
                total: data.message?.length,
                pending: <span
                    onClick={() => {
                        props.history?.push(`/dashboard/whatsapp/contact?broadcastId=${data.id}&status=pending`)
                    }}
                    className="cursor-pointer">{data.status?.pending || 0}</span>,
                sent: <span
                    onClick={() => {
                        props.history?.push(`/dashboard/whatsapp/contact?broadcastId=${data.id}&status=sent`)
                    }}
                    className="cursor-pointer">{data.status?.sent || 0}</span>,
                delivered: <span
                    onClick={() => {
                        props.history?.push(`/dashboard/whatsapp/contact?broadcastId=${data.id}&status=delivered`)
                    }}
                    className="cursor-pointer">{data.status?.delivered || 0}</span>,
                read: <span
                    onClick={() => {
                        props.history?.push(`/dashboard/whatsapp/contact?broadcastId=${data.id}&status=read`)
                    }}
                    className="cursor-pointer">{data.status?.read || 0}</span>,
                failed: <span
                    onClick={() => {
                        props.history?.push(`/dashboard/whatsapp/contact?broadcastId=${data.id}&status=failed`)
                    }}
                    className="cursor-pointer">{data.status?.failed || 0}</span>,
                date: new Date(data.createdAt).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                }),
                action: (
                    <span>
                        <i
                            onClick={() => {
                                setIsDeleting(true);
                                setCurrentData(data);
                            }}
                            className={`simple-icon-trash cursor-pointer c_ed7117 mr-4px`}
                        />
                    </span>
                ),
            };
            allRows.push(oneRow);
        });

        setDatatable({ ...datatable, rows: allRows });
    }, [fetchedData]);


    props = {
        ...props,
        isDeleting,
        setIsDeleting,
        currentData,
        setCurrentData,
        onDeleteClick,
    };

    return (<>
        <div>
            {isDeleting && <DeleteConfirmationPopUp {...props} />}
            <Row>
                <Colxx xxs="12">
                    <div className="table-header-container">
                        <span className="table-header-title">
                            List of Broadcasts
                        </span>
                        {
                            !isAdding && <div>
                                <button
                                    onClick={() => {
                                        setIsAdding(true);
                                    }}
                                    className="shadow-box create-new-button"
                                >
                                    Publish New
                                </button>
                            </div>
                        }
                    </div>
                    <Separator className="mb-5" />
                </Colxx>
            </Row>
            {!isAdding && <div className="d-flex">
                <div className="student-filter-section">
                    <div className="w-304px mb-10px mr-10px">
                        <label className="input-label fw-400 fs-14px">
                            Filter By Group
                        </label>

                        <Select value={wapGroupSelectOptions.find(x => x.value === groupId)}
                            isClearable
                            onChange={(data) => {
                                onGroupChange(data)
                            }}
                            options={wapGroupSelectOptions}
                        />
                    </div>
                </div>
            </div>
            }

            {isAdding && <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
                <span className="table-header-title ml-20px mt-10px">
                    Publish New Broadcast Messages
                </span>

                {!isPublished && <div className="d-flex flex-grow-1 min-width-zero">
                    <div className="card-body accordion-content pt-0">
                        <Row>
                            <Colxx lg="12" xl="4" className="mt-20px">
                                <label className="input-label fw-400 fs-14px">
                                    Select Group
                                </label>
                                <Select
                                    options={wapGroupSelectOptions}
                                    isClearable
                                    value={values.group}
                                    onChange={(data) => {
                                        setValues({ ...values, group: data })
                                    }}
                                />
                            </Colxx>

                            <Colxx lg="12" xl="4" className="mt-20px">
                                <label className="input-label fw-400 fs-14px">
                                    Select Contact
                                </label>
                                <Select
                                    options={wapContactSelectOptions}
                                    isClearable
                                    value={values.contact}
                                    onChange={(data) => {
                                        setValues({ ...values, contact: data })
                                    }}
                                    onInputChange={(data) => {
                                        if (data?.length < 3) setWapContactSelectOptions([])
                                        setSearchParams({ ...searchParams, search: { value: data } })
                                    }}
                                />
                            </Colxx>

                            <Colxx lg="12" xl="4" className="mt-20px">
                                <label className="input-label fw-400 fs-14px">
                                    Select WAP Template
                                </label>
                                <Select
                                    options={wapTeplateSelectOptions}
                                    isClearable
                                    value={values.template}
                                    onChange={(data) => {
                                        setValues({ ...values, template: data, templateParams: data?.template_params || {} })
                                    }}
                                />
                            </Colxx>

                            <Colxx lg="12" xl="6" className="mt-20px">
                                <label className="input-label fw-400 fs-14px">
                                    Related to Course
                                </label>
                                <Select
                                    options={relatedCourseSelectOptions}
                                    isClearable
                                    value={values.course}
                                    onChange={(data) => {
                                        setValues({ ...values, course: data})
                                    }}
                                />
                            </Colxx>

                            {
                                values.template && values.template?.number_of_params > 0 && <div className="container mt-20px">
                                    <p className="fs-20px fw-600">Total {values.template?.number_of_params} Params Required for this Templates</p>
                                    {values?.template?.header_params > 0 && <div>
                                        <p className="mt-20px fw-900 mb-n10px">Header Parameter</p>
                                        {
                                            arrRange(1, values.template?.header_params, 1).arr?.map(params => {
                                                return <Colxx lg="12" xl="6" className="mt-10px">
                                                    <label className="input-label fw-400 fs-14px">
                                                        Header - {params}
                                                    </label>
                                                    <input
                                                        type={"text"}
                                                        className="form-control br-10px"
                                                        value={values.templateParams[`header${params}`]}
                                                        onChange={e => setValues({
                                                            ...values, templateParams:
                                                                { ...values.templateParams, [`header${params}`]: e.target.value }
                                                        })}
                                                    />
                                                </Colxx>
                                            })
                                        }
                                    </div>}

                                    {values?.template?.body_params > 0 && <div>
                                        <p className="mt-20px fw-900 mb-n10px">Body Parameter</p>
                                        {
                                            arrRange(1, values.template?.body_params, 1).arr?.map(params => {
                                                return <Colxx lg="12" xl="6" className="mt-10px">
                                                    <label className="input-label fw-400 fs-14px">
                                                        Body Parameter - {params}
                                                    </label>
                                                    <input
                                                        type={"text"}
                                                        className="form-control br-10px"
                                                        value={values.templateParams[`body${params}`]}
                                                        onChange={e => setValues({
                                                            ...values, templateParams:
                                                                { ...values.templateParams, [`body${params}`]: e.target.value }
                                                        })}
                                                    />
                                                </Colxx>
                                            })
                                        }
                                    </div>}

                                    {values?.template?.footer_params > 0 && <div>
                                        <p className="mt-20px fw-900 mb-n10px">Footer Parameter</p>
                                        {
                                            arrRange(1, values.template?.footer_params, 1).arr?.map(params => {
                                                return <Colxx lg="12" xl="6" className="mt-10px">
                                                    <label className="input-label fw-400 fs-14px">
                                                        Footer Parameter - {params}
                                                    </label>
                                                    <input
                                                        type={"text"}
                                                        className="form-control br-10px"
                                                        value={values.templateParams[`footer${params}`]}
                                                        onChange={e => setValues({
                                                            ...values, templateParams:
                                                                { ...values.templateParams, [`footer${params}`]: e.target.value }
                                                        })}
                                                    />
                                                </Colxx>
                                            })
                                        }
                                    </div>}

                                </div>
                            }


                            <Colxx lg="12" xl="12" className="mt-20px">
                                <label className="input-label fw-400 fs-14px">
                                    Mobile Number
                                </label>
                                <input
                                    type={"text"}
                                    className="form-control br-10px"
                                    placeholder="ex. 9143546565564"
                                    value={values.mobile}
                                    onChange={e => setValues({ ...values, mobile: e.target.value })}
                                />
                            </Colxx>

                            <Colxx lg="12" xl="12" className="mt-20px">
                                <label className="input-label fw-400 fs-14px">
                                    Template String
                                </label>
                                <textarea
                                    type={"text"}
                                    className="form-control br-10px"
                                    placeholder="Write Your Msg Here."
                                    value={values.template_string}
                                    onChange={e => setValues({ ...values, template_string: e.target.value })}
                                />
                            </Colxx>


                            <div className="d-flex justify-content-flex-end align-items-center w-100percent mt-30px">
                                <Button
                                    size={"sm"}
                                    color="secondry"
                                    className="br-20px "
                                    onClick={() => {
                                        setIsAdding(!isAdding);
                                        setValues(defaultValues)
                                    }}
                                >
                                    <b>Cancle</b>
                                </Button>
                                <Button onClick={onSubmitHandler}
                                    size={"sm"}
                                    color="secondry"
                                    className="br-20px c_white shadow-box create-new-button"
                                >
                                    Publish Now
                                </Button>
                            </div>
                        </Row>
                    </div>
                </div>}

                {isPublished && <div className="h-284px d-flex justify-content-center align-items-center flex-direction-column">
                    <p className="fs-20px c_4CAF50 fw-900">You Have published Your Broadcast successfully. You can track report on table.</p>
                    <div>
                        <button onClick={() => setIsPublished(!isPublished)} className="btn-primary border-0 mr-10px p-2 pl-20px pr-20px br-20px fw-900">Publish New</button>
                        <button onClick={() => {
                            setIsAdding(false)
                            dispatch(getWAPBroadcastsAction())
                        }} className="btn-dark border-0 p-2 pl-20px pr-20px br-20px fw-900">Check Report</button>
                    </div>

                </div>}

            </Card>}

            <Row>
                {!isAdding && (
                    <Colxx lg="12" xl="12">
                        <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
                            <div className="d-flex flex-grow-1 min-width-zero">
                                <div
                                    className="card-body accordion-content pt-0"
                                    style={{ maxWidth: "100%" }}
                                >
                                    <MDBDataTable
                                        fixed
                                        hover
                                        entriesOptions={[25, 50, 100, 500, 1000]}
                                        entries={25}
                                        pagesAmount={4}
                                        data={datatable}
                                        materialSearch
                                        // searchTop
                                        fullPagination
                                        striped
                                        theadColor="dark"
                                        searching={false}
                                        pagingTop
                                        scrollX={true}
                                        // scrollY
                                        maxHeight={1000}
                                        searchBottom={false}
                                    // barReverse={true}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Colxx>
                )}
            </Row>
        </div>
    </>)

}

export default NewWAPBroadCast