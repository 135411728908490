import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import { useSelector, useDispatch } from "react-redux";
import Switch from "rc-switch";
import {
  deleteUserDataAction,
  errorHandler,
  getAllUsersDataAction,
} from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import {
  createNewUserAPI,
  updateUserAPI,
} from "../../../../../services/master";
import { checkIfEmail } from "../../../../../helpers/Utils";
import CustomeTable from "../../../../../components/common/table";
import UserFilterSection from "./userFilter";
import { useAuthRole } from "../../../../../helpers/hooks";
const queryString = require("query-string");

const Users = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [searchParams, setSearchParams] = useState({});
  const [searchObject, setSearchObject] = useState({});
  let defaultValues = {
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    username: "",
    is_active: true,
    is_staff: false,
    is_superuser: false,
  };
  let { action } = queryString.parse(props.location.search);
  const [values, setValues] = useState(defaultValues);

  const dispatch = useDispatch();
  const { users: fetchedData, totalCount } = useSelector(
    (state) => state.master.allUsers
  );
  const isApiSuccess = useSelector((state) => state.master.isApiSuccess);
  const currentUser = useSelector((state) => state.authUser.currentUser);
  const { isAdmin, isStaff } = useAuthRole(currentUser);

  const adminColumns = [
    {
      label: "#",
      field: "sn",
      width: 60,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "id",
      },
      sort: "disabled",
    },
    {
      label: "Username",
      field: "username",
      width: 200,
    },
    {
      label: "Name",
      field: "name",
      width: 200,
    },
    {
      label: "Email",
      field: "email",
      width: 250,
    },
    {
      label: "Student",
      field: "is_student",
      width: 100,
    },

    {
      label: "Is Active",
      field: "is_active",
      width: 120,
      sort: "disabled",
    },

    {
      label: "Is Staff",
      field: "is_staff",
      width: 120,
      sort: "disabled",
    },

    {
      label: "Is Superuser",
      field: "is_superuser",
      width: 150,
      sort: "disabled",
    },
    {
      label: "Last Login",
      field: "last_login",
      width: 250,
    },
    {
      label: "Date Joined",
      field: "date_joined",
      width: 250,
    },
    {
      label: "Action",
      field: "action",
      width: 100,
      sort: "disabled",
    },
  ];

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 60,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },
      {
        label: "Username",
        field: "username",
        width: 200,
      },
      {
        label: "Name",
        field: "name",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        width: 250,
      },

      {
        label: "Date Joined",
        field: "date_joined",
        width: 250,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
        sort: "disabled",
      },
    ],
    rows: [],
  });

  const onPageChange = (e) => {
    setSearchParams({ ...searchParams, ...e });
  };

  useEffect(() => {
    if (isAdmin) {
      setDatatable({ ...datatable, columns: adminColumns });
    }
  }, [isAdmin]);

  const onSubmitHandler = () => {
    if (
      values.username === null ||
      values.username === "" ||
      values.email === "" ||
      values.first_name === "" ||
      values.last_name === ""
    ) {
      AlertMessage(
        "All Fields Required.",
        "error",
        "To create/update user you have to define all input fields"
      );
      return;
    }

    if (!isEditing && values.password === "") {
      AlertMessage("Password Required", "error");
      return;
    }

    if (!checkIfEmail(values.username) || !checkIfEmail(values.username)) {
      AlertMessage("Email Format should be correct", "error");
      return;
    }

    let newValues = { ...values };

    let finalMethod = createNewUserAPI;
    if (isEditing) {
      finalMethod = updateUserAPI;
    }

    finalMethod(newValues, { _id: isEditing ? currentData.id : 0 }).then(
      (res) => {
        setIsEditing(false);
        setIsAdding(false);
        setCurrentData(false);
        setValues(defaultValues);
        dispatch(getAllUsersDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  useEffect(() => {
    if (isApiSuccess) {
      setIsAdding(false);
      setIsEditing(false);
      setValues(defaultValues);
    }
  }, [isApiSuccess]);

  useEffect(() => {
    if (action === "add-new") {
      setIsAdding(true);
      setCurrentData(null);
    }
  }, [action]);

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: index + 1,
        username: data.username,
        name: data.first_name + " " + data.last_name,
        email: data.email,
        is_student: data.is_student,
        is_active: (
          <Switch
            className="custom-switch custom-switch-2-inverse custom-switch-small"
            checked={data.is_active}
            onChange={(status) => onUserStatusChange(data, "is_active", status)}
          />
        ),
        is_staff: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.is_staff}
            onChange={(status) => {
              data.institute_user
                ? AlertMessage("Institute associated with this user ")
                : onUserStatusChange(data, "is_staff", status);
            }}
          />
        ),
        is_superuser: (
          <Switch
            className="custom-switch custom-switch-primary-inverse custom-switch-small"
            checked={data.is_superuser}
            onChange={() => {
              AlertMessage("You can't change superuser status");
            }}
          />
        ),
        last_login: data.last_login
          ? new Date(data.last_login).toLocaleString("en-IN", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "Not LoggedIn",
        date_joined: new Date(data.date_joined).toLocaleString("en-IN", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
        action: (
          <span>
            <i
              onClick={() => {
                onEditClick(data);
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />

            {isAdmin && (
              <i
                onClick={() => {
                  if (!data.is_superuser && !data.is_staff) {
                    setIsDeleting(true);
                    setCurrentData(data);
                  }
                }}
                className={`simple-icon-trash ${
                  !data.is_superuser && !data.is_staff
                    ? "c_ed7117 cursor-pointer"
                    : "text-disabled fw-900"
                } mr-4px`}
              />
            )}
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [fetchedData]);

  useEffect(() => {
    dispatch(getAllUsersDataAction(searchObject));
  }, []);

  const onDeleteClick = (_id) => {
    dispatch(deleteUserDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const onUserStatusChange = (data, field, status) => {
    if (!data.is_superuser) {
      updateUserAPI({ [field]: status }, { _id: data.id }).then(
        (res) => {
          dispatch(getAllUsersDataAction(searchObject));
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
    } else {
      AlertMessage("This is Super User. You can't deactivate this.");
    }
  };

  const onEditClick = (data) => {
    let valuesObj = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      password: "",
      username: data.username,
    };

    setValues(valuesObj);
    setIsEditing(true);
    setCurrentData(data);
  };

  useEffect(() => {
    if (isStaff) {
      setValues({ ...values, password: "12345678" });
    }
  }, [isStaff]);

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              {!isAdding && !isEditing ? "List of Users" : ""}
              {isAdding && "Add New User"}
              {isEditing && "Edit User"}
              {!isAdding && !isEditing && (
                <p className="fs-14px c_black-60p">
                  This is the data of users, who registerd in our courses.
                </p>
              )}
            </span>
            {!isAdding && !isEditing && (
              <div>
                <button
                  onClick={() => {
                    setIsAdding(true);
                  }}
                  className="shadow-box create-new-button"
                >
                  Add New User
                </button>
              </div>
            )}
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!isAdding && !isEditing && (
        <Row>
          <UserFilterSection
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setSearchObject={setSearchObject}
          />
        </Row>
      )}
      <Row>
        {!isAdding && !isEditing && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div
                  className="card-body accordion-content pt-0"
                  style={{ maxWidth: "100%" }}
                >
                  <CustomeTable
                    onPageChange={onPageChange}
                    datatable={datatable}
                    total={totalCount}
                  />
                </div>
              </div>
            </Card>
          </Colxx>
        )}

        {(isAdding || isEditing) && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div className="card-body accordion-content pt-0">
                  <Row className="mt-30px">
                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        User First Name{" "}
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. Prateek"
                        value={values.first_name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            first_name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        User Last Name{" "}
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. Mishra"
                        value={values.last_name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            last_name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Email <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. training@techsimplus.com"
                        value={values.email}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            email: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="6" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Username<span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. username"
                        value={values.username}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            username: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    {(isAdmin || !isEditing) && (
                      <Colxx lg="12" xl="6" className="mt-10px">
                        <label className="input-label fw-400 fs-14px">
                          {isEditing ? "Update Password" : "Password"}
                          {!isEditing && (
                            <span className="c_B00020 ml-2px">*</span>
                          )}
                        </label>
                        <input
                          disabled={isStaff}
                          type={"password"}
                          className="form-control br-10px"
                          placeholder="ex. ......"
                          value={values.password}
                          onChange={(data) =>
                            setValues({
                              ...values,
                              password: data.target.value,
                            })
                          }
                        />
                        {isStaff && (
                          <p className="c_FFA726 fw-900 mt-4px">
                            Password will be sent to the user email address.
                          </p>
                        )}
                      </Colxx>
                    )}

                    <div className="d-flex justify-content-flex-end w-100percent">
                      <Button
                        onClick={() => {
                          setIsAdding(false);
                          setIsEditing(false);
                          setCurrentData(null);
                          setValues(defaultValues);
                          props.history.push(
                            `${props.history.location.pathname}`
                          );
                        }}
                        size={"sm"}
                        color="dark"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onSubmitHandler}
                        size={"sm"}
                        color="secondry"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        {isEditing ? "Update" : "Create"}
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Card>
          </Colxx>
        )}
      </Row>
    </React.Fragment>
  );
};

export default Users;
