import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import {
  getCourseCategoriesDataAction,
  updateCourseCategoriesDataAction,
  addNewCourseCategoriesDataAction,
  deleteCourseCategoriesDataAction,
} from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import ViewImageByUrl from "../../../../../components/common/viewImageByUrl";
import { getWebSiteTrafficAPI } from "../../../../../services/master";
import { formateDate } from "../../../../../helpers/Utils";

const WebTraffic = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [values, setValues] = useState({
    name: "",
    icon: "",
  });

  const dispatch = useDispatch();
  const courseCategories = useSelector(
    (state) => state.master.courseCategories
  );

  const onDeleteClick = (_id) => {
    dispatch(deleteCourseCategoriesDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 60,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Source",
        field: "utm_source",
        width: 200,
      },

      {
        label: "Campaign",
        field: "utm_campaign",
        width: 200,
      },

      {
        label: "Total",
        field: "count",
        width: 100,
      },
    ],
    rows: [],
  });

  const [dateWiseTable, setDateWiseTable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 60,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Date",
        field: "date",
        width: 200,
      },

      {
        label: "Count",
        field: "count",
        width: 200,
      },
    ],
    rows: [],
  });



  useEffect(() => {
    getWebSiteTrafficAPI().then(res => {
      let data = res.data?.data || []
      let dateWiseVisit = res?.data?.dateWiseVisit || []

      let allRows = []
      data?.map((row, index) => {
        let utm_source = row.utm_source || ""
        if (!row.utm_source) return false;

        row?.campaigns?.map((campaign, c_index) => {
          let oneRow = {
            sn: `${index + 1}.${c_index + 1}`,
            utm_source,
            utm_campaign: campaign.campaign,
            count: campaign.count,
          };
          allRows.push(oneRow)
        })

      })
      let allDateRows = []
      dateWiseVisit?.map((row, index) => {
        let oneRow = {
          sn: `${index + 1}}`,
          date: formateDate(row.date),
          count: row.count
        }
        allDateRows.push(oneRow)
      })
      setDatatable({ ...datatable, rows: allRows })
      setDateWiseTable({ ...dateWiseTable, rows: allDateRows })
    }, (error) => { })
  }, []);

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              Web Traffic
              <p className="fs-14px c_black-60p">
                This is Web Traffic Coming from Different Places
              </p>
            </span>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx lg="12" xl="7">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div
                className="card-body accordion-content pt-0"
                style={{ maxWidth: "100%" }}
              >
                <MDBDataTable
                  fixed
                  hover
                  entriesOptions={[25, 50, 100, 500, 1000]}
                  entries={25}
                  pagesAmount={4}
                  data={datatable}
                  materialSearch
                  // searchTop
                  fullPagination
                  striped
                  theadColor="dark"
                  searching={false}
                  pagingTop
                  scrollX={true}
                  // scrollY
                  maxHeight={1000}
                  searchBottom={false}
                // barReverse={true}
                />
              </div>
            </div>
          </Card>
        </Colxx>
        <Colxx lg="12" xl="5">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div
                className="card-body accordion-content pt-0"
                style={{ maxWidth: "100%" }}
              >
                <MDBDataTable
                  fixed
                  hover
                  entriesOptions={[25, 50, 100, 500, 1000]}
                  entries={25}
                  pagesAmount={4}
                  data={dateWiseTable}
                  materialSearch
                  // searchTop
                  fullPagination
                  striped
                  theadColor="dark"
                  searching={false}
                  pagingTop
                  scrollX={true}
                  // scrollY
                  maxHeight={1000}
                  searchBottom={false}
                // barReverse={true}
                />
              </div>
            </div>
          </Card>
        </Colxx>

      </Row>
    </React.Fragment>
  );
};

export default WebTraffic;
