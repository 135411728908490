import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
} from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";

import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import {
  getTempletesDataAction,
  updateTempletesDataAction,
  addNewTempletesDataAction,
  deleteTempletesDataAction,
} from "../../../../../redux/master/actions";
import Editor from "@monaco-editor/react";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import Select from "react-select";

const Templetes = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [values, setValues] = useState({
    name: "",
    templete: "",
  });
  const [showPreview, setShowPreview] = useState(false);
  const [showDynamicValues, setShowDynamicValues] = useState(false);
  const dispatch = useDispatch();
  const fetchedData = useSelector((state) => state.master.templetes);

  const onDeleteClick = (_id) => {
    dispatch(deleteTempletesDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const [certificateOptions, setCertificateOptions] = useState([]);

  const onSubmitHandler = () => {
    if (values.name === "") {
      AlertMessage("Templete Name Required", "info");
      return;
    }
    if (isEditing) {
      dispatch(updateTempletesDataAction(values, currentData.id, setValues));
      setCurrentData(null);
      setIsEditing(false);
      setCertificateOptions([]);
    } else dispatch(addNewTempletesDataAction(values, setValues));
  };

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      allRows.push({ label: data.name, value: data.id });
    });

    setCertificateOptions([...certificateOptions, ...allRows]);
  }, [fetchedData]);

  useEffect(() => {
    dispatch(getTempletesDataAction());
  }, []);

  function handleEditorChange(value, event) {
    setValues({ ...values, templete: value });
  }

  const dynamicValuesOptions = [
    { label: "Certificate Number", value: "{{certificate_number}}" },
    { label: "What You Did", value: "{{what_you_did}}" },
    { label: "Tag Line", value: "{{tag_line}}" },
    { label: "Learning Period", value: "{{learning_period}}" },
    { label: "Project Name", value: "{{project_name}}" },
    { label: "Issue Date", value: "{{issue_date}}" },
    { label: "Student Name", value: "{{name}}" },
    { label: "Student College Name", value: "{{institute_name}}" },
    { label: "Designation", value: "{{designation}}" },
    { label: "Course Name", value: "{{course_name}}" },
    { label: "Learning Mode", value: "{{learning_mode}}" },
  ].sort((a, b) => a.label.localeCompare(b.label));

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      {showPreview && (
        <Modal
          isOpen={showPreview}
          toggle={() => {
            setShowPreview(!showPreview);
          }}
          backdrop={true}
          size="lg"
        >
          {parse(values.templete)}
        </Modal>
      )}

      {showDynamicValues && (
        <Modal
          toggle={() => {
            setShowDynamicValues(!showDynamicValues);
          }}
          size={"md"}
          isOpen={showDynamicValues}
        >
          <div className="p-3">
            {dynamicValuesOptions?.map((item) => {
              return (
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(item.value);
                    AlertMessage(`Value Copy - ${item.value}`)
                  }}
                  className="shadow-box mb-2 p-2 br-20px d-flex justify-content-between cursor-pointer"
                >
                  <span className="fw-900">{item.label}</span>
                  <span className="fw-600 c_FFA726">{item.value}</span>
                </div>
              );
            })}
          </div>
        </Modal>
      )}
      <Row className="mt-n20px">
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              Certificate Templates
              <p className="fs-14px c_black-60p">
                Certificate Templetes are used to generate certificates for
                students.
              </p>
            </span>
            <div style={{ width: 300 }}>
              <Select
                isClearable
                options={certificateOptions}
                className="react-select"
                classNamePrefix="react-select"
                value={values.data}
                onChange={(data) => {
                  let oneCertificate = fetchedData.find(
                    (x) => x.id === data?.value
                  );
                  setIsEditing(data ? true : false);
                  setValues({
                    ...values,
                    name: oneCertificate ? oneCertificate.name : "",
                    templete: oneCertificate ? oneCertificate.templete : "",
                    data: data,
                  });
                  if (data) {
                    setCurrentData({ id: oneCertificate.id });
                  } else {
                    setCurrentData(null);
                  }
                }}
              />
            </div>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row className="mt-n20px">
        <Colxx lg="12" xl="3">
          <Card className="d-flex mb-3 br-0px shadow-box ">
            <div className="d-flex min-width-zero">
              <input
                type={"text"}
                className="form-control  h-35px br-0px"
                placeholder="Templete Name..."
                value={values.name}
                onChange={(data) =>
                  setValues({
                    ...values,
                    name: data.target.value,
                  })
                }
              />
            </div>
          </Card>
        </Colxx>
        <Colxx lg="12" xl="3" className="mt-6px">
          <i
            onClick={() => {
              setShowDynamicValues(true);
            }}
            className="simple-icon-eye fs-20px cursor-pointer c_00508B fw-900 mr-4px"
          />
        </Colxx>
        <Colxx lg="12" xl="6">
          <div className="d-flex justify-content-flex-end w-100percent">
            <Button
              onClick={onSubmitHandler}
              size={"sm"}
              color="secondry"
              className="br-20px c_white shadow-box create-new-button"
            >
              {isEditing ? "Update" : "Create"}
            </Button>

            {isEditing && values.data && (
              <Button
                onClick={() => {
                  setIsDeleting(true);
                  setCurrentData({ id: values.data.value });
                }}
                size={"sm"}
                color="secondry"
                className="br-20px c_white shadow-box create-new-button"
              >
                Delete
              </Button>
            )}

            <Button
              onClick={() => {
                setShowPreview(true);
              }}
              size={"sm"}
              color="secondry"
              className="br-20px c_white shadow-box create-new-button bg-primary-variant"
            >
              Preview
            </Button>
          </div>
        </Colxx>
      </Row>

      <Row>
        <Colxx lg="12" xl="12">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div className="card-body accordion-content pt-0">
                <div className="mt-10px">
                  <Editor
                    height="520px"
                    defaultLanguage="html"
                    defaultValue={values.templete}
                    onChange={handleEditorChange}
                    value={values.templete}
                  />
                </div>
              </div>
            </div>
          </Card>
        </Colxx>
        {/* <Colxx lg="12" xl="6">
          
        </Colxx> */}
      </Row>
    </React.Fragment>
  );
};

export default Templetes;
