import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import Select from "react-select";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import { useDispatch } from "react-redux";
import { errorHandler } from "../../../../../redux/master/actions";
import {
  createWebSiteSettingAPI,
  getWebSiteSettingAPI,
  updateWebSiteSettingAPI,
} from "../../../../../services/master";
import AlertMessage from "../../../../../helpers/alerts";
import { yesNoOptions } from "../../../../../constants/selectOptions";

const WebSetting = (props) => {
  const [currentDataId, setCurrentDataId] = useState(null);

  let defaultValues = {
    oneEventUrl: "",
    isOneEventActive: false,
    mainPageTitle: "",
    headerMainTitle: "",
  };
  const [values, setValues] = useState(defaultValues);

  const dispatch = useDispatch();

  useEffect(() => {
    getWebSiteSettingAPI().then(
      (res) => {
        if (res.data?.data[0]) {
          let data = res.data.data[0];
          setValues({
            oneEventUrl: data?.oneEventUrl || "",
            mainPageTitle: data?.mainPageTitle || "",
            isOneEventActive: data?.isOneEventActive || false,
            headerMainTitle: data?.headerMainTitle || "",
          });
          setCurrentDataId(data?.id);
        }
      },
      (error) => {}
    );
  }, []);

  const onSubmitHandler = () => {
    let newValues = { ...values };

    let finalMethod = createWebSiteSettingAPI;
    if (currentDataId) {
      finalMethod = updateWebSiteSettingAPI;
    }

    finalMethod(newValues, { _id: currentDataId || 0 }).then(
      (res) => {
        AlertMessage("Setting Updated Successfully");
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              Website Setting
              <p className="fs-14px c_black-60p">
                This is the Complete Setting of techsimplus website.
              </p>
            </span>
          </div>
          {/* <Separator className="mb-5" /> */}
        </Colxx>
      </Row>
      <Row>
        <Colxx lg="12" xl="12">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div className="card-body accordion-content pt-0">
                <Row className="mt-30px">
                  <Colxx lg="4" xl="4" className="mt-10px">
                    <label className="input-label fw-400 fs-14px">
                      One Event Url
                      <span className="c_B00020 ml-2px">*</span>
                    </label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. Prateek"
                      value={values.oneEventUrl}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          oneEventUrl: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <Colxx lg="4" xl="4" className="mt-10px">
                    <label className="input-label fw-400 fs-14px">
                      Is One Event Url Active{" "}
                      <span className="c_B00020 ml-2px">*</span>
                    </label>
                    <Select
                      isClearable
                      options={yesNoOptions}
                      className="react-select"
                      classNamePrefix="react-select"
                      value={
                        yesNoOptions.find(
                          (x) => x.value === values.isOneEventActive
                        ) || yesNoOptions[0]
                      }
                      onChange={(data) =>
                        setValues({ ...values, isOneEventActive: data.value })
                      }
                    />
                  </Colxx>

                  <Colxx lg="12" xl="12" className="mt-10px">
                    <label className="input-label fw-400 fs-14px">
                      Main Page Title
                      <span className="c_B00020 ml-2px">*</span>
                    </label>
                    <textarea
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="Add the main page title"
                      value={values.mainPageTitle}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          mainPageTitle: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <Colxx lg="4" xl="4" className="mt-10px">
                    <label className="input-label fw-400 fs-14px">
                      Header Main Title
                      <span className="c_B00020 ml-2px">*</span>
                    </label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="Summer Internship"
                      value={values.headerMainTitle}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          headerMainTitle: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <div className="d-flex justify-content-flex-end w-100percent">
                    <Button
                      onClick={onSubmitHandler}
                      size={"sm"}
                      color="secondry"
                      className="br-20px c_white shadow-box create-new-button mt-30px"
                    >
                      Update
                    </Button>
                  </div>
                </Row>
              </div>
            </div>
          </Card>
        </Colxx>
      </Row>
    </React.Fragment>
  );
};

export default WebSetting;
