import React from "react";
import {
  Route,
  withRouter,
  Switch,
  Redirect,
  useHistory,
  Router,
} from "react-router-dom";

import AppLayout from "../../../layout/AppLayout";
import CoursesBatch from "./batches";
import Courses from "./courses";
import Dashboard from "./dashboard";
import MasterData from "./masterData";
import StudentsData from "./students";
import BlogsData from "./blogs";
import WhatsappApp from "./whatsapp";
import ToDoApp from "./todos";
import StudentAttendance from "./attendance";

const MasterAdminPanel = (props) => {
  const { match } = props;
  return (
    <AppLayout>
      <Switch>
        <Route exact path={`${match.url}`} component={Dashboard} />
        <Route path={`${match.url}/master-data`} component={MasterData} />
        <Route path={`${match.url}/courses`} component={Courses} />
        <Route path={`${match.url}/one-courses`} component={Courses} />
        <Route path={`${match.url}/batches`} component={CoursesBatch} />
        <Route path={`${match.url}/students`} component={StudentsData} />
        <Route path={`${match.url}/attendance`} component={StudentAttendance} />
        <Route path={`${match.url}/todos`} component={ToDoApp} />
        <Route path={`${match.url}/whatsapp`} component={WhatsappApp} />
        <Route path={`${match.url}/blogs`} component={BlogsData} />
        <Redirect to="/dashboard" />
      </Switch>
    </AppLayout>
  );
};

export default MasterAdminPanel;
