import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import Switch from "rc-switch";
import {
  deleteInstituteDataAction,
  deleteUserDataAction,
  errorHandler,
  getAllInstitutesDataAction,
  getAllUsersDataAction,
  getCompaniesDataAction,
} from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import {
  createNewInstituteAPI,
  createNewUserAPI,
  updateInstituteAPI,
  updateUserAPI,
} from "../../../../../services/master";
import ViewImageByUrl from "../../../../../components/common/viewImageByUrl";
import { requiredFieldValidation } from "../../../../../helpers/Utils";

const Institutes = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  let defaultValues = {
    user: null,
    name: "",
    certificate_name: "",
    address: "",
    mobile: "",
    email: "",
    description: "",
    image: "",
    city: "",
  };
  const [values, setValues] = useState(defaultValues);

  const dispatch = useDispatch();
  const { users: allUsers } = useSelector((state) => state.master.allUsers);
  const fetchedData = useSelector((state) => state.master.allInstitutes);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },
      {
        label: "User",
        field: "user",
        width: 200,
      },
      {
        label: "Institute Name",
        field: "name",
        width: 250,
      },
      {
        label: "Name on Certificates",
        field: "certificate_name",
        width: 250,
      },
      {
        label: "Mobile Number",
        field: "mobile",
        width: 180,
      },

      {
        label: "Email",
        field: "email",
        width: 180,
      },

      {
        label: "City",
        field: "city",
        width: 180,
      },
      {
        label: "Is Active",
        field: "is_login_allowed",
        width: 120,
        sort: "disabled",
      },

      {
        label: "Logo",
        field: "image",
        width: 220,
        sort: "disabled",
      },

      {
        label: "Last Login",
        field: "last_login",
        width: 250,
      },

      {
        label: "Date Joined",
        field: "date",
        width: 250,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
        sort: "disabled",
      },
    ],
    rows: [],
  });

  const onSubmitHandler = () => {
    let [isExist, field] = requiredFieldValidation(values, [
      "image",
      "description",
    ]);
    if (!isExist) {
      AlertMessage(`Field - ${field}`, "error", "This field required");
      return;
    }

    let newValues = { ...values, user: values.user.value };

    let finalMethod = createNewInstituteAPI;
    if (isEditing) {
      finalMethod = updateInstituteAPI;
    }

    finalMethod(newValues, { _id: isEditing ? currentData.id : 0 }).then(
      (res) => {
        setIsEditing(false);
        setIsAdding(false);
        setCurrentData(null);
        setValues(defaultValues);
        dispatch(getAllInstitutesDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  useEffect(() => {
    let allOptions = [];
    allUsers?.map((user, index) => {
      if (user.is_active && user.is_staff) {
        allOptions.push({
          label: user.first_name + " " + user.last_name,
          value: user.id,
          key: index,
        });
      }
    });

    setUserSelectOptions(allOptions);
  }, [allUsers]);

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      console.log(data);
      let oneRow = {
        sn: index + 1,
        name: data.name,
        user: data?.user?.first_name + " " + data?.user?.last_name,
        email: data.email,
        certificate_name: data.certificate_name,
        address: data.address,
        mobile: data.mobile,
        description: data.description,
        image: <ViewImageByUrl imageUrl={data.image} />,
        city: data.city,
        date: new Date(data.date).toLocaleString("en-IN", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
        is_login_allowed: (
          <Switch
            className="custom-switch custom-switch-2-inverse custom-switch-small"
            checked={data?.user?.is_active}
            onChange={(status) =>
              onUserStatusChange(data.user, "is_active", status)
            }
          />
        ),

        last_login: data.user?.last_login
          ? new Date(data.user?.last_login).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "Not LoggedIn",
        date_joined: new Date(data.date_joined).toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
        action: (
          <span>
            <i
              onClick={() => {
                onEditClick(data);
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className={`simple-icon-trash ${
                data.user.is_superuser ? "text-disabled" : ""
              } cursor-pointer c_ed7117 mr-4px`}
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [fetchedData]);

  useEffect(() => {
    dispatch(getAllUsersDataAction());
    dispatch(getAllInstitutesDataAction());
  }, []);

  const onDeleteClick = (_id) => {
    dispatch(deleteInstituteDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
    setTimeout(() => {
      dispatch(getAllInstitutesDataAction());
    }, 200);
  };

  const onUserStatusChange = (data, field, status) => {
    if (!data.is_superuser) {
      updateUserAPI({ [field]: status }, { _id: data.id }).then(
        (res) => {
          dispatch(getAllInstitutesDataAction());
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
    } else {
      AlertMessage("This is Super User. You can't deactivate this.");
    }
  };

  const onEditClick = (data) => {
    let valuesObj = {
      user: userSelectOptions.find((x) => x.value === data.user?.id),
      name: data.name,
      certificate_name: data.certificate_name,
      address: data.address,
      mobile: data.mobile,
      email: data.email,
      description: data.description,
      image: data.image,
      city: data.city,
    };

    setValues(valuesObj);
    setIsEditing(true);
    setCurrentData(data);
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of Institutes
              <p className="fs-14px c_black-60p">
                These are the institutes or places where we are conducting
                trainings.
              </p>
            </span>
            <div>
              <button
                onClick={() => {
                  setIsAdding(true);
                }}
                className="shadow-box create-new-button"
              >
                Add New Institute
              </button>
            </div>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        {!isAdding && !isEditing && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div
                  className="card-body accordion-content pt-0"
                  style={{ maxWidth: "100%" }}
                >
                  <MDBDataTable
                    fixed
                    hover
                    entriesOptions={[25, 50, 100, 500, 1000]}
                    entries={25}
                    pagesAmount={4}
                    data={datatable}
                    materialSearch
                    // searchTop
                    fullPagination
                    striped
                    theadColor="dark"
                    searching={false}
                    pagingTop
                    scrollX={true}
                    // scrollY
                    maxHeight={1000}
                    searchBottom={false}
                    // barReverse={true}
                  />
                </div>
              </div>
            </Card>
          </Colxx>
        )}

        {(isAdding || isEditing) && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div className="card-body accordion-content pt-0">
                  <Row className="mt-30px">
                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Select User <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Select
                        value={values.user}
                        onChange={(data) =>
                          setValues({ ...values, user: data })
                        }
                        options={userSelectOptions}
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Institute Name{" "}
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. TechSim+"
                        value={values.name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Institute Name on Certificates{" "}
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. TechSimPlus - The Symbol of Expertise"
                        value={values.certificate_name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            certificate_name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="12" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Institute Address{" "}
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. 323, Block C, SP Bhopal - 111321"
                        value={values.address}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            address: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Email <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. training@techsimplus.com"
                        value={values.email}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            email: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Mobile Number<span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. 9893xxxx56"
                        value={values.mobile}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            mobile: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        City<span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. Bhopal"
                        value={values.city}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            city: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="6" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Image<span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. https://"
                        value={values.image}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            image: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="6" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Description
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. ......"
                        value={values.description}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            description: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <div className="d-flex justify-content-flex-end w-100percent">
                      <Button
                        onClick={() => {
                          setIsAdding(false);
                          setIsEditing(false);
                          setCurrentData(null);
                          setValues(defaultValues);
                        }}
                        size={"sm"}
                        color="dark"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onSubmitHandler}
                        size={"sm"}
                        color="secondry"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        {isEditing ? "Update" : "Create"}
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Card>
          </Colxx>
        )}
      </Row>
    </React.Fragment>
  );
};

export default Institutes;
