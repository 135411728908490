import React, { useEffect, useState } from "react";
import { Button, Card, Row } from "reactstrap";
import { Colxx } from "../../../../../../../components/common/CustomBootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import FeePaymentPopUp from "./feePay";
import { formateDate } from "../../../../../../../helpers/Utils";
import {
  deleteOneCertificatesAPI,
  getOneCertificatesAPI,
} from "../../../../../../../services/master";
import GenerateBulkCertificates from "../../../../batches/bulkCertificates";
import DeleteConfirmationPopUp from "../../../../../../../components/common/deleteConfirmationPopup";
import { getOneStudentDataAction } from "../../../../../../../redux/master/actions";

const AllAssignments = (props) => {
  const {
    isAssing,
    setIsAssign,
    isAssingEdit,
    setIsAssignEdit,
    currentAssign,
    setCurrentAssign,
    isAssingDelete,
    setIsAssignDelete,
  } = props;
  const [isCertificateGenerate, setIsCertificateGenerate] = useState(false);
  const [searchObj, setSearchObj] = useState(null);
  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Course Name",
        field: "course",
        width: 250,
      },
      {
        label: "Batch Name",
        field: "batch",
        width: 250,
      },

      {
        label: "Fee",
        field: "fee",
        width: 120,
      },

      {
        label: "Paid Fee",
        field: "paid_fee",
        width: 150,
      },

      {
        label: "Remain Fee",
        field: "remain_fee",
        width: 150,
      },

      {
        label: "Date",
        field: "date",
        width: 180,
      },

      {
        label: "Action",
        field: "action",
        width: 100,
        sort: "disabled",
      },
    ],
    rows: [],
  });
  const [feePayToggle, setFeePayToggle] = useState(false);
  const oneStudent = useSelector((state) => state.master.oneStudent);
  const dispatch = useDispatch();
  const [deleteCertificate, setDeleteCertificate] = useState(false);

  const onCertificateDeleteClick = async (_id) => {
    await deleteOneCertificatesAPI({
      _id,
      enrollmentId: deleteCertificate.enrollment_id,
    });
    dispatch(getOneStudentDataAction(props.student_id));
    setDeleteCertificate(null);
  };

  useEffect(() => {
    if (!isCertificateGenerate) {
      dispatch(getOneStudentDataAction(props.student_id));
    }
  }, [isCertificateGenerate]);

  useEffect(() => {
    let allAssings = [];
    props?.oneStudent?.enrollments?.map((x, index) => {
      let oneAssign = {
        sn: index + 1,
        course: x.course?.name,
        batch: x.batch?.name,
        fee: x.fee,
        paid_fee: x.paid_fee,
        remain_fee: x.remain_fee,
        date: formateDate(x.date),
        action: (
          <span className="d-flex align-items-center justify-content-flex-end">
            <i
              onClick={() => {
                setFeePayToggle(!feePayToggle);
                setCurrentAssign(x);
              }}
              className="simple-icon-paper-plane fs-20px cursor-pointer c_00508B fw-900 mr-4px"
            />
            {x.remain_fee > 0 && (
              <i
                onClick={() => {
                  setIsAssignEdit(true);
                  setCurrentAssign(x);
                }}
                className={`simple-icon-pencil c_ed7117 mr-4px fs-20px cursor-pointer`}
              />
            )}
            {x.remain_fee > 0 && x.paid_fee === 0 && (
              <i
                onClick={() => {
                  setIsAssignDelete(true);
                  setCurrentAssign(x);
                }}
                className="simple-icon-trash cursor-pointer fs-20px c_ed7117 mr-4px"
              />
            )}
            {x.isCertificateGenerated && x.certificate_enrollment && (
              <>
                <i
                  onClick={() => {
                    props.history.push(
                      `${props.history.location.pathname}?student_id=${oneStudent.id}&action=certificate&certificate_number=${x.certificate_enrollment}`
                    );
                  }}
                  className={`iconsminds-diploma-2 c_ed7117 mr-4px fs-20px cursor-pointer`}
                />

                <i
                  onClick={() => {
                    setDeleteCertificate({
                      id: x.certificate_enrollment,
                      enrollment_id: x.id,
                    });
                  }}
                  className={`simple-icon-trash c_ed7117 mr-4px fs-20px cursor-pointer`}
                />
              </>
            )}

            {!x.isCertificateGenerated && x.remain_fee === 0 && (
              <i
                onClick={() => {
                  setIsCertificateGenerate(x?.id);
                  setSearchObj({
                    course_id: x.course.id,
                    batch_id: x.batch.id,
                  });
                }}
                className={`simple-icon-paper-clip c_ed7117 mr-4px fs-20px ml-10px cursor-pointer`}
              />
            )}
          </span>
        ),
      };
      allAssings.push(oneAssign);
    });

    setDatatable({ ...datatable, rows: allAssings });
  }, [props.oneStudent]);

  props = { ...props, feePayToggle, setFeePayToggle };
  return (
    <React.Fragment>
      {feePayToggle && <FeePaymentPopUp {...props} />}
      {!!isCertificateGenerate && searchObj && (
        <GenerateBulkCertificates
          isOpen={!!isCertificateGenerate}
          setIsOpen={setIsCertificateGenerate}
          enrollmentId={isCertificateGenerate}
          searchObj={searchObj}
        />
      )}

      {deleteCertificate && (
        <DeleteConfirmationPopUp
          onDeleteClick={onCertificateDeleteClick}
          isDeleting={!!deleteCertificate}
          setIsDeleting={setDeleteCertificate}
          currentData={deleteCertificate}
          setCurrentData={setDeleteCertificate}
        />
      )}
      <Colxx lg="12" xl="12">
        <Card className="d-flex mt-3 br-20px" key={`faqItem_1`}>
          <div className="d-flex flex-grow-1 min-width-zero">
            <div
              className="card-body accordion-content"
              style={{ maxWidth: "100%" }}
            >
              <MDBDataTable
                fixed
                hover
                pagesAmount={4}
                data={datatable}
                materialSearch
                // searchTop
                fullPagination
                striped
                theadColor="dark"
                searching={false}
                pagingTop
                scrollX={true}
                // scrollY
                paging={false}
                maxHeight={1000}
                searchBottom={false}
                // barReverse={true}
              />
            </div>
          </div>
        </Card>
      </Colxx>
    </React.Fragment>
  );
};

export default AllAssignments;
