import {
  GET_LIST_OF_COMPANIES,
  GET_LIST_OF_COURSE_CATEGORIES,
  GET_LIST_OF_TECHNOLOGIES,
  GET_LIST_OF_JOB_TAGS,
  GET_LIST_OF_FAQS,
  GET_LIST_OF_TEMPLETES,
  GET_LIST_OF_FILES,
  GET_LIST_OF_TEAM_MEMBERS,
  SET_ONE_COURSE_DETAILS,
  SET_ERRRO_MSG,
  SET_API_PROGRESS,
  SET_ALL_COURSES_LIST,
  SET_ALL_BATCH_LIST,
  SET_ONE_BATCH_DETAILS,
  SET_ALL_USER_LIST,
  SET_ONE_USER_DETAILS,
  SET_ALL_INSTITUTE_LIST,
  SET_ONE_INSTITUTE_DETAILS,
  SET_ALL_STUDENT_LIST,
  SET_ONE_STUDENT_DETAILS,
  SET_ALL_STUDENT_ENROLLMENT_LIST,
  SET_ONE_STUDENT_ENROLLMENT_DETAILS,
  SET_ALL_ENQUIRES_LIST,
  GET_LIST_OF_BLOG_TOPICS,
  GET_ALL_BLOG_LIST,
  GET_ONE_BLOG_DETAILS,
  WAP_GET_ALL_TEMPLATES,
  FB_GET_ALL_LEAD_FORMS,
  FB_GET_ALL_LEADS,
  GET_LIST_OF_TODOS,
} from "../actions";

const INIT_STATE = {
  companies: [],
  courseCategories: [],
  technologies: [],
  todos: { todos: [], totalCount: 0 },
  jobTags: [],
  faqs: [],
  templetes: [],
  files: [],
  teams: [],
  blogTopics: [],
  allCourses: [],
  oneCourse: null,
  allBatch: [],
  oneBatch: null,
  allStudents: [],
  oneStudent: null,

  allBlogs: [],
  oneBlog: null,

  allEnquires: [],
  oneEnquire: null,

  allEnrollments: [],
  oneEnrollment: null,

  allUsers: { users: [], totalCount: 0 },
  oneUser: null,

  allInstitutes: [],
  oneInstitute: null,
  error: null,
  isApiSuccess: false,

  wapTemplates: [],
  fbForms: [],
  fbLeads: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ERRRO_MSG:
      return {
        ...state,
        error: action.payload,
      };
    case SET_API_PROGRESS:
      return {
        ...state,
        isApiSuccess: action.payload,
      };

    case GET_LIST_OF_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };

    case GET_LIST_OF_TODOS:
      return {
        ...state,
        todos: action.payload,
      };

    case WAP_GET_ALL_TEMPLATES:
      return {
        ...state,
        wapTemplates: action.payload,
      };
    case FB_GET_ALL_LEAD_FORMS:
      return {
        ...state,
        fbForms: action.payload,
      };
    case FB_GET_ALL_LEADS:
      return {
        ...state,
        fbLeads: action.payload,
      };

    case SET_ALL_COURSES_LIST:
      return {
        ...state,
        allCourses: action.payload,
      };
    case SET_ALL_ENQUIRES_LIST:
      return {
        ...state,
        allEnquires: action.payload,
      };

    case SET_ALL_BATCH_LIST:
      return {
        ...state,
        allBatch: action.payload,
      };

    case SET_ONE_BATCH_DETAILS:
      return {
        ...state,
        oneBatch: action.payload,
      };

    case GET_ALL_BLOG_LIST:
      return {
        ...state,
        allBlogs: action.payload,
      };

    case GET_ONE_BLOG_DETAILS:
      return {
        ...state,
        oneBlog: action.payload,
      };

    case SET_ALL_STUDENT_LIST:
      return {
        ...state,
        allStudents: action.payload,
      };

    case SET_ONE_STUDENT_DETAILS:
      return {
        ...state,
        oneStudent: action.payload,
      };

    case SET_ALL_STUDENT_ENROLLMENT_LIST:
      return {
        ...state,
        allEnrollments: action.payload,
      };

    case SET_ONE_STUDENT_ENROLLMENT_DETAILS:
      return {
        ...state,
        oneEnrollment: action.payload,
      };

    case SET_ALL_USER_LIST:
      return {
        ...state,
        allUsers: action.payload,
      };

    case SET_ONE_USER_DETAILS:
      return {
        ...state,
        oneUser: action.payload,
      };

    case SET_ALL_INSTITUTE_LIST:
      return {
        ...state,
        allInstitutes: action.payload,
      };

    case SET_ONE_INSTITUTE_DETAILS:
      return {
        ...state,
        oneInstitute: action.payload,
      };

    case GET_LIST_OF_COURSE_CATEGORIES:
      return {
        ...state,
        courseCategories: action.payload,
      };
    case GET_LIST_OF_TECHNOLOGIES:
      return {
        ...state,
        technologies: action.payload,
      };
    case GET_LIST_OF_JOB_TAGS:
      return {
        ...state,
        jobTags: action.payload,
      };
    case GET_LIST_OF_FAQS:
      return {
        ...state,
        faqs: action.payload,
      };
    case GET_LIST_OF_TEMPLETES:
      return {
        ...state,
        templetes: action.payload,
      };
    case GET_LIST_OF_FILES:
      return {
        ...state,
        files: action.payload,
      };
    case GET_LIST_OF_TEAM_MEMBERS:
      return {
        ...state,
        teams: action.payload,
      };
    case GET_LIST_OF_BLOG_TOPICS:
      return {
        ...state,
        blogTopics: action.payload,
      };
    case SET_ONE_COURSE_DETAILS:
      return {
        ...state,
        oneCourse: action.payload,
      };

    default:
      return { ...state };
  }
};
