import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getToDosDataAction } from "../../../../redux/master/actions";

const UserFilterSection = (props) => {
  const { searchParams, setSearchParams, setSearchObject } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    var newObj = {};
    Object.keys(searchParams).map((key) => {
      if (searchParams[key]) {
        if (searchParams[key].value.toString().length > 0)
          newObj[key] = searchParams[key].value;
      }
    });
    if (
      (!newObj.query || newObj.query?.length > 2) &&
      Object.keys(searchParams).length > 0
    ) {
      setSearchObject(newObj);
      dispatch(getToDosDataAction(newObj));
    }
  }, [searchParams]);

  return (
    <React.Fragment>
      <div className="d-flex">
        <div className="student-filter-section">
          {/* <div className="w-250px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">
              Select File Author
            </label>
            <Select
              options={userSelectOptions}
              isClearable
              onChange={(data) => {
                setSearchParams({
                  ...searchParams,
                  user: data,
                });
              }}
              filterOption={customFilter}
            />
          </div> */}

          <div className="w-303px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Search</label>
            <input
              type={"text"}
              className="form-control br-10px"
              placeholder="Search..."
              value={searchParams.query ? searchParams.query?.value : ""}
              onChange={(data) =>
                setSearchParams({
                  ...searchParams,
                  query: { value: data.target.value },
                })
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserFilterSection;
