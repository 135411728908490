import React, { useState, useEffect } from "react";
import { Button, Row } from "reactstrap";
import { Colxx } from "../../../../../../components/common/CustomBootstrap";
import { useSelector, useDispatch } from "react-redux";
import AlertMessage from "../../../../../../helpers/alerts";
import { updateCourseDetailsAPI } from "../../../../../../services/master";

const CourseWhatsappDetails = (props) => {
  const currentCourse = useSelector((state) => state.master.oneCourse);

  const [isKeyDeleting, setIsKeyDeleting] = useState(false);
  const [currentKey, setCurrentKey] = useState(null);
  let defaultValues = {
    wap_title: "",
    wap_description: "",
    wap_msg_on_select: "",
    wap_msg_image: "",
    bitly_link: "",
  };
  const [values, setValues] = useState(defaultValues);

  const onSubmitHandler = async () => {
    if (currentCourse) {
      updateCourseDetailsAPI(values, { _id: currentCourse.id }).then(
        (res) => {
          AlertMessage(
            "Course Whatsapp Details Updated Successfully",
            "success"
          );
        },
        (error) => {
          AlertMessage("Internal Server Error", "error");
        }
      );
    }
  };

  useEffect(() => {
    if (currentCourse) {
      setValues({
        wap_title: currentCourse.wap_title || "",
        wap_description: currentCourse.wap_description || "",
        wap_msg_image: currentCourse.wap_msg_image || "",
        wap_msg_on_select: currentCourse.wap_msg_on_select || "",
        bitly_link: currentCourse.bitly_link || "",
      });
    }
  }, [currentCourse]);

  props = {
    ...props,
    isDeleting: isKeyDeleting,
    setIsDeleting: setIsKeyDeleting,
    currentData: currentKey,
    setCurrentData: setCurrentKey,
  };

  return (
    <div className="card-body accordion-content pt-0">
      <Row className="">
        <Colxx lg="12" xl="3" className="mt-3">
          <label className="input-label fw-600 fs-14px">Whatsapp Title</label>
          <div className="d-flex align-items-center">
            <div className="w-100">
              <input
                placeholder="PyDjango: Python and django development"
                className="form-control  br-4px"
                value={values.wap_title}
                onChange={(e) => {
                  if (e?.target?.value.length <= 24)
                    setValues({ ...values, wap_title: e.target.value });
                }}
              />
            </div>
            <div>
              <span className="ml-n40px">
                {values.wap_title?.length || 0}/24
              </span>
            </div>
          </div>
        </Colxx>

        <Colxx lg="12" xl="6" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Whatsapp Description
          </label>

          <div className="d-flex align-items-center">
            <div className="w-100">
              <input
                placeholder="PyDjango: Python and django development"
                className="form-control  br-4px"
                value={values.wap_description}
                onChange={(e) => {
                  if (e?.target?.value.length <= 72)
                    setValues({ ...values, wap_description: e.target.value });
                }}
              />
            </div>
            <div>
              <span className="ml-n40px">
                {values.wap_description?.length || 0}/72
              </span>
            </div>
          </div>
        </Colxx>

        <Colxx lg="12" xl="3" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Whatsapp Sort Link
          </label>
          <div className="d-flex align-items-center">
            <div className="w-100">
              <input
                placeholder="bit.ly/dd32e232"
                className="form-control  br-4px"
                value={values.bitly_link}
                onChange={(e) => {
                  setValues({ ...values, bitly_link: e.target.value });
                }}
              />
            </div>
          </div>
        </Colxx>

        <Colxx lg="12" xl="6" className="mt-3">
          <label className="input-label fw-600 fs-14px">Whatsapp Message</label>

          <div className="d-flex align-items-flex-end">
            <div className="w-100">
              <textarea
                placeholder="PyDjango: Python and django development"
                className="form-control  br-4px"
                value={values.wap_msg_on_select}
                onChange={(e) => {
                  if (e?.target?.value.length <= 360)
                    setValues({ ...values, wap_msg_on_select: e.target.value });
                }}
              />
            </div>
            <div>
              <span className="ml-n40px pb-10px">
                {values.wap_msg_on_select?.length || 0}/360
              </span>
            </div>
          </div>
        </Colxx>

        <Colxx lg="12" xl="6" className="mt-3">
          <label className="input-label fw-600 fs-14px">
            Whatsapp Image Url
          </label>

          <div className="d-flex align-items-flex-end">
            <div className="w-100">
              <input
                placeholder="https://techsimplus.com/uploads/image.png"
                className="form-control  br-4px"
                value={values.wap_msg_image}
                onChange={(e) => {
                  if (e?.target?.value.length <= 360)
                    setValues({ ...values, wap_msg_image: e.target.value });
                }}
              />
            </div>
          </div>
        </Colxx>

        <div className="d-flex justify-content-flex-end w-100percent">
          <Button
            size={"sm"}
            color="secondry"
            onClick={onSubmitHandler}
            className="br-20px c_white shadow-box create-new-button mt-30px"
          >
            Update
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default CourseWhatsappDetails;
