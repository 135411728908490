import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneUserChatAction,
  getWAPUsersAction,
} from "../../../../../redux/whatsapp/actions";
import ChatApplicationMenu from "../components/ChatApplicationMenu";
import SaySomething from "../components/SaySomething";
import { Row } from "reactstrap";
import { Colxx } from "../../../../../components/common/CustomBootstrap";
import ChatHeading from "../components/ChatHeading";
import PerfectScrollbar from "react-perfect-scrollbar";
import MessageCard from "../components/MessageCard";
const queryString = require("query-string");

const WhatsappHome = (props) => {
  const scrollBarRef = useRef(null);
  const timerRef = useRef(null);
  const dispatch = useDispatch();
  const chats = useSelector((state) => state.wap.oneUserChat);
  const [autoScrollToBottom, setAutoScrollToBottom] = useState(true)

  let { _id } = queryString.parse(props.location.search);

  const focusScrollBottom = () => {
    setTimeout(() => {
      if (scrollBarRef.current) {
        scrollBarRef.current._ps.element.scrollTop =
          scrollBarRef.current._ps.contentHeight;
      }
    }, 100);
  };

  useEffect(() => {
    if (autoScrollToBottom) {
      focusScrollBottom()
    }
  }, [chats]);


  useEffect(() => {
    if (timerRef.current)
      clearInterval(timerRef.current);

    timerRef.current = setInterval(() => {
      dispatch(getWAPUsersAction({ perPage: 100, page_number: 1 }));
      if (_id) {
        dispatch(getOneUserChatAction({ _id }));
      }
    }, 5000);

    return () => {
      clearInterval(timerRef.current);
    }
  }, [_id]);

  useEffect(() => {
    dispatch(getWAPUsersAction({ perPage: 100, page_number: 1 }));
    if (_id) {
      dispatch(getOneUserChatAction({ _id }));
    }
  }, [_id]);

  return (
    <React.Fragment>
      <Row className="app-row">
        <Colxx xxs="12" className="chat-app chat-side-ba">
          <ChatHeading {...props} />
          <PerfectScrollbar onScrollUp={() => {
            setAutoScrollToBottom(false)
          }}
            onYReachEnd={() => {
              setAutoScrollToBottom(true)
              console.log("End")
            }}
            className="chat-side-bar"
            ref={scrollBarRef}
            options={{ suppressScrollX: true, wheelPropagation: false }}
          >
            {chats.map((chat, index) => {
              return <MessageCard key={index} chat={chat} />;
            })}
            <div className="mb-30px"></div>
          </PerfectScrollbar>
        </Colxx>
      </Row>
      <SaySomething />
      <ChatApplicationMenu {...props} />
    </React.Fragment>
  );
};

export default WhatsappHome;
