import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteStudentEnrollmentDataAction,
  getOneStudentDataAction,
} from "../../../../../../redux/master/actions";
import { defaultData } from "../../../../../../constants/selectOptions";
import AddEditSection from "./assignments/addEdit";
import AllAssignments from "./assignments/allAssign";
import DeleteConfirmationPopUp from "../../../../../../components/common/deleteConfirmationPopup";
import { formateDate } from "../../../../../../helpers/Utils";

const OneStudent = (props) => {
  let [studentImage, setStudentImage] = useState(null);
  let [isAssing, setIsAssign] = useState(false);
  let [isAssingEdit, setIsAssignEdit] = useState(false);
  let [isAssingDelete, setIsAssignDelete] = useState(false);
  let [currentAssign, setCurrentAssign] = useState(false);

  var randomColor = Math.floor(Math.random() * 16777215).toString(16);
  const dispatch = useDispatch();
  const oneStudent = useSelector((state) => state.master.oneStudent);

  useEffect(() => {
    dispatch(getOneStudentDataAction(props.student_id));
  }, []);

  useEffect(() => {
    if (oneStudent) {
      if (oneStudent.image) {
        if (
          oneStudent.image.indexOf("http://") === 0 ||
          oneStudent.image.indexOf("https://") === 0
        ) {
          setStudentImage(oneStudent.image);
        }
      }
    }
  }, [oneStudent]);

  let char = "";
  if (oneStudent?.name) {
    let name = oneStudent.name.split(" ");
    if (name.length > 1) {
      char = name[0].slice(0, 1) + name[1].slice(0, 1);
    } else if (name.length > 0) {
      char = name[0].slice(0, 1);
    } else {
      char = "Tech";
    }
  }

  const onDeleteClick = (_id) => {
    dispatch(deleteStudentEnrollmentDataAction(_id));
    dispatch(getOneStudentDataAction(props.student_id));
    setIsAssignDelete(false);
    setCurrentAssign(null);
  };

  props = {
    ...props,
    isAssing,
    setIsAssign,
    isAssingEdit,
    setIsAssignEdit,
    currentAssign,
    setCurrentAssign,
    isAssingDelete,
    setIsAssignDelete,
    onDeleteClick,
  };
  return oneStudent ? (
    <React.Fragment>
      {isAssingDelete && (
        <DeleteConfirmationPopUp
          {...props}
          isDeleting={isAssingDelete}
          setIsDeleting={setIsAssignDelete}
          currentData={currentAssign}
          setCurrentData={setCurrentAssign}
        />
      )}
      <i
        onClick={() => props.history.push(props.history.location.pathname)}
        className="simple-icon-arrow-left-circle fs-20px c_FFA726 cursor-pointer"
      />
      <Row>
        <Colxx lg="2" xl="2">
          <div className="student-image mb-2">
            {studentImage && (
              <img
                src={studentImage}
                className="w-150px h-150px br-30px shadow-box"
                alt="student_image"
              />
            )}

            {!studentImage && (
              <div
                className="student-null-image"
                style={{ backgroundColor: `#${randomColor}` }}
              >
                {char}
              </div>
            )}
          </div>
        </Colxx>
        <Colxx lg="10" xl="10">
          <Card className="d-flex br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div className="card-body accordion-content pb-1 pt-1">
                <Row>
                  <Colxx lg="3" md="6" sm="6" xs="6" xxs="12">
                    <div className="m-2">
                      <label className="input-label fs-16px">Name</label>
                      <p className="input-label mt-n10px c_black-60p">
                        {oneStudent.name}
                      </p>
                    </div>
                  </Colxx>
                  <Colxx lg="3" md="6" sm="6" xs="6" xxs="12">
                    <div className="m-2">
                      <label className="input-label fs-16px">
                        Email Address
                      </label>
                      <p className="input-label mt-n10px c_black-60p">
                        {oneStudent.email}
                      </p>
                    </div>
                  </Colxx>

                  <Colxx lg="3" md="6" sm="6" xs="6" xxs="6">
                    <div className="m-2">
                      <label className="input-label fs-16px">
                        Mobile Number
                      </label>
                      <p className="input-label mt-n10px c_black-60p">
                        {oneStudent.mobile}
                      </p>
                    </div>
                  </Colxx>

                  <Colxx lg="3" md="6" sm="6" xs="6" xxs="6">
                    <div className="m-2">
                      <label className="input-label fs-16px">
                        WhatsApp Number
                      </label>
                      <p className="input-label mt-n10px c_black-60p">
                        {oneStudent.wapMobile}
                      </p>
                    </div>
                  </Colxx>
                  <Colxx lg="2" md="6" sm="6" xs="6" xxs="6">
                    <div className="m-2">
                      <label className="input-label fs-16px">
                        Passing Year
                      </label>
                      <p className="input-label mt-n10px c_black-60p">
                        {oneStudent.year}
                      </p>
                    </div>
                  </Colxx>

                  <Colxx lg="3" md="6" sm="6" xs="6" xxs="6">
                    <div className="m-2">
                      <label className="input-label fs-16px">
                        Who are you ?
                      </label>
                      <p className="input-label mt-n10px c_black-60p">
                        {oneStudent.category}
                      </p>
                    </div>
                  </Colxx>

                  <Colxx lg="4" md="6" sm="6" xs="6" xxs="6">
                    <div className="m-2">
                      <label className="input-label fs-16px">
                        Institute Name
                      </label>
                      <p className="input-label mt-n10px c_black-60p">
                        {oneStudent.institute_name}
                      </p>
                    </div>
                  </Colxx>

                  {oneStudent.category === defaultData.workingProfessional && (
                    <Colxx lg="3" md="6" sm="6" xs="6" xxs="6">
                      <div className="m-2">
                        <label className="input-label fs-16px">
                          Designation
                        </label>
                        <p className="input-label mt-n10px c_black-60p">
                          {oneStudent.designation}
                        </p>
                      </div>
                    </Colxx>
                  )}

                  <Colxx lg="2" md="6" sm="6" xs="6" xxs="6">
                    <div className="m-2">
                      <label className="input-label fs-16px">Background</label>
                      <p className="input-label mt-n10px c_black-60p">
                        {oneStudent.education_background}
                      </p>
                    </div>
                  </Colxx>

                  <Colxx lg="3" md="6" sm="6" xs="6" xxs="6">
                    <div className="m-2">
                      <label className="input-label fs-16px">
                        Degree Program
                      </label>
                      <p className="input-label mt-n10px c_black-60p">
                        {oneStudent.subject}
                      </p>
                    </div>
                  </Colxx>

                  <Colxx lg="3" md="6" sm="6" xs="6" xxs="6">
                    <div className="m-2">
                      <label className="input-label fs-16px">
                        Registered Date
                      </label>
                      <p className="input-label mt-n10px c_black-60p">
                        {formateDate(oneStudent.date)}
                      </p>
                    </div>
                  </Colxx>


                  <Colxx lg="3" md="6" sm="6" xs="6" xxs="6">
                    <div className="">
                      <label className="input-label fs-16px">
                        Referal Code
                      </label>
                      <p className="input-label mt-n10px c_black-60p">
                        {oneStudent.referal_code}
                      </p>
                    </div>
                  </Colxx>

                  <Colxx lg="2" md="6" sm="6" xs="6" xxs="6">
                    <div className="m-2">
                      <label className="input-label fs-16px">Is Blogger</label>
                      <p className="input-label mt-n10px c_black-60p">
                        {oneStudent.isBlogger ? "Yes" : "No"}
                      </p>
                    </div>
                  </Colxx>
                </Row>
              </div>
            </div>
          </Card>
        </Colxx>
      </Row>
      <div className="d-flex justify-content-spacebetween align-items-center mt-20px">
        <div className="c_00508B fs-20px fw-600 ">List of Assignments</div>
        <div className="d-flex align-items-center">
          <i
            onClick={() => {
              setIsAssign(!isAssing);
            }}
            className="c_00508B cursor-pointer simple-icon-plus fs-36px"
          />
        </div>
      </div>

      {(isAssing || isAssingEdit) && (
        <div className="mt-20px">
          <AddEditSection {...props} oneStudent={oneStudent} />
        </div>
      )}

      <AllAssignments {...props} oneStudent={oneStudent} />
    </React.Fragment>
  ) : (
    <div className="loading"></div>
  );
};

export default OneStudent;
