import React, { useState, useEffect } from "react";
import { Button, Card, Row, Modal, ModalHeader } from "reactstrap";
import filterIcon from "../../../../assets/img/icons/solid/filter.svg";
import downloadIcon from "../../../../assets/img/icons/solid/download.svg";
import {
  Colxx,
  Separator,
} from "../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import Switch from "rc-switch";

import {
  deleteStudentDataAction,
  errorHandler,
  getAllStudentsDataAction,
  getAllUsersDataAction,
} from "../../../../redux/master/actions";
import Select from "react-select";
import DeleteConfirmationPopUp from "../../../../components/common/deleteConfirmationPopup";
import {
  assignStudentToUserAPI,
  getOneStudentAttendanceAPI,
  updateStudentsAPI,
} from "../../../../services/master";

import { formateDate, selectCustomStyles } from "../../../../helpers/Utils";
import StudentFilterSection from "./components/studentsFilter";
import AddEditSection from "./components/addEdit";
import OneStudent from "./components/oneStudent";
import StudentCertificate from "./components/certificate";
import AlertMessage from "../../../../helpers/alerts";
import { useAuthRole } from "../../../../helpers/hooks";
import { getWAPGroupDataAction } from "../../../../redux/whatsapp/actions";
import { enquiriesToContactAPI } from "../../../../services/whatsapp";
import downloadCSV from "../../../../helpers/csvDownload";
import GenerateBulkCertificates from "../batches/bulkCertificates";
const queryString = require("query-string");

const StudentsData = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [wapGroupSelectOptions, setWapGroupSelectOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  let { student_id, action, enrollment_id } = queryString.parse(
    props.location.search
  );
  const currentUser = useSelector((state) => state.authUser.currentUser);
  const [isCertificateGenerate, setIsCertificateGenerate] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [assignedUser, setAssignedUser] = useState(null);
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const [oneStudentAttendance, setOneStudentAttendance] = useState([]);
  let [attendancePercent, setAttendancePercent] = useState(0);

  const [selectedIds, setSelectedIds] = useState([]);
  const [searchObj, setSearchObj] = useState({});
  const [oneStudent, setOneStudent] = useState(null);
  const dispatch = useDispatch();
  const fetchedData = useSelector((state) => state.master.allStudents);
  const { users: allUsers } = useSelector((state) => state.master.allUsers);
  const allGroups = useSelector((state) => state.wap.allGroups);
  const { isAdmin, isStaff } = useAuthRole(currentUser);

  const downloadFilteredData = () => {
    let newData = [];
    fetchedData?.map((oneEle) => {
      let oneObj = {
        name: oneEle.name,
        mobile: oneEle.mobile,
        email: oneEle.email,
        subject: oneEle.subject,
        designation: oneEle?.designation || "",
        institute_name: oneEle.institute_name,
        education_background: oneEle.education_background,
        availableDiscountPercentage: oneEle.availableDiscountPercentage,
        category: oneEle.category,
        joinedDate: new Date(oneEle.createdAt).toLocaleDateString(),
        discountPercentage: oneEle.discountPercentage,
        isCA: oneEle.isCA,
      };
      newData.push(oneObj);
    });
    if (newData?.length > 0) {
      downloadCSV(newData);
    }
  };

  const processStudentIds = (_id, isAll = false) => {
    if (!isAll) {
      if (selectedIds.includes(_id)) {
        let remainIds = selectedIds.filter((x) => x !== _id);
        setSelectedIds(remainIds);
      } else {
        setSelectedIds([...selectedIds, _id]);
      }
    } else {
      if (selectedIds?.length > 0) {
        setSelectedIds([]);
      } else {
        let allIds = [];
        fetchedData?.map((x) => allIds.push(x.id));
        setSelectedIds(allIds);
      }
    }
  };

  const assignUserToStudents = async (isRemove = false) => {
    if (!assignedUser) {
      AlertMessage("Please select user", "error");
      return;
    }

    try {
      await assignStudentToUserAPI({
        userId: assignedUser,
        studentIds: selectedIds,
        isRemove: isRemove,
      });
      AlertMessage("Student Assigned to Selected User");
      setSelectedIds([]);
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const deleteSelectedStudents = () => {
    dispatch(deleteStudentDataAction(selectedIds));
  };

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: (
          <span>
            #{" "}
            <input
              className={isStaff && "d-none"}
              checked={selectedIds?.length === fetchedData?.length}
              type="checkbox"
              onChange={() => processStudentIds("", true)}
            />
          </span>
        ),
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Name",
        field: "name",
        width: 200,
      },
      {
        label: "Attendance",
        field: "attendance",
        width: 100,
        sort: "disabled",
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },

      {
        label: "Email",
        field: "email",
        width: 250,
      },

      {
        label: "Who You Are ?",
        field: "category",
        width: 200,
      },
      {
        label: "Institute Name",
        field: "institute_name",
        width: 280,
      },
      {
        label: "Passing Year",
        field: "year",
        width: 180,
      },

      {
        label: "Referal Code",
        field: "referal_code",
        width: 120,
        sort: "disabled",
      },
      {
        label: "Date",
        field: "date",
        width: 200,
      },

      {
        label: "Action",
        field: "action",
        width: 100,
        sort: "disabled",
      },
    ],
    rows: [],
  });

  const [bloggerRequestdatatable, setBloggerRequestDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Name",
        field: "name",
        width: 200,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },

      {
        label: "Email",
        field: "email",
        width: 250,
      },

      {
        label: "Who You Are ?",
        field: "category",
        width: 200,
      },
      {
        label: "Institute Name",
        field: "institute_name",
        width: 280,
      },

      {
        label: "Take Action",
        field: "take_action",
        width: 120,
        sort: "disabled",
      },
    ],
    rows: [],
  });

  const onViewAttendance = async (studentId) => {
    let attendanceObj = {
      course: searchObj.course_id,
      batch: searchObj.batch_id,
      student_id: studentId,
    };
    let { data } = await getOneStudentAttendanceAPI(attendanceObj);
    data.attendance?.length && setOneStudentAttendance(data.attendance);
  };

  useEffect(() => {
    let allRows = [];
    let bloggerRequests = [];
    fetchedData?.map((data, index) => {
      console.log("Student Data", data);
      let oneRow = {
        sn: (
          <span>
            {index + 1}{" "}
            <input
              onChange={() => processStudentIds(data.id)}
              checked={selectedIds.includes(data.id)}
              type="checkbox"
              className={isStaff && "d-none"}
            />
          </span>
        ),
        name: data.name,
        mobile: data.mobile,
        email: data.email,
        category: data.category,
        institute_name: data.institute_name,
        year: data.year,
        referal_code: data.referal_code,
        isBlogger: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.isBlogger}
            onChange={(status) => onUserStatusChange(data, "isBlogger", status)}
          />
        ),
        isCA: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.isCA}
            onChange={(status) => onUserStatusChange(data, "isCA", status)}
          />
        ),
        date: new Date(data.date).toLocaleString("en-IN", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        }),

        action: (
          <span className="d-flex align-items-center">
            <i
              onClick={() => {
                setIsAdding(false);
                setIsEditing(false);
                setCurrentData(null);
                props.history.push(
                  `${props.history.location.pathname}?student_id=${data.id}&action=view`
                );
              }}
              className="simple-icon-paper-plane cursor-pointer c_00508B fw-900 mr-4px"
            />
            <i
              onClick={() => {
                setCurrentData(data);
                setIsEditing(true);
                props.history.push(
                  `${props.history.location.pathname}?student_id=${data.id}&action=edit`
                );
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            {isAdmin && (
              <i
                onClick={() => {
                  setIsDeleting(true);
                  setCurrentData(data);
                }}
                className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
              />
            )}
          </span>
        ),

        attendance: (
          <span className="d-flex align-items-center">
            <button
              onClick={() => {
                onViewAttendance(data.id);
                setOneStudent(data);
              }}
              className="attendance-view-btn"
            >
              View
            </button>
          </span>
        ),
      };
      allRows.push(oneRow);
      if (data.isBloggerRequest) {
        oneRow.take_action = (
          <Select
            styles={selectCustomStyles}
            options={[
              { label: "Accept", value: true },
              { label: "Reject", value: false },
            ]}
            className="mt-n5px"
            onChange={(status) => {
              onUserStatusChange(data, "isBlogger", status.value);
              setTimeout(() => {
                onUserStatusChange(data, "isBloggerRequest", false);
              }, 1000);
            }}
          />
        );
        bloggerRequests.push(oneRow);
      }
    });

    let allColumns = datatable.columns;
    let oneColumn = allColumns[0];
    oneColumn = {
      ...oneColumn,
      label: (
        <span>
          #{" "}
          <input
            checked={selectedIds?.length === fetchedData?.length}
            type="checkbox"
            className={isStaff && "d-none"}
            onChange={() => processStudentIds("", true)}
          />
        </span>
      ),
    };

    allColumns[0] = oneColumn;

    setDatatable({ ...datatable, rows: allRows, columns: allColumns });
    setBloggerRequestDatatable({
      ...bloggerRequestdatatable,
      rows: bloggerRequests,
    });
  }, [fetchedData, selectedIds]);

  useEffect(() => {
    let allRows = [];
    allUsers?.map((x) => {
      allRows.push({
        label: (
          <span className="">
            <strong>
              {x.first_name} {x.last_name}
            </strong>
            <br /> {x.username}
          </span>
        ),
        value: x.id,
        email: x.username,
        name: x.first_name + " " + x.last_name,
      });
    });
    setUserSelectOptions(allRows);
  }, [allUsers, currentData]);

  useEffect(() => {
    if (isAdmin) {
      setDatatable({
        ...datatable,
        columns: [
          ...datatable.columns,
          {
            label: "Is Blogger",
            field: "isBlogger",
            width: 120,
            sort: "disabled",
          },

          {
            label: "Is CA",
            field: "isCA",
            width: 120,
            sort: "disabled",
          },
        ],
      });
    }
  }, [isAdmin]);

  useEffect(() => {
    let allRows = [];
    allGroups?.map((x) => {
      allRows.push({
        label: x.name,
        value: x.id,
      });
    });
    setWapGroupSelectOptions(allRows);
  }, [allGroups]);

  useEffect(() => {
    if (Object.keys(searchObj)?.length > 0) {
      dispatch(getAllStudentsDataAction(searchObj));
    }

    if (isAdmin)
      dispatch(
        getAllUsersDataAction({ perPage: 1000, page_number: 1, is_staff: true })
      );
    dispatch(getWAPGroupDataAction());
  }, []);

  useEffect(() => {
    if (oneStudentAttendance?.length) {
      let presentCount = 0;
      oneStudentAttendance?.map((attendance) => {
        attendance?.isPresent && presentCount++;
      });
      let percent = Math.round(
        (presentCount * 100) / oneStudentAttendance?.length
      );
      setAttendancePercent(percent);
    } else {
      setAttendancePercent(0);
    }
  }, [oneStudentAttendance]);

  const customFilter = (option, searchText) => {
    if (
      option.data.email.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onStudentsToWAPAccount = async () => {
    try {
      if (!selectedGroup) {
        AlertMessage("Whatsapp Group Required", "error");
        return;
      }

      let values = { enquiryIds: selectedIds, selectedGroup, model: "student" };
      await enquiriesToContactAPI(values);
      setSelectedIds([]);
      AlertMessage("Added Successfully");
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  useEffect(() => {
    if (action === "add-new") {
      setIsAdding(true);
      setCurrentData(null);
    } else if (action === "edit" && student_id) {
      let oneStudent = fetchedData.find((x) => x.id?.toString() === student_id);
      setCurrentData(oneStudent);
      setIsEditing(true);
    } else {
      setIsAdding(false);
      setIsEditing(false);
    }
  }, [action, fetchedData]);

  const onDeleteClick = (_id) => {
    dispatch(deleteStudentDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
    if (Object.keys(searchObj)?.length > 0) {
      dispatch(getAllStudentsDataAction(searchObj));
    }
  };

  const onUserStatusChange = (data, field, status) => {
    updateStudentsAPI({ [field]: status }, { _id: data.id }).then(
      (res) => {
        if (Object.keys(searchObj)?.length > 0) {
          dispatch(getAllStudentsDataAction(searchObj));
        }
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
    isEditing,
    setIsEditing,
    isAdding,
    setIsAdding,
    searchObj,
  };

  return student_id && action === "view" ? (
    <OneStudent student_id={student_id} {...props} />
  ) : student_id && action === "certificate" ? (
    <StudentCertificate {...props} />
  ) : (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      {isCertificateGenerate && (
        <GenerateBulkCertificates
          isOpen={isCertificateGenerate}
          setIsOpen={setIsCertificateGenerate}
          studentIds={selectedIds}
          searchObj={searchObj}
        />
      )}
      <Modal
        toggle={() => setOneStudentAttendance([])}
        isOpen={oneStudentAttendance?.length > 0}
      >
        <div className="p-4">
          <span className="table-header-title fs-24px">
            Hi {oneStudent?.name}{" "}
            <span
              className={` truncate mt-n10px ml-10px fs-20px ${
                attendancePercent > 80
                  ? "bg-primary-variant c_white fw-900"
                  : attendancePercent > 60 && attendancePercent <= 80
                  ? "bg-FFA726  fw-900"
                  : "bg-b000206d c_BF360C"
              } px-3  br-10px`}
            >
              {attendancePercent} %
            </span>
            <p className="fs-14px mt-n10px c_black-60p">
              Your Attendance for this Course.
            </p>
          </span>

          {oneStudentAttendance?.map((attendance) => {
            return (
              <div
                className={` shadow-box p-2 br-4px mb-2 fw-900 display-flex justify-content-spacebetween`}
              >
                <span>{formateDate(attendance.createdAt)}</span>
                <span
                  className={`attendance-view-btn pl-10px pr-10px ${
                    attendance?.isPresent
                      ? "bg-primary-variant"
                      : "bg-rating-error"
                  }`}
                >
                  {attendance?.isPresent ? "Present" : "Absent"}
                </span>
              </div>
            );
          })}
        </div>
      </Modal>
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of Students
              <p className="fs-14px c_black-60p">
                All students, who registered in different batches, and different
                courses.
              </p>
            </span>
            <div>
              <button
                onClick={() => {
                  setIsBulkUpload(!isBulkUpload);
                }}
                className="shadow-box create-new-button bg-primary-variant mr-20px"
              >
                Bulk Upload Students
              </button>

              <button
                onClick={() => {
                  setIsAdding(true);
                  props.history.push(
                    `${props.history.location.pathname}?action=add-new`
                  );
                }}
                className="shadow-box create-new-button"
              >
                Add New Student
              </button>
            </div>
          </div>
          <Separator className={toggleFilter ? `mb-2` : "mb-5"} />
        </Colxx>
      </Row>

      <Row>
        {!isAdding && !isEditing && (
          <Colxx lg="12" xl="12">
            {toggleFilter && (
              <StudentFilterSection
                isBulkUpload={isBulkUpload}
                setIsBulkUpload={setIsBulkUpload}
                setSearchObj={setSearchObj}
              />
            )}
            {selectedIds?.length > 0 && isAdmin && (
              <div className="mr-10px d-flex justify-content-flex-start mb-10px mt-10px">
                <Select
                  placeholder="Select Users"
                  className="w-303px mr-10px"
                  isClearable
                  options={userSelectOptions}
                  onChange={(data) => {
                    setAssignedUser(data?.value || null);
                  }}
                  filterOption={customFilter}
                />
                <button
                  className="shadow-box create-new-button bg-ed7117"
                  onClick={() => assignUserToStudents(false)}
                >
                  Assign to User
                </button>
                <button
                  className="shadow-box create-new-button bg-ed7117 ml-10px"
                  onClick={() => assignUserToStudents(true)}
                >
                  Remove From User
                </button>

                <button
                  className="shadow-box create-new-button bg-black-60p ml-10px"
                  onClick={() => deleteSelectedStudents()}
                >
                  Delete Selected Students
                </button>
              </div>
            )}
            {selectedIds?.length > 0 && (
              <div className="mr-10px d-flex justify-content-flex-start mb-10px mt-10px">
                <Select
                  placeholder="Select Group"
                  className="w-303px mr-10px"
                  isClearable
                  options={wapGroupSelectOptions}
                  onChange={(data) => {
                    setSelectedGroup(data?.value || null);
                  }}
                />
                <button
                  onClick={onStudentsToWAPAccount}
                  className="shadow-box create-new-button bg-ed7117"
                >
                  Add to Whatsapp Group
                </button>

                <button
                  onClick={() => {
                    setIsCertificateGenerate(true);
                  }}
                  className="shadow-box create-new-button ml-10px bg-primary-variant"
                >
                  Generate Bulk Certificates
                </button>
              </div>
            )}

            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="position-absolute w-100percent d-flex justify-content-flex-end mt-20px pr-30px">
                {datatable?.rows?.length > 0 && (
                  <img
                    src={downloadIcon}
                    alt="filter-icon"
                    onClick={() => {
                      downloadFilteredData();
                    }}
                    className="w-20px filter-icon mr-20px"
                  />
                )}

                <img
                  src={filterIcon}
                  alt="filter-icon"
                  onClick={() => {
                    setToggleFilter(!toggleFilter);
                  }}
                  className="w-20px filter-icon"
                />
              </div>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div
                  className="card-body accordion-content pt-0"
                  style={{ maxWidth: "100%" }}
                >
                  <MDBDataTable
                    fixed
                    hover
                    entriesOptions={[25, 50, 100, 500, 1000]}
                    entries={25}
                    pagesAmount={4}
                    data={
                      bloggerRequestdatatable.rows?.length > 0
                        ? bloggerRequestdatatable
                        : datatable
                    }
                    materialSearch
                    // searchTop
                    fullPagination
                    striped
                    theadColor="dark"
                    searching={false}
                    pagingTop
                    scrollX={true}
                    // scrollY
                    maxHeight={1000}
                    searchBottom={false}
                    // barReverse={true}
                  />
                </div>
              </div>
            </Card>
          </Colxx>
        )}

        {(isAdding || isEditing) && <AddEditSection {...props} />}
      </Row>
    </React.Fragment>
  );
};

export default StudentsData;
