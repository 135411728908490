import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import WhatsappHome from "./home";
import WAPContacts from "./contacts";
import WAPGroups from "./groups";
import NewWAPBroadCast from "./newBroadcast";

const WhatsappApp = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/home`} />
      <Route path={`${match.url}/chat`} component={WhatsappHome} />
      <Route path={`${match.url}/contact`} component={WAPContacts} />
      <Route path={`${match.url}/group`} component={WAPGroups} />
      <Route path={`${match.url}/broadcast`} component={NewWAPBroadCast} />
    </Switch>
  );
};

export default WhatsappApp;
