import React, { useEffect, useState } from "react";
import { Button, Card, Row } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Colxx } from "../../../../../../../components/common/CustomBootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  errorHandler,
  getAllBatchDataAction,
  getAllCourseDataAction,
  getOneStudentDataAction,
} from "../../../../../../../redux/master/actions";
import {
  paymentStatusOptions,
  yesNoOptions,
} from "../../../../../../../constants/selectOptions";
import { requiredFieldValidation } from "../../../../../../../helpers/Utils";
import AlertMessage from "../../../../../../../helpers/alerts";
import {
  createNewFeePaymentAPI,
  createNewStudentAPI,
  createNewStudentEnrollAPI,
  updateStudentsAPI,
  updateStudentsEnrollAPI,
} from "../../../../../../../services/master";

const AddEditSection = (props) => {
  const {
    setIsAssign,
    isAssingEdit,
    setIsAssignEdit,
    currentAssign,
    setCurrentAssign,
  } = props;

  const defaultValues = {
    student: props.student_id,
    course: null,
    batch: null,
    is_fee_in_dollar: { label: "No", value: false },
    is_installment_allowed: null,
    status: null,
    fee: 0,
    paid_fee: 0,
    remain_fee: 0,
    referral_code: null,
  };

  const [values, setValues] = useState(defaultValues);
  const [courseSelectOptions, setCourseSelectOptions] = useState([]);
  const [courseBatchSelectOptions, setCourseBatchSelectOptions] = useState([]);

  const dispatch = useDispatch();
  const allBatch = useSelector((state) => state.master.allBatch);
  const allCourses = useSelector((state) => state.master.allCourses);

  const onEditClick = () => {
    let oneCourse = allCourses.find((x) => x.id === currentAssign.course.id);
    let oneBatch = allBatch.find((x) => x.id === currentAssign.batch.id);
    if (oneCourse) {
      let batchOp = allBatch.map((batch) => {
        if (batch.course.id === oneCourse.id) {
          return { label: batch.name, value: batch.id };
        }
      });

      setCourseBatchSelectOptions(batchOp);
    }
    let valuesObj = {
      student: props.student_id,
      course: oneCourse ? { label: oneCourse.name, value: oneCourse.id } : null,
      batch: oneBatch ? { label: oneBatch.name, value: oneBatch.id } : null,
      is_fee_in_dollar: currentAssign.is_fee_in_dollar
        ? { label: "Yes", value: true }
        : { label: "No", value: false },
      is_installment_allowed: {
        label: currentAssign.is_installment_allowed ? "Yes" : "No",
        value: currentAssign.is_installment_allowed,
      },
      status: paymentStatusOptions.find(
        (x) => x.value === currentAssign.status
      ),
      fee: currentAssign.fee,
      paid_fee: currentAssign.paid_fee,
      remain_fee: currentAssign.remain_fee,
    };

    setValues(valuesObj);
    setIsAssignEdit(true);
  };

  const onSubmitHandler = () => {
    let [allFieldExist, fieldName] = requiredFieldValidation(values, [
      "paid_fee",
      "remain_fee",
      "referral_code",
    ]);

    if (!allFieldExist) {
      AlertMessage("Field - " + fieldName, "error", "This field required.");
      return;
    }

    let valuesObj = {
      ...values,
      course: values?.course?.value,
      batch: values?.batch?.value,
      is_fee_in_dollar: values.is_fee_in_dollar.value,
      is_installment_allowed: values.is_installment_allowed.value,
      status: values.status.value,
    };

    var finalMethod = createNewStudentEnrollAPI;
    if (isAssingEdit) {
      finalMethod = updateStudentsEnrollAPI;
    }

    finalMethod(valuesObj, { _id: isAssingEdit ? currentAssign.id : 0 }).then(
      (res) => {
        dispatch(getOneStudentDataAction(props.student_id));
        setIsAssign(false);
        setIsAssignEdit(false);
        setCurrentAssign(null);
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  useEffect(() => {
    if (currentAssign && isAssingEdit) {
      onEditClick();
    }
  }, [currentAssign, isAssingEdit, allCourses, allBatch]);

  useEffect(() => {
    dispatch(getAllCourseDataAction());
    dispatch(getAllBatchDataAction());
  }, []);

  useEffect(() => {
    let allRows = [];
    allCourses?.map((x) => allRows.push({ label: x.name, value: x.id }));
    setCourseSelectOptions(allRows);
  }, [allCourses]);

  useEffect(() => {
    console.log(values);
  }, [values]);

  useEffect(() => {
    if (values.fee < values.paid_fee) {
      AlertMessage("Paid fee should not be greater then main fee. ");
      setValues({ ...values, paid_fee: 0, remain_fee: values.fee });
      return;
    }
  }, [values]);

  return (
    <React.Fragment>
      <Colxx lg="12" xl="12">
        <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
          <div className="d-flex flex-grow-1 min-width-zero">
            <div className="card-body accordion-content pt-0">
              <Row className="mt-30px">
                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Select Course
                  </label>
                  <Select
                    isClearable
                    value={values.course}
                    onChange={(data) => {
                      setValues({
                        ...values,
                        course: data,
                        batch: null,
                        fee: 0,
                      });
                      let allOpt = [];
                      allBatch.map((x) => {
                        if (x.course.id === data?.value && !x.is_ended) {
                          allOpt.push({
                            label: x.name,
                            value: x.id,
                            is_referal_active: x.is_referal_active,
                          });
                        }
                      });
                      setCourseBatchSelectOptions(allOpt);
                    }}
                    options={courseSelectOptions}
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Select Batch
                  </label>
                  <Select
                    isClearable
                    value={values.batch}
                    onChange={(data) => {
                      let oneBatch = allBatch.find((x) => x.id === data?.value);
                      setValues({
                        ...values,
                        batch: data,
                        fee: oneBatch?.fee ? oneBatch?.fee : 0,
                        remain_fee: oneBatch?.fee ? oneBatch?.fee : 0,
                      });
                    }}
                    options={courseBatchSelectOptions}
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Is Installment Allowed
                  </label>
                  <Select
                    isClearable
                    value={values.is_installment_allowed}
                    onChange={(data) => {
                      setValues({ ...values, is_installment_allowed: data });
                    }}
                    options={yesNoOptions}
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Payment Status
                  </label>
                  <Select
                    isClearable
                    value={values.status}
                    onChange={(data) => setValues({ ...values, status: data })}
                    options={paymentStatusOptions}
                  />
                </Colxx>
                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Is Fee in $
                  </label>
                  <Select
                    value={values.is_fee_in_dollar}
                    onChange={(data) => {
                      let oneBatch = allBatch.find(
                        (x) => x.id === values.batch.value
                      );
                      var fee = 0;
                      if (oneBatch) {
                        if (data.value) {
                          fee = oneBatch.fee_in_doller;
                        } else {
                          fee = oneBatch.fee;
                        }
                      }
                      setValues({
                        ...values,
                        is_fee_in_dollar: data,
                        fee: fee,
                        remain_fee: fee,
                      });
                    }}
                    options={yesNoOptions}
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Student Fee <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <input
                    type={"number"}
                    className="form-control br-10px"
                    placeholder="ex. 5000"
                    value={values.fee}
                    onChange={(data) =>
                      setValues({
                        ...values,
                        fee: data.target.value,
                        remain_fee: data.target.value - values.paid_fee,
                      })
                    }
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Paid Fee <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <input
                    type={"number"}
                    disabled
                    className="form-control br-10px"
                    placeholder="ex. 4000"
                    value={values.paid_fee}
                    onChange={(data) =>
                      setValues({
                        ...values,
                        paid_fee: data.target.value,
                        remain_fee: values.fee - data.target.value,
                      })
                    }
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Remain Fee <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <input
                    type={"number"}
                    disabled
                    className="form-control br-10px"
                    placeholder="ex.1000"
                    value={values.remain_fee}
                    onChange={(data) =>
                      setValues({
                        ...values,
                        remain_fee: data.target.value,
                      })
                    }
                  />
                </Colxx>

                {values?.batch?.is_referal_active && (
                  <Colxx lg="3" xl="3" className="mt-10px">
                    <label className="input-label fw-400 fs-14px">
                      Student Referal Code{" "}
                      <span className="c_B00020 ml-2px">*</span>
                    </label>
                    <input
                      className="form-control br-10px"
                      placeholder="ex. 08a79f81"
                      value={values.referral_code}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          referral_code: data.target.value,
                        })
                      }
                    />
                  </Colxx>
                )}

                <div className="d-flex justify-content-flex-end w-100percent">
                  <Button
                    onClick={() => {
                      setIsAssign(false);
                      setIsAssignEdit(false);
                      setValues(defaultValues);
                      setCurrentAssign(null);
                    }}
                    size={"sm"}
                    color="dark"
                    className="br-20px c_white shadow-box create-new-button mt-30px"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={onSubmitHandler}
                    size={"sm"}
                    color="secondry"
                    className="br-20px c_white shadow-box create-new-button mt-30px"
                  >
                    {isAssingEdit ? "Update" : "Create"}
                  </Button>
                </div>
              </Row>
            </div>
          </div>
        </Card>
      </Colxx>
    </React.Fragment>
  );
};

export default AddEditSection;
