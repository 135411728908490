import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";

import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import {
  createNewUTMIdAPI,
  deleteUTMIdAPI,
  getAllUTMIdsAPI,
  updateUTMIdAPI,
} from "../../../../../services/master";

const UTMListDetails = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const defaaultValues = {
    name: "",
    utm_campaign: "",
    utm_source: "",
  };
  const [values, setValues] = useState(defaaultValues);

  const onDeleteClick = async (_id) => {
    await deleteUTMIdAPI(_id);
    setCurrentData(null);
    setIsDeleting(false);
    fetchUTMData();
  };

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Name",
        field: "name",
        width: 200,
      },

      {
        label: "UTM Campaign",
        field: "utm_campaign",
        width: 200,
      },
      {
        label: "UTM Source",
        field: "utm_source",
        width: 200,
      },

      {
        label: "Enquiry Count",
        field: "count",
        width: 200,
      },
      {
        label: "_Id",
        field: "_id",
        width: 250,
      },

      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  const onSubmitHandler = async () => {
    if (
      values.name === "" ||
      values.utm_campaign === "" ||
      values.utm_source === ""
    ) {
      AlertMessage("All Values Required", "info");
      return;
    }

    try {
      if (isEditing) {
        await updateUTMIdAPI(values, { _id: currentData.id });
        AlertMessage("UTM Updated Successfully");
        setCurrentData(null);
        setIsEditing(false);
      } else {
        await createNewUTMIdAPI(values);
        AlertMessage("UTM Created Successfully");
      }

      fetchUTMData();
      setValues(defaaultValues);
    } catch (error) {
      AlertMessage("Some Internal Error ", "error");
    }
  };

  const fetchUTMData = () => {
    getAllUTMIdsAPI()?.then(
      (res) => {
        const data = res.data.data || [];
        let allRows = [];
        data.map((data, index) => {
          let oneRow = {
            sn: index + 1,
            name: data.name,
            utm_source: data.utm_source,
            utm_campaign: data.utm_campaign,
            count: data.enquires?.length,
            _id: data.id,
            action: (
              <span>
                <i
                  onClick={() => {
                    setIsEditing(true);
                    setCurrentData(data);
                    setValues({
                      ...values,
                      name: data.name,
                      utm_campaign: data.utm_campaign,
                      utm_source: data.utm_source,
                    });
                  }}
                  className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
                />
                <i
                  onClick={() => {
                    setIsDeleting(true);
                    setCurrentData(data);
                  }}
                  className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
                />
              </span>
            ),
          };
          allRows.push(oneRow);
        });

        setDatatable({ ...datatable, rows: allRows });
      },
      (error) => {}
    );
  };

  useEffect(() => {
    fetchUTMData();
  }, []);

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of UTM Id's
              <p className="fs-14px c_black-60p">
                These UTM Id's used for Tracking the Leads
              </p>
            </span>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx lg="12" xl="7">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div
                className="card-body accordion-content pt-0"
                style={{ maxWidth: "100%" }}
              >
                <MDBDataTable
                  fixed
                  hover
                  entriesOptions={[25, 50, 100, 500, 1000]}
                  entries={25}
                  pagesAmount={4}
                  data={datatable}
                  materialSearch
                  // searchTop
                  fullPagination
                  striped
                  theadColor="dark"
                  searching={false}
                  pagingTop
                  scrollX={true}
                  // scrollY
                  maxHeight={1000}
                  searchBottom={false}
                  // barReverse={true}
                />
              </div>
            </div>
          </Card>
        </Colxx>
        <Colxx lg="12" xl="5">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div className="card-body accordion-content pt-0">
                <Row className="mt-30px">
                  <Colxx lg="12" xl="12" className="mt-10px">
                    <label className="input-label fw-400 fs-14px">
                      UTM Name
                    </label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. New Markketting Lead"
                      value={values.name}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          name: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <Colxx lg="12" xl="12" className="mt-10px">
                    <label className="input-label fw-400 fs-14px">
                      UTM Campaign
                    </label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. internship-program"
                      value={values.utm_campaign}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          utm_campaign: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <Colxx lg="12" xl="12" className="mt-10px">
                    <label className="input-label fw-400 fs-14px">
                      UTM Source
                    </label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. facebook-add"
                      value={values.utm_source}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          utm_source: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <div className="d-flex justify-content-flex-end w-100percent">
                    <Button
                      onClick={onSubmitHandler}
                      size={"sm"}
                      color="secondry"
                      className="br-20px c_white shadow-box create-new-button mt-30px"
                    >
                      {isEditing ? "Update" : "Create"}
                    </Button>
                  </div>
                </Row>
              </div>
            </div>
          </Card>
        </Colxx>
      </Row>
    </React.Fragment>
  );
};

export default UTMListDetails;
