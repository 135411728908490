/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { Nav, TabContent, TabPane, CardHeader, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import ApplicationMenu from "./ApplicationMenu";
import { useDispatch, useSelector } from "react-redux";
import { deleteWAPUserAction, getWAPUsersAction } from "../../../../../redux/whatsapp/actions";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";

const ChatApplicationMenu = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [currentData, setCurrentData] = useState(null);
  const [idsForDeleteChat, setIdsForDelete] = useState([])
  let { users, count } = useSelector((state) => state.wap.allUsers);
  const dispatch = useDispatch();

  const handleSearchContact = (keyword) => {
    setSearchKey(keyword);
    if (keyword?.length > 2) dispatch(getWAPUsersAction({ search: keyword, perPage: 100, page_number: 1 }))
    if (!keyword?.length) dispatch(getWAPUsersAction({ perPage: 100, page_number: 1 }))
  };

  const onDeleteClick = (_id) => {
    dispatch(deleteWAPUserAction({ _id }));
    idsForDeleteChat.map(_id => dispatch(deleteWAPUserAction({ _id })))
    setCurrentData(null);
    setIsDeleting(false);
    setTimeout(() => dispatch(getWAPUsersAction({ perPage: 100, page_number: 1 })), 200)
  };


  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <ApplicationMenu>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}


      <div className="shadow-box br-20px mt-20px ml-10px mr-10px">
        <div className="form-group">
          <input
            type="text"
            className="form-control br-10px h-35px"
            placeholder="Search User"
            value={searchKey}
            onChange={(e) => handleSearchContact(e.target.value)}
          />
        </div>
      </div>

      <PerfectScrollbar
        options={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <div className="pt-2 pr-4 pl-4 pb-2 mb-100px">
          {users?.map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex justify-content-spacebetween align-items-center mb-1 border-bottom pb-1 mb-1"
              >
                <div className="d-flex justify-content-flex-start align-items-flex-start w-100percent">
                  <div className="">
                    <NavLink
                      to={`${props.location.pathname}?_id=${item.id}`}
                    >
                      <p className="mb-0 c_black-100p fw-600">{item.name}</p>
                      <p className="mb-1 text-muted text-small">
                        {item.mobile}
                      </p>
                    </NavLink>
                  </div>
                </div>
                {item.unread_messages > 0 && <div className="unread-msg-in-chat">
                  2
                </div>}
              </div>
            );
          })}
        </div>
      </PerfectScrollbar>
    </ApplicationMenu>
  );
};

export default ChatApplicationMenu;
