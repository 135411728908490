import axios from "axios";
import { trackPromise } from "react-promise-tracker";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const ProcessParams = (Obj) => {
  let paramString = "";
  if (Object.keys(Obj).length > 0) {
    paramString = "?";
  }

  Object.keys(Obj).forEach((key, index) => {
    if (index !== 0) {
      paramString = paramString + "&";
    }
    paramString += `${key}=${Obj[key]}`;
  });

  return paramString;
};

export const getDashboardDataAPI = async () => {
  try {
    return trackPromise(
      axios.get("/master/get-dashboard-data/", {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Certificates API ---------- //

export const generateBulkCertificatesAPI = async (data) => {
  try {
    return trackPromise(
      axios.post("/master/generate-certificates/", data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const getOneCertificatesAPI = async (Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.get(`/master/generate-certificates/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteOneCertificatesAPI = async (Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.delete(`/master/generate-certificates/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Attendance API ---------- //

export const createAttendanceQRAPI = async (data) => {
  try {
    return trackPromise(
      axios.post("/master/attendance/", data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const getAttendanceAPI = async (Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.get(`/master/attendance/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};
export const getOneStudentAttendanceAPI = async (Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.get(`/master/one-student-attendance/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateAttendanceAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/master/attendance/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const addAttendanceStudentAPI = async (Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.post(`/master/attendance-add-student/${paramString}`, {}, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateStudentAttendanceAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.patch(`/master/attendance/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteAttendanceAPI = async (Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.delete(`/master/attendance/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ----------Student  BULK Upload API -------- //
export const bulkUploadStudentsAPI = async (data) => {
  try {
    return trackPromise(
      axios.post("/student/bulk_upload_students/", data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Companies API ---------- //

export const getAllCompaniesListAPI = async () => {
  try {
    return trackPromise(
      axios.get("/website/companies/", {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewCompanyAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/companies/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateCompanyAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/companies/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteCompanyAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/website/companies/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Course Categories ------- //

export const getAllCourseCategoriesListAPI = async () => {
  try {
    return trackPromise(
      axios.get("/website/course-categories/", {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewCourseCategoriesAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/course-categories/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateCourseCategoriesAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/course-categories/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteCourseCategoriesAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/website/course-categories/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Course Categories ------- //

export const getAllUTMIdsAPI = async () => {
  try {
    return trackPromise(
      axios.get("/website/utm/", {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewUTMIdAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/utm/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateUTMIdAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/utm/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteUTMIdAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/website/utm/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of FaceBook Lead Form ------- //

export const getAllFBLeadFormAPI = async () => {
  try {
    return trackPromise(
      axios.get("/fb/lead-forms/", {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewFBLeadFormAPI = async (data, obj = {}) => {
  try {
    return trackPromise(
      axios.post(`/fb/lead-forms/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateFBLeadFormAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/fb/lead-forms/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteFBLeadFormAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/fb/lead-forms/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of FaceBook Leads ------- //

export const getAllFBLeadsAPI = async (obj = {}) => {
  try {
    let paramString = ProcessParams(obj);
    return trackPromise(
      axios.get(`/fb/leads/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewFBLeadsAPI = async (data, obj = {}) => {
  try {
    return trackPromise(
      axios.post(`/fb/leads/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateFBLeadsAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/fb/leads/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteFBLeadsAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/fb/leads/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Technologies API ---------- //

export const getAllTechnologiesListAPI = async () => {
  try {
    return trackPromise(
      axios.get("/website/technologies/", {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewTechnologiesAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/technologies/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateTechnologiesAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/technologies/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteTechnologiesAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/website/technologies/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of ToDos API ---------- //

export const getAllToDoListAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/website/todo/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewToDoAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/todo/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateToDoAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/todo/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteToDoAPI = async (todoId) => {
  try {
    return trackPromise(
      axios.delete(`/website/todo/?_id=${todoId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Job Tags API'S ---------- //

export const getAllJobTagsListAPI = async () => {
  try {
    return trackPromise(
      axios.get("/website/job-tags/", {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewJobTagsAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/job-tags/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateJobTagsAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/job-tags/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteJobTagsAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/website/job-tags/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Technologies API ---------- //

export const getAllFaQsListAPI = async () => {
  try {
    return trackPromise(
      axios.get("/website/faqs/", {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewFaQsAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/faqs/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateFaQsAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/faqs/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteFaQsAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/website/faqs/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Templetes API ---------- //

export const getAllTempletesListAPI = async () => {
  try {
    return trackPromise(
      axios.get("/website/certificates-templete/", {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewTempletesAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/certificates-templete/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateTempletesAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/certificates-templete/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteTempletesAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/website/certificates-templete/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Files API ---------- //

export const getAllFilesListAPI = async (obj = {}) => {
  try {
    let paramString = ProcessParams(obj);
    return trackPromise(
      axios.get(`/master/file-upload/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const fileUploadRequestAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/master/file-upload-request`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const uploadFileAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/master/file-upload/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteFileAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/master/file-upload/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Team Members API ---------- //

export const getAllTeamMemberListAPI = async (Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.get(`/website/teams/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createTeamMemberAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/teams/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateTeamMemberAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/teams/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteTeamMemberAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/website/teams/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List ofBlog Topics API ---------- //

export const getAllBlogTopicListAPI = async (Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.get(`/blog/topic/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createBlogTopicAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/blog/topic/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateBlogTopicAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/blog/topic/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteBlogTopicAPI = async (topicId) => {
  try {
    return trackPromise(
      axios.delete(`/blog/topic/?_id=${topicId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of WAP Templates API ---------- //

export const getAllWAPTemplatesAPI = async (Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.get(`/master/wap-templates/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createWAPTemplatesAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/master/wap-templates/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateWAPTemplatesAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/master/wap-templates/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteWAPTemplatesAPI = async (topicId) => {
  try {
    return trackPromise(
      axios.delete(`/master/wap-templates/?_id=${topicId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ----------- Enquiry API's ---------- //
export const getAllEnquiresAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/website/enquiries/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const addNewEnquireAPI = async (data, params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.post(`/website/enquiries/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateEnquireAPI = async (data, params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.put(`/website/enquiries/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteEnquiresAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.delete(`/website/enquiries/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const uploadEnquireAPI = async (data, params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.post(`/website/upload-enquiries/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ----------- Courses API's ---------- //
export const getAllCoursesAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/website/courses/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const addNewCourseAPI = async (data, params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.post(`/website/courses/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateCourseDetailsAPI = async (data, params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.put(`/website/courses/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteCourseAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.delete(`/website/courses/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ----------- Courses Criteria API's ---------- //
export const getAllCourseCriteriasAPI = async (data, params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/website/course-criteria/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const addNewCourseCriteriaAPI = async (data, params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.post(`/website/course-criteria/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateCourseCriteriaAPI = async (data, params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.put(`/website/course-criteria/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteCourseCriteriaAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.delete(`/website/course-criteria/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ----------- Courses Content API's ---------- //
export const getAllCourseContentAPI = async (data, params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/website/course-content/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const addNewCourseContentAPI = async (data, params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.post(`/website/course-content/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateCourseContentAPI = async (data, params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.put(`/website/course-content/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteCourseContentAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.delete(`/website/course-content/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Course Key Points API ---------- //

export const getAllKeyPointListAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/website/courses-keypoints/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewKeyPointAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/courses-keypoints/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateKeyPointAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/courses-keypoints/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteKeyPointAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/website/courses-keypoints/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Course urls Points API ---------- //

export const getCourseURLsAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/website/courses-urls/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewCourseUrlAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/courses-urls/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateCourseUrlAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/courses-urls/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteCourseUrlAPI = async (companyId) => {
  try {
    return trackPromise(
      axios.delete(`/website/courses-urls/?_id=${companyId}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Course Key Points API ---------- //

export const getAllBatchesAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/website/courses-batch/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewBatcheAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/courses-batch/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateBatcheAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/website/courses-batch/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteBatcheAPI = async (_id) => {
  try {
    return trackPromise(
      axios.delete(`/website/courses-batch/?_id=${_id}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// Get WebSite Traffic
export const getWebSiteTrafficAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/master/website-traffic/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Users API ---------- //

export const getAllUsersAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/master/users/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewUserAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/master/users/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateUserAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/master/users/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteUserAPI = async (_id) => {
  try {
    return trackPromise(
      axios.delete(`/master/users/?_id=${_id}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Users API ---------- //

export const getWebSiteSettingAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/master/website-settings/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createWebSiteSettingAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/master/website-settings/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateWebSiteSettingAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/master/website-settings/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Institutes API ---------- //

export const getAllInstitutesAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/institute/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewInstituteAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/institute/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateInstituteAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/institute/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteInstitutesAPI = async (_id) => {
  try {
    return trackPromise(
      axios.delete(`/institute/?_id=${_id}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ ------ --- Blogs API'S --- ---- ---- ----- --- //
export const getBlogsAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/blog/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateBlogsAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/blog/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteBlogAPI = async (_id) => {
  try {
    return trackPromise(
      axios.delete(`/blog/?_id=${_id}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Students API ---------- //

export const getAllStudentsAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/student/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewStudentAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/student/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const assignStudentToUserAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/student/student_assign_to_user`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateStudentsAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/student/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteStudentsAPI = async (_id) => {
  try {
    return trackPromise(
      axios.delete(`/student/?_id=${_id}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// ------ List of Students Enrollments API ---------- //

export const getAllStudentsEnrollAPI = async (params = {}) => {
  try {
    let paramString = ProcessParams(params);
    return trackPromise(
      axios.get(`/student/enrollment/${paramString}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const createNewStudentEnrollAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/student/enrollment/`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const updateStudentsEnrollAPI = async (data, Obj = {}) => {
  try {
    let paramString = ProcessParams(Obj);
    return trackPromise(
      axios.put(`/student/enrollment/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteStudentsEnrollAPI = async (_id) => {
  try {
    return trackPromise(
      axios.delete(`/student/enrollment/?_id=${_id}`, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// Student Fee Payment ------ //
export const createNewFeePaymentAPI = async (data, obj = {}) => {
  try {
    let paramString = ProcessParams(obj);
    return trackPromise(
      axios.post(`/student/student_fee_pay/${paramString}`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const assignEnquiryToUserAPI = async (data) => {
  try {
    return trackPromise(
      axios.post(`/website/enquiry_assign_to_user`, data, {
        headers: {
          Authorization: "jwt " + localStorage.getItem("authToken"),
        },
      })
    );
  } catch (e) {
    throw new Error(e);
  }
};
