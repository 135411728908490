import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import Switch from "rc-switch";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteFBLeadFormDataAction,
  errorHandler,
  getFBLeadFormDataAction,
} from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import { createNewFBLeadFormAPI, updateFBLeadFormAPI } from "../../../../../services/master";

const FBLeadForms = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [values, setValues] = useState({
    name: "",
    formId: "",
    is_active: true
  });

  const dispatch = useDispatch();
  const courseCategories = useSelector(
    (state) => state.master.fbForms
  );

  const onDeleteClick = (_id) => {
    dispatch(deleteFBLeadFormDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Name",
        field: "name",
        width: 200,
      },

      {
        label: "Form Id",
        field: "formId",
        width: 200,
      },
      {
        label: "Is Active",
        field: "is_active",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  const onSubmitHandler = async () => {
    if (values.name === "" || values.formId === "") {
      AlertMessage("All Field Required", "info");
      return;
    }

    let finalMethod = createNewFBLeadFormAPI
    let obj = {}
    if (isEditing) {
      obj._id = currentData.id
      finalMethod = updateFBLeadFormAPI
    }

    try {
      await finalMethod(values, obj)
      dispatch(getFBLeadFormDataAction());
    } catch (error) {
      dispatch(errorHandler(error))
    }

    setValues({
      name: "",
      formId: "",
    });
  };

  const onEnquiryStatusChange = async (status, _id) => {
    await updateFBLeadFormAPI({ is_active: status }, { _id })
    dispatch(getFBLeadFormDataAction());
  };

  useEffect(() => {
    let allRows = [];
    courseCategories.map((data, index) => {
      let oneRow = {
        sn: index + 1,
        name: data.name,
        formId: data.formId,
        is_active: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.is_active}
            onChange={(status) => onEnquiryStatusChange(status, data.id)}
          />
        ),
        action: (
          <span>
            <i
              onClick={() => {
                setIsEditing(true);
                setCurrentData(data);
                setValues({
                  ...values,
                  name: data.name,
                  formId: data.formId ? data.formId : "",
                  is_active: data.is_active
                });
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [courseCategories]);

  useEffect(() => {
    dispatch(getFBLeadFormDataAction());
  }, []);

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of Facebook Lead Forms
              <p className="fs-14px c_black-60p">
                This is the data of fb lead form data.
              </p>
            </span>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx lg="12" xl="8">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div
                className="card-body accordion-content pt-0"
                style={{ maxWidth: "100%" }}
              >
                <MDBDataTable
                  fixed
                  hover
                  entriesOptions={[25, 50, 100, 500, 1000]}
                  entries={25}
                  pagesAmount={4}
                  data={datatable}
                  materialSearch
                  // searchTop
                  fullPagination
                  striped
                  theadColor="dark"
                  searching={false}
                  pagingTop
                  scrollX={true}
                  // scrollY
                  maxHeight={1000}
                  searchBottom={false}
                // barReverse={true}
                />
              </div>
            </div>
          </Card>
        </Colxx>
        <Colxx lg="12" xl="4">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div className="card-body accordion-content pt-0">
                <Row className="mt-30px">
                  <Colxx lg="12" xl="12">
                    <label className="input-label fw-400 fs-14px">
                      Form Name
                    </label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. TechSimPlus Lead Form"
                      value={values.name}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          name: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <Colxx lg="12" xl="12">
                    <label className="input-label fw-400 fs-14px">
                      Form Id
                    </label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. 53546446576868645"
                      value={values.formId}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          formId: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  <div className="d-flex justify-content-flex-end w-100percent">
                    <Button
                      onClick={onSubmitHandler}
                      size={"sm"}
                      color="secondry"
                      className="br-20px c_white shadow-box create-new-button mt-30px"
                    >
                      {isEditing ? "Update" : "Create"}
                    </Button>
                  </div>
                </Row>
              </div>
            </div>
          </Card>
        </Colxx>
      </Row>
    </React.Fragment>
  );
};

export default FBLeadForms;
