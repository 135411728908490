import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteCompaniesDataAction,
  errorHandler,
} from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import ViewImageByUrl from "../../../../../components/common/viewImageByUrl";
import { deleteWAPGroupDataAction, getWAPGroupDataAction, getWAPUsersAction } from "../../../../../redux/whatsapp/actions";
import { createWAPGroupAPI, updateWAPGroupAPI } from "../../../../../services/whatsapp";
import { processSearchParamsObj } from "../../../../../helpers/Utils";

const WAPGroups = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [wapContactSelectOptions, setWapContactSelectOptions] = useState([])
  const [searchParams, setSearchParams] = useState({});
  const [values, setValues] = useState({
    name: "",
    filePath: "",
    userIds: [],
    removeOrAdd: "add"
  });

  const dispatch = useDispatch();
  const fetchedData = useSelector((state) => state.wap.allGroups);
  const { users: allUsers } = useSelector(
    (state) => state.wap.allUsers
  );

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },
      {
        label: "Group name",
        field: "name",
        width: 200,
      },
      {
        label: "Total Users",
        field: "count",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  const onSubmitHandler = async () => {
    if (
      values.name === null ||
      values.name === ""
    ) {
      AlertMessage(
        "All Fields Required.",
        "error",
        "To create new group you have to define group name"
      );
      return;
    }

    let newValues = { name: values.name }
    if (values.filePath) {
      newValues.filePath = values.filePath
    }

    if (values.userIds && values.userIds?.length > 0) {
      let allUserIds = []
      values.userIds?.map(x => allUserIds.push(x.value))

      newValues.userIds = allUserIds
    }

    let queryObj = {}
    let finalMethod = createWAPGroupAPI
    if (isEditing) {
      finalMethod = updateWAPGroupAPI
      queryObj = { _id: currentData.id }
    }

    try {
      await finalMethod(newValues, queryObj)
      dispatch(getWAPGroupDataAction())
      setValues({ name: "" })
      setIsEditing(false)
      setCurrentData(null)
    } catch (error) {
      dispatch(errorHandler(error))
    }

  };

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((company, index) => {
      let oneRow = {
        sn: index + 1,
        name: <span
          onClick={() => {
            props.history?.push(`/dashboard/whatsapp/contact?groupId=${company.id}`)
          }}
          className="cursor-pointer">{company.name}</span>,
        count: company.users?.length,
        action: (
          <span>
            <i
              onClick={() => {
                setIsEditing(true);
                setCurrentData(company);
                setValues({
                  ...values,
                  name: company.name,
                });
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(company);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [fetchedData]);

  useEffect(() => {
    dispatch(getWAPGroupDataAction());
  }, []);


  useEffect(() => {
    let allContacts = []
    allUsers?.map((item) => allContacts.push({
      label: item.name,
      value: item.id
    }))
    setWapContactSelectOptions(allContacts)
  }, [allUsers])


  const fetchUsersOnParamsChange = () => {
    let obj = processSearchParamsObj(searchParams)
    if (obj?.search?.length > 2) {
      dispatch(getWAPUsersAction(obj));
    }
  }

  useEffect(() => {
    fetchUsersOnParamsChange()
  }, [searchParams]);

  const onDeleteClick = (_id) => {
    dispatch(deleteWAPGroupDataAction({ _id }));
    setCurrentData(null);
    setIsDeleting(false);
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of WhatsApp Groups
              <p className="fs-14px c_black-60p">
                This is the data of WAP Groups.
              </p>
            </span>
            {/* <div>
              <button className="shadow-box create-new-button">
                Add New Company
              </button>
            </div> */}
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        <Colxx lg="12" xl="7">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div
                className="card-body accordion-content pt-0"
                style={{ maxWidth: "100%" }}
              >
                <MDBDataTable
                  fixed
                  hover
                  entriesOptions={[25, 50, 100, 500, 1000]}
                  entries={25}
                  pagesAmount={4}
                  data={datatable}
                  materialSearch
                  // searchTop
                  fullPagination
                  striped
                  theadColor="dark"
                  searching={false}
                  pagingTop
                  scrollX={true}
                  // scrollY
                  maxHeight={1000}
                  searchBottom={false}
                // barReverse={true}
                />
              </div>
            </div>
          </Card>
        </Colxx>
        <Colxx lg="12" xl="5">
          <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div className="card-body accordion-content pt-0">
                <Row className="mt-30px">

                  <Colxx lg="12" xl="12">
                    <label className="input-label fw-400 fs-14px">
                      Group Name
                    </label>
                    <input
                      type={"text"}
                      className="form-control br-10px"
                      placeholder="ex. MERN Batch"
                      value={values.name}
                      onChange={(data) =>
                        setValues({
                          ...values,
                          name: data.target.value,
                        })
                      }
                    />
                  </Colxx>

                  {isEditing &&
                    <div className="w-100percent">
                      <Colxx lg="12" xl="12" className="mt-10px">
                        <label className="input-label fw-400 fs-14px">
                          Upload Data From CSV File
                        </label>
                        <input
                          type={"text"}
                          className="form-control br-10px"
                          placeholder="/uploads/..."
                          value={values.filePath}
                          onChange={(data) =>
                            setValues({
                              ...values,
                              filePath: data.target.value,
                            })
                          }
                        />
                      </Colxx>

                      <Colxx lg="12" xl="12" className="mt-20px">
                        <label className="input-label fw-400 fs-14px">
                          Select Contact
                        </label>
                        <Select isMulti
                          options={wapContactSelectOptions}
                          isClearable
                          value={values.contact}
                          onChange={(data) => {
                            setValues({ ...values, userIds: data })
                          }}
                          onInputChange={(data) => {
                            if (data?.length < 3) setWapContactSelectOptions([])
                            setSearchParams({ ...searchParams, search: { value: data } })
                          }}
                        />
                      </Colxx>
                    </div>
                  }

                  <div className="d-flex justify-content-flex-end w-100percent">
                    <Button
                      onClick={onSubmitHandler}
                      size={"sm"}
                      color="secondry"
                      className="br-20px c_white shadow-box create-new-button mt-30px"
                    >
                      Create
                    </Button>
                  </div>
                </Row>
              </div>
            </div>
          </Card>
        </Colxx>
      </Row>
    </React.Fragment>
  );
};

export default WAPGroups;
