import React, { useEffect, useState } from "react";
import { getOneCertificatesAPI } from "../../../../../services/master";
import parse from "html-react-parser";
import AlertMessage from "../../../../../helpers/alerts";
import { certificateViewUrl } from "../../../../../constants/defaultValues";
const queryString = require("query-string");

const StudentCertificate = (props) => {
  let { certificate_number } = queryString.parse(props.location.search);
  const [certificate, setCertificate] = useState(null);
  const getOneCertificate = () => {
    getOneCertificatesAPI({
      _id: certificate_number,
    }).then((res) => {
      setCertificate(res.data);
      console.log("res.data", res.data);
    });
  };

  function printCertificate() {
    var divContents = document.getElementById("certificate").innerHTML;
    var a = window.open("", "", "height=500, width=500");
    a.document.write("<html>");
    a.document.write("<body >");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  }

  useEffect(() => {
    if (certificate_number) {
      getOneCertificate();
    }
  }, [certificate_number]);


  return (
    <React.Fragment>
      <div id="certificate">
        {certificate?.templete && parse(certificate.templete)}
      </div>
      <button onClick={printCertificate}>Print</button>
      <i
        onClick={() => {
          navigator.clipboard.writeText(
            `${certificateViewUrl}${certificate.data.certificate_number}`
          );
          AlertMessage(`Link Copyed`);
        }}
        className="simple-icon-paper-clip fs-20px cursor-pointer c_00508B fw-900 mr-4px ml-20px"
      />
    </React.Fragment>
  );
};

export default StudentCertificate;
