import React, { useState } from "react";
import { Card } from "reactstrap";
import parse from "html-react-parser";
import pendingIcon from '../../../../../assets/img/icons/others/wap_pending.png'
import sentIcon from '../../../../../assets/img/icons/others/wap_sent.png'
import deliveredIcon from '../../../../../assets/img/icons/others/wap_delivered.png'
import readIcon from '../../../../../assets/img/icons/others/wap_read.png'


const statusImages = {
  verified: readIcon,
  rejected: readIcon,
  read: readIcon,
  delivered: deliveredIcon,
  sent: sentIcon,
  pending: pendingIcon,
  failed: pendingIcon,
}


const processTest = (text) => {
  const boldPattern = /\*(.*?)\*/g;
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  let processedText = text
    ?.replace(/\n/g, "<br/>")
    .replace(boldPattern, "<b>$1</b>");

  processedText = processedText.replace(
    urlRegex,
    '<a href="$1" target="_blank">$1</a>'
  );

  return parse(processedText);
};

const MessageCard = (props) => {
  let { chat } = props;
  let { status } = chat;
  let [isListOpen, setIsListOpen] = useState(false);
  var time = new Date(chat.createdAt);
  time = time.toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  let bodyMessage = "";
  let header = "";
  let list = [];
  let listType = "list";
  let listTitle = "";
  if (chat.messages_type === "text") {
    bodyMessage = processTest(chat?.payload?.body || "");
  } else if (chat.messages_type === "interactive") {
    let payload = chat.payload;
    if (payload.type === "list" || payload.type === "button") {
      bodyMessage = payload?.body?.text || "";
      bodyMessage = processTest(bodyMessage);
      header =
        payload?.header?.type === "image" ? payload?.header.image.link : "";

      if (payload.type === "list") {
        listTitle = payload.action?.button;
        payload.action?.sections[0]?.rows?.map((row) => {
          list.push(row);
        });
      } else {
        listType = "button";
        payload?.action?.buttons?.map((row) => list.push(row.reply));
      }
    } else if (payload.type === "list_reply") {
      bodyMessage = payload.list_reply.title;
      if (payload.list_reply.description) {
        bodyMessage =
          bodyMessage +
          `\n<span class="text-muted">${payload.list_reply.description}</span>`;
        bodyMessage = processTest(bodyMessage);
      }
    }
  } else if (chat.messages_type === "template") {
    bodyMessage = "You Sent WAP Templete\n*Name:* " + chat.payload.name;
    bodyMessage = processTest(bodyMessage);
  } else if (chat.messages_type === "image") {
    header = chat?.payload?.link;
    bodyMessage = chat.payload.caption;
    bodyMessage = processTest(bodyMessage);
  } else if (chat.messages_type === "button") {
    bodyMessage = chat.payload?.text || chat.payload?.payload || "Some Error"
    bodyMessage = processTest(bodyMessage);
  }

  return (
    <>
      <Card
        className={`p-2  br-10px brtl-0px min-w-100px max-w-332px mb-3 pb-0 float-${chat.sender_or_receiver === "sender" ? "right" : "left"
          }`}
      >
        <div className="">
          {header && (
            <img alt="chat_image" className="chat-header-image" src={header} />
          )}
          <p className="mb-8px text-semi-muted c_black-68p">{bodyMessage}</p>
        </div>
        <div className="d-flex justify-content-flex-end mt-n10px">
          <span className="text-extra-small fs-12px">{time} {chat.sender_or_receiver === "sender" && <img alt="techsimplus" className="w-15px" src={statusImages[status]} />}</span>
        </div>
        {listType === "list" && listTitle && (
          <React.Fragment>
            <p
              onClick={() => {
                setIsListOpen(!isListOpen);
              }}
              className={`btn m-0 ml-n10px ${isListOpen ? "" : "mb-n8px"
                } mr-n10px btn-dark br-10px`}
            >
              {listTitle}
            </p>
            {isListOpen &&
              list.map((x, i) => {
                return (
                  <div className="d-flex justify-content-center pl-20px align-items-center flex-direction-column shadow-box br-10px m-2 h-51px">
                    <span className="fw-900"> {x.title}</span>
                    {x.description && (
                      <span className="fw-500 text-muted">
                        {" "}
                        {x.description}
                      </span>
                    )}
                  </div>
                );
              })}
          </React.Fragment>
        )}

        {listType === "button" && (
          <div className="d-flex w-100percent">
            {list.map((x, i) => {
              return (
                <div className="d-flex justify-content-center align-items-center flex-direction-column shadow-box br-10px m-2 h-35px w-100percent">
                  <span className="fw-900"> {x.title}</span>
                  {x.description && (
                    <span className="fw-500 text-muted"> {x.description}</span>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </Card>
      <div className="clearfix" />
    </>
  );
};

export default React.memo(MessageCard);
