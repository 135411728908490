import React, { useEffect, useState } from "react";
import { Button, Card, Row } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Colxx } from "../../../../../components/common/CustomBootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  errorHandler,
  getAllStudentsDataAction,
  getAllUsersDataAction,
} from "../../../../../redux/master/actions";
import {
  backgroundOptions,
  defaultData,
  degreeOptions,
  graduationYear,
  whoYouAre,
} from "../../../../../constants/selectOptions";
import { requiredFieldValidation } from "../../../../../helpers/Utils";
import AlertMessage from "../../../../../helpers/alerts";
import {
  createNewStudentAPI,
  updateStudentsAPI,
} from "../../../../../services/master";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuthRole } from "../../../../../helpers/hooks";

const AddEditSection = (props) => {
  const {
    searchObj,
    setIsAdding,
    setIsEditing,
    isEditing,
    currentData,
    setCurrentData,
  } = props;

  const history = useHistory();

  const defaultValues = {
    user: null,
    name: "",
    mobile: "",
    wapMobile: "",
    email: "",
    image: "",
    category: "",
    education_background: "",
    institute_name: "",
    subject: "",
    year: "",
    designation: "",
    discountPercentage: 5,
    availableDiscountPercentage: 0,
    totalPoints: 0,
  };
  const [userSearch, setUserSearch] = useState("");
  const [values, setValues] = useState(defaultValues);
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const dispatch = useDispatch();
  const { users: allUsers } = useSelector((state) => state.master.allUsers);
  const currentUser = useSelector((state) => state.authUser.currentUser);
  const { isAdmin } = useAuthRole(currentUser);

  const onEditClick = () => {
    let valuesObj = {
      user: userSelectOptions.find((x) => x.value === currentData?.user?.id),
      name: currentData.name,
      mobile: currentData.mobile,
      wapMobile: currentData.wapMobile || "",
      email: currentData.email,
      image: currentData.image,
      totalPoints: currentData.totalPoints || 0,
      availableDiscountPercentage: currentData.availableDiscountPercentage || 0,
      discountPercentage: currentData.discountPercentage || 5,
      category: whoYouAre.find((x) => x.value === currentData.category),
      education_background: backgroundOptions.find(
        (x) => x.value === currentData.education_background
      ),
      institute_name: currentData.institute_name,
      subject: degreeOptions.find((x) => x.value === currentData.subject),
      year: graduationYear.find((x) => x.value === currentData.year),
      designation: currentData.designation,
      referal_code: currentData.referal_code,
    };
    setValues(valuesObj);
    setIsEditing(true);
  };

  const onSubmitHandler = () => {
    let [allFieldExist, fieldName] = requiredFieldValidation(values, [
      "designation",
      "image",
      "user",
    ]);

    if (!allFieldExist) {
      AlertMessage("Field - " + fieldName, "error", "This field required.");
      return;
    }

    let valuesObj = {
      ...values,
      user: values?.user?.value ? values?.user?.value : null,
      category: values.category.value,
      education_background: values.education_background.value,
      subject: values.subject.value,
      year: values.year.value,
    };

    var finalMethod = createNewStudentAPI;
    if (isEditing) {
      finalMethod = updateStudentsAPI;
    }

    finalMethod(valuesObj, { _id: isEditing ? currentData.id : 0 }).then(
      (res) => {
        dispatch(getAllStudentsDataAction(searchObj));
        setIsAdding(false);
        setIsEditing(false);
        setCurrentData(null);
        props.history.push(`${props.history.location.pathname}`);
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };

  const customFilter = (option, searchText) => {
    if (
      option.data.email.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.label.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (currentData && isEditing) {
      onEditClick();
    }
  }, [currentData, isEditing, userSelectOptions]);

  useEffect(() => {
    if (userSearch && userSearch?.length > 2)
      dispatch(getAllUsersDataAction({ query: userSearch, perPage: 50 }));
  }, [userSearch]);

  useEffect(() => {
    let allRows = [];
    allUsers?.map((x) => {
      allRows.push({
        label: x.name,
        value: x.id,
        email: x.username,
      });
    });

    if (currentData?.user) {
      allRows.push({
        label: currentData?.user?.name,
        value: currentData?.user?.id,
        email: currentData?.user.email,
      });
    }

    setUserSelectOptions(allRows);
  }, [allUsers, currentData]);

  return (
    <React.Fragment>
      <Colxx lg="12" xl="12">
        <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
          <div className="d-flex flex-grow-1 min-width-zero">
            <div className="card-body accordion-content pt-0">
              <Row className="mt-30px">
                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Select User{" "}
                    <i
                      onClick={() => {
                        history.push(
                          "/dashboard/master-data/users?action=add-new"
                        );
                      }}
                      className="iconsminds-add cursor-pointer c_ed7117 mr-4px"
                    />
                  </label>
                  <Select
                    isClearable
                    value={values.user}
                    onInputChange={(text) => {
                      setUserSearch(text);
                    }}
                    onChange={(data) => {
                      setValues({
                        ...values,
                        user: data,
                        name: data ? data?.label : "",
                        email: data ? data?.email : "",
                      });
                    }}
                    filterOption={customFilter}
                    options={userSelectOptions}
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Student Name <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control br-10px"
                    placeholder="ex. First Last Name"
                    value={values.name}
                    onChange={(data) =>
                      setValues({
                        ...values,
                        name: data.target.value,
                      })
                    }
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Mobile Number <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control br-10px"
                    placeholder="ex. 9893762256"
                    value={values.mobile}
                    onChange={(data) =>
                      setValues({
                        ...values,
                        mobile: data.target.value,
                      })
                    }
                  />
                </Colxx>
                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    WhatsApp Number <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control br-10px"
                    placeholder="ex. 9893762256"
                    value={values.wapMobile}
                    onChange={(data) =>
                      setValues({
                        ...values,
                        wapMobile: data.target.value,
                      })
                    }
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Email <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control br-10px"
                    placeholder="ex. student@techsimplus.com"
                    value={values.email}
                    onChange={(data) =>
                      setValues({
                        ...values,
                        email: data.target.value,
                      })
                    }
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Who You are ? <span className="color-red">*</span>
                  </label>
                  <Select
                    isClearable
                    value={values.category}
                    onChange={(data) =>
                      setValues({ ...values, category: data })
                    }
                    options={whoYouAre}
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Education Background <span className="color-red">*</span>
                  </label>
                  <Select
                    isClearable
                    value={values.education_background}
                    onChange={(data) =>
                      setValues({ ...values, education_background: data })
                    }
                    options={backgroundOptions}
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Degree <span className="color-red">*</span>
                  </label>
                  <Select
                    isClearable
                    value={values.subject}
                    onChange={(data) => setValues({ ...values, subject: data })}
                    options={degreeOptions}
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    Passing Year <span className="color-red">*</span>
                  </label>
                  <Select
                    isClearable
                    value={values.year}
                    onChange={(data) => setValues({ ...values, year: data })}
                    options={graduationYear}
                  />
                </Colxx>

                <Colxx lg="3" xl="3" className="mt-10px">
                  <label className="input-label fw-400 fs-14px">
                    {values?.category?.value === defaultData.workingProfessional
                      ? "Company Name"
                      : "Institute Name"}
                    <span className="c_B00020 ml-2px">*</span>
                  </label>
                  <input
                    type={"text"}
                    className="form-control br-10px"
                    placeholder="ex. Institute name"
                    value={values.institute_name}
                    onChange={(data) =>
                      setValues({
                        ...values,
                        institute_name: data.target.value,
                      })
                    }
                  />
                </Colxx>

                <Colxx lg="12" className="mt-30px">
                  <Row>
                    {values?.category?.value ===
                      defaultData.workingProfessional && (
                      <Colxx lg="3" xl="3" className="mt-10px">
                        <label className="input-label fw-400 fs-14px">
                          Designation
                        </label>
                        <input
                          type={"text"}
                          className="form-control br-10px"
                          placeholder="ex. Developer"
                          value={values.designation}
                          onChange={(data) =>
                            setValues({
                              ...values,
                              designation: data.target.value,
                            })
                          }
                        />
                      </Colxx>
                    )}

                    {isEditing && isAdmin && (
                      <Colxx lg="3" xl="3" className="mt-10px">
                        <label className="input-label fw-400 fs-14px">
                          Referal Code
                        </label>
                        <input
                          type={"text"}
                          className="form-control br-10px"
                          placeholder="ex. Developer"
                          value={values.referal_code}
                          onChange={(data) =>
                            setValues({
                              ...values,
                              referal_code: data.target.value,
                            })
                          }
                        />
                      </Colxx>
                    )}

                    {isAdmin && (
                      <Colxx lg="3" xl="3" className="mt-10px">
                        <label className="input-label fw-400 fs-14px">
                          Available Discount
                        </label>
                        <input
                          type={"number"}
                          className="form-control br-10px"
                          placeholder="ex. 0 -10"
                          value={values.availableDiscountPercentage}
                          onChange={(data) =>
                            setValues({
                              ...values,
                              availableDiscountPercentage: data.target.value,
                            })
                          }
                        />
                      </Colxx>
                    )}
                    {isAdmin && (
                      <Colxx lg="3" xl="3" className="mt-10px">
                        <label className="input-label fw-400 fs-14px">
                          Referral Discount
                        </label>
                        <input
                          type={"number"}
                          className="form-control br-10px"
                          placeholder="ex. 0 -10"
                          value={values.discountPercentage}
                          onChange={(data) =>
                            setValues({
                              ...values,
                              discountPercentage: data.target.value,
                            })
                          }
                        />
                      </Colxx>
                    )}

                    {isAdmin && (
                      <Colxx lg="3" xl="3" className="mt-10px">
                        <label className="input-label fw-400 fs-14px">
                          Total Points
                        </label>
                        <input
                          type={"number"}
                          className="form-control br-10px"
                          placeholder="ex. 0 -10"
                          value={values.totalPoints}
                          onChange={(data) =>
                            setValues({
                              ...values,
                              totalPoints: data.target.value,
                            })
                          }
                        />
                      </Colxx>
                    )}

                    <Colxx lg="6" xl="6" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Image Url
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. https://"
                        value={values.image}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            image: data.target.value,
                          })
                        }
                      />
                    </Colxx>
                  </Row>
                </Colxx>

                <div className="d-flex justify-content-flex-end w-100percent">
                  <Button
                    onClick={() => {
                      setIsAdding(false);
                      setIsEditing(false);
                      setValues();
                      props.history.push(`${props.history.location.pathname}`);
                    }}
                    size={"sm"}
                    color="dark"
                    className="br-20px c_white shadow-box create-new-button mt-30px"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={onSubmitHandler}
                    size={"sm"}
                    color="secondry"
                    className="br-20px c_white shadow-box create-new-button mt-30px"
                  >
                    {isEditing ? "Update" : "Create"}
                  </Button>
                </div>
              </Row>
            </div>
          </div>
        </Card>
      </Colxx>
    </React.Fragment>
  );
};

export default AddEditSection;
