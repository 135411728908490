import React, { useEffect } from "react";
import { Row } from "reactstrap";
import IconCardsCarousel from "../../../../containers/dashboards/IconCardsCarousel";
import { Colxx } from "../../../../components/common/CustomBootstrap";

import { useSelector, useDispatch } from "react-redux";
import { getDashboardDataAction } from "../../../../redux/company/actions";
import { GreetStudent } from "../../../../helpers/Utils";
import ToDoApp from "../todos";
import Enquires from "../masterData/enquire";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.company.dashboardData);
  const currentUser = useSelector((state) => state.authUser.currentUser);
  useEffect(() => {
    dispatch(getDashboardDataAction());
  }, []);

  return (
    <>
      <Row className="mb-14px">
        <p className="fs-32px fw-600 ">
          {GreetStudent()}!{" "}
          <span className="fw-900 c_FFA726">{currentUser.name}</span>
        </p>
      </Row>
      <Row>
        <Colxx lg="12" xl="12">
          <IconCardsCarousel values={dashboardData} {...props} />
        </Colxx>
      </Row>
      <Row>
        <Colxx lg="12" xl="12">
          <Enquires fromDashboard = {true} />
        </Colxx>

        <Colxx lg="12" xl="12">
          <ToDoApp asComponent={true} {...props} />
        </Colxx>
      </Row>
    </>
  );
};

export default Dashboard;
