import React, { useState, useEffect } from "react";
import { Button, Card, Row, Collapse } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../../components/common/CustomBootstrap";

import CourseBasicDetails from "./basic";
import CourseDifferentAssignment from "./assign";
import CourseCriteria from "./criteria";
import CourseContent from "./content";
import CourseSEODetails from "./seo";
import CourseKeyPoints from "./keyPoints";
import CourseSEOUrls from "./urls";
import CourseWhatsappDetails from "./whatsapp";
const queryString = require("query-string");

const AddNewCourse = (props) => {
  let { course_id, tabIndex } = queryString.parse(props.location.search);
  let defaultTabIndex = 0;
  if (tabIndex) {
    try {
      defaultTabIndex = parseInt(tabIndex);
    } catch {
      defaultTabIndex = 0;
    }
  }
  const [showingIndex, setShowIndex] = useState(defaultTabIndex);

  return (
    <React.Fragment>
      <Row>
        <Colxx xxs="12" className="mb-2">
          <Card className="d-flex mb-3 br-14px" key={`faqItem_0`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <span
                color="link"
                className="card-body btn-empty cursor-pointer btn-link fw-600 fs-20px list-item-heading text-left text-one "
                onClick={() => {
                  setShowIndex(0);
                  if (course_id) {
                    props.history.push(
                      `${props.history.location.pathname}?course_id=${course_id}&tabIndex=0&action=edit`
                    );
                  }
                }}
                aria-expanded={showingIndex === 0}
              >
                Course Basic Details
              </span>
            </div>
            <Collapse isOpen={showingIndex === 0}>
              <CourseBasicDetails {...props} />
            </Collapse>
          </Card>
        </Colxx>

        {props.isEditing && (
          <Colxx xxs="12" className="mb-2">
            <Card className="d-flex mb-3 br-14px" key={`faqItem_0`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <span
                  color="link"
                  className="card-body btn-empty cursor-pointer fw-600 fs-20px btn-link text-left text-one "
                  onClick={() => {
                    setShowIndex(5);
                    props.history.push(
                      `${props.history.location.pathname}?course_id=${course_id}&tabIndex=5&action=edit`
                    );
                  }}
                  aria-expanded={showingIndex === 5}
                >
                  Course Importent Key Points
                </span>
              </div>
              <Collapse isOpen={showingIndex === 5}>
                <CourseKeyPoints {...props} />
              </Collapse>
            </Card>
          </Colxx>
        )}

        {props.isEditing && (
          <Colxx xxs="12" className="mb-2">
            <Card className="d-flex mb-3 br-14px" key={`faqItem_0`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <span
                  color="link"
                  className="card-body btn-empty cursor-pointer fw-600 fs-20px btn-link text-left text-one "
                  onClick={() => {
                    setShowIndex(1);
                    props.history.push(
                      `${props.history.location.pathname}?course_id=${course_id}&tabIndex=1&action=edit`
                    );
                  }}
                  aria-expanded={showingIndex === 1}
                >
                  Different Assignments
                </span>
              </div>
              <Collapse isOpen={showingIndex === 1}>
                <CourseDifferentAssignment {...props} />
              </Collapse>
            </Card>
          </Colxx>
        )}

        {props.isEditing && (
          <Colxx xxs="12" className="mb-2">
            <Card className="d-flex mb-3 br-14px" key={`faqItem_0`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <span
                  color="link"
                  className="card-body btn-empty cursor-pointer fw-600 fs-20px btn-link text-left text-one "
                  onClick={() => {
                    setShowIndex(2);
                    props.history.push(
                      `${props.history.location.pathname}?course_id=${course_id}&tabIndex=2&action=edit`
                    );
                  }}
                  aria-expanded={showingIndex === 2}
                >
                  Course Criteria
                </span>
              </div>
              <Collapse isOpen={showingIndex === 2}>
                <div className="card-body accordion-content pt-0">
                  <CourseCriteria {...props} />
                </div>
              </Collapse>
            </Card>
          </Colxx>
        )}

        {props.isEditing && (
          <Colxx xxs="12" className="mb-2">
            <Card className="d-flex mb-3 br-14px" key={`faqItem_0`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <span
                  color="link"
                  className="card-body btn-empty cursor-pointer fw-600 fs-20px btn-link text-left text-one "
                  onClick={() => {
                    setShowIndex(4);
                    props.history.push(
                      `${props.history.location.pathname}?course_id=${course_id}&tabIndex=4&action=edit`
                    );
                  }}
                  aria-expanded={showingIndex === 4}
                >
                  Course Content
                </span>
              </div>
              <Collapse isOpen={showingIndex === 4}>
                <div className="card-body accordion-content pt-1">
                  <CourseContent {...props} />
                </div>
              </Collapse>
            </Card>
          </Colxx>
        )}

        {props.isEditing && (
          <Colxx xxs="12" className="mb-2">
            <Card className="d-flex mb-3 br-14px" key={`faqItem_0`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <span
                  color="link"
                  className="card-body btn-empty cursor-pointer fw-600 fs-20px btn-link text-left text-one "
                  onClick={() => {
                    setShowIndex(3);
                    props.history.push(
                      `${props.history.location.pathname}?course_id=${course_id}&tabIndex=3&action=edit`
                    );
                  }}
                  aria-expanded={showingIndex === 3}
                >
                  Course SEO Details
                </span>
              </div>
              <Collapse isOpen={showingIndex === 3}>
                <div className="card-body accordion-content pt-0">
                  <CourseSEODetails {...props} />
                </div>
              </Collapse>
            </Card>
          </Colxx>
        )}

        {props.isEditing && (
          <Colxx xxs="12" className="mb-2">
            <Card className="d-flex mb-3 br-14px" key={`faqItem_0`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <span
                  color="link"
                  className="card-body btn-empty cursor-pointer fw-600 fs-20px btn-link text-left text-one "
                  onClick={() => {
                    setShowIndex(7);
                    props.history.push(
                      `${props.history.location.pathname}?course_id=${course_id}&tabIndex=7&action=edit`
                    );
                  }}
                  aria-expanded={showingIndex === 7}
                >
                  Course Whatsapp Details
                </span>
              </div>
              <Collapse isOpen={showingIndex === 7}>
                <CourseWhatsappDetails {...props} />
              </Collapse>
            </Card>
          </Colxx>
        )}

        {props.isEditing && (
          <Colxx xxs="12" className="mb-2">
            <Card className="d-flex mb-3 br-14px" key={`faqItem_0`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <span
                  color="link"
                  className="card-body btn-empty cursor-pointer fw-600 fs-20px btn-link text-left text-one "
                  onClick={() => {
                    setShowIndex(6);
                    props.history.push(
                      `${props.history.location.pathname}?course_id=${course_id}&tabIndex=6&action=edit`
                    );
                  }}
                  aria-expanded={showingIndex === 6}
                >
                  Course URL's
                </span>
              </div>
              <Collapse isOpen={showingIndex === 6}>
                <CourseSEOUrls {...props} />
              </Collapse>
            </Card>
          </Colxx>
        )}
      </Row>
    </React.Fragment>
  );
};

export default AddNewCourse;
