import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  addNewBlogTopicsDataAction,
  deleteBlogTopicsDataAction,
  deleteWAPTemplatesDataAction,
  errorHandler,
  getBlogTopicsDataAction,
  getWAPTemplatesDataAction,
  updateBlogTopicsDataAction,
} from "../../../../../redux/master/actions";
import {
  wapTemplateHeaderTypeOptions,
  wapTemplateTypeOptions,
  yesNoOptions,
} from "../../../../../constants/selectOptions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import ViewImageByUrl from "../../../../../components/common/viewImageByUrl";
import { arrRange, requiredFieldValidation } from "../../../../../helpers/Utils";
import { createWAPTemplatesAPI, updateWAPTemplatesAPI } from "../../../../../services/master";

const WAPTemplates = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  let defaultValues = {
    name: "",
    number_of_params: 0,
    header_params: 0,
    body_params: 0,
    footer_params: 0,
    template_string: "",
    template_type: { label: "Text", value: "text" },
    header_type: { label: "Text", value: "text" },
    is_cloud: { label: "Yes", value: true },
    template_params: {}
  };
  const [values, setValues] = useState(defaultValues);

  const dispatch = useDispatch();
  const fetchedData = useSelector((state) => state.master.wapTemplates);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },
      {
        label: "Name",
        field: "name",
        width: 200,
      },

      {
        label: "Number Of Params",
        field: "number_of_params",
        width: 200,
      },

      {
        label: "Is Cloud",
        field: "is_cloud",
        width: 110,
      },


      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  const onSubmitHandler = () => {
    let [isExist, field] = requiredFieldValidation(values, [
      "template_string",
    ]);
    if (!isExist) {
      AlertMessage(`Field - ${field}`, "error", "This field required");
      return;
    }

    let newValues = {
      ...values,
      is_cloud: values.is_cloud.value,
      template_type: values.template_type.value,
      header_type: values.header_type.value
    };
    let queryParams = {}
    let finalMethod = createWAPTemplatesAPI
    if (isEditing) {
      queryParams = { _id: currentData.id }
      finalMethod = updateWAPTemplatesAPI
    }

    finalMethod(newValues, queryParams).then((res) => {
      setIsAdding(false)
      setIsEditing(false)
      setCurrentData(null)
      dispatch(getWAPTemplatesDataAction());
    }, err => {
      dispatch(errorHandler(err))
    })
  };


  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: index + 1,
        name: data.name,
        number_of_params: data.number_of_params,
        is_cloud: data.is_cloud ? "Yes" : "No",
        action: (
          <span>
            <i
              onClick={() => {
                onEditClick(data);
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            <i
              onClick={() => {
                setIsDeleting(true);
                setCurrentData(data);
              }}
              className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
            />
          </span>
        ),
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [fetchedData]);

  useEffect(() => {
    dispatch(getWAPTemplatesDataAction());
  }, []);

  const onDeleteClick = (_id) => {
    dispatch(deleteWAPTemplatesDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const onEditClick = (data) => {
    let valuesObj = {
      name: data.name,
      number_of_params: data.number_of_params,
      header_params: data.header_params || 0,
      body_params: data.body_params || 0,
      footer_params: data.footer_params || 0,
      template_string: data.template_string,
      template_params: data.template_params || {},
      is_cloud: yesNoOptions.find((x) => x.value === data.is_cloud),
      template_type: wapTemplateTypeOptions.find((x) => x.value === data.template_type),
      header_type: wapTemplateHeaderTypeOptions.find((x) => x.value === data.header_type),
    };

    setValues(valuesObj);
    setIsEditing(true);
    setCurrentData(data);
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of WhatsApp Templates
              <p className="fs-14px c_black-60p">This is the list of templates.</p>
            </span>
            <div>
              <button
                onClick={() => {
                  setIsAdding(true);
                }}
                className="shadow-box create-new-button"
              >
                Add New Templates
              </button>
            </div>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Row>
        {!isAdding && !isEditing && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div
                  className="card-body accordion-content pt-0"
                  style={{ maxWidth: "100%" }}
                >
                  <MDBDataTable
                    fixed
                    hover
                    entriesOptions={[25, 50, 100, 500, 1000]}
                    entries={25}
                    pagesAmount={4}
                    data={datatable}
                    materialSearch
                    // searchTop
                    fullPagination
                    striped
                    theadColor="dark"
                    searching={false}
                    pagingTop
                    scrollX={true}
                    // scrollY
                    maxHeight={1000}
                    searchBottom={false}
                  // barReverse={true}
                  />
                </div>
              </div>
            </Card>
          </Colxx>
        )}
        {(isAdding || isEditing) && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div className="card-body accordion-content pt-0">
                  <Row className="mt-30px">
                    <Colxx sm="12" md="6" xl="4">
                      <label className="input-label fw-400 fs-14px">
                        Is Cloud Template
                      </label>
                      <Select
                        options={yesNoOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                        value={values.is_cloud}
                        onChange={(data) =>
                          setValues({ ...values, is_cloud: data })
                        }
                      />
                    </Colxx>

                    <Colxx sm="12" md="6" xl="4">
                      <label className="input-label fw-400 fs-14px">
                        Template Type
                      </label>
                      <Select
                        options={wapTemplateTypeOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                        value={values.template_type}
                        onChange={(data) =>
                          setValues({ ...values, template_type: data })
                        }
                      />
                    </Colxx>

                    <Colxx sm="12" md="6" xl="4">
                      <label className="input-label fw-400 fs-14px">
                        Template Header Type
                      </label>
                      <Select
                        options={wapTemplateHeaderTypeOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                        value={values.header_type}
                        onChange={(data) =>
                          setValues({ ...values, header_type: data })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" md="6" xl="6" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Template Name
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. Prateek Mishra"
                        value={values.name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" md="6" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Number Of Params
                      </label>
                      <input
                        type={"number"}
                        className="form-control br-10px"
                        placeholder="ex. 2"
                        value={values.number_of_params}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            number_of_params: data.target.value,
                          })
                        }
                      />
                    </Colxx>
                    <Colxx lg="12" md="6" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Header Params
                      </label>
                      <input
                        type={"number"}
                        className="form-control br-10px"
                        placeholder="ex. 2"
                        value={values.header_params}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            header_params: data.target.value,
                          })
                        }
                      />
                    </Colxx>
                    <Colxx lg="12" md="6" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Body Params
                      </label>
                      <input
                        type={"number"}
                        className="form-control br-10px"
                        placeholder="ex. 2"
                        value={values.body_params}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            body_params: data.target.value,
                          })
                        }
                      />
                    </Colxx>
                    <Colxx lg="12" md="6" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Footer Params
                      </label>
                      <input
                        type={"number"}
                        className="form-control br-10px"
                        placeholder="ex. 2"
                        value={values.footer_params}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            footer_params: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="6" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Local Template String
                      </label>
                      <textarea
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. "
                        value={values.template_string}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            template_string: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="12" className="mt-10px">
                      {
                        values.number_of_params > 0 && <div className="container mt-20px">
                          <p className="fs-20px fw-600">Total {values?.number_of_params} Params Required for this Templates</p>
                          {values?.header_params > 0 && <div>
                            <p className="mt-20px fw-900 mb-n10px">Header Parameter</p>
                            {
                              arrRange(1, values?.header_params, 1).arr?.map(params => {
                                return <Colxx lg="12" xl="6" className="mt-10px">
                                  <label className="input-label fw-400 fs-14px">
                                    Header - {params}
                                  </label>
                                  <input
                                    type={"text"}
                                    className="form-control br-10px"
                                    value={values.template_params[`header${params}`]}
                                    onChange={e => setValues({
                                      ...values, template_params:
                                        { ...values.template_params, [`header${params}`]: e.target.value }
                                    })}
                                  />
                                </Colxx>
                              })
                            }
                          </div>}

                          {values?.body_params > 0 && <div>
                            <p className="mt-20px fw-900 mb-n10px">Body Parameter</p>
                            {
                              arrRange(1, values?.body_params, 1).arr?.map(params => {
                                return <Colxx lg="12" xl="6" className="mt-10px">
                                  <label className="input-label fw-400 fs-14px">
                                    Body Parameter - {params}
                                  </label>
                                  <input
                                    type={"text"}
                                    className="form-control br-10px"
                                    value={values.template_params[`body${params}`]}
                                    onChange={e => setValues({
                                      ...values, template_params:
                                        { ...values.template_params, [`body${params}`]: e.target.value }
                                    })}
                                  />
                                </Colxx>
                              })
                            }
                          </div>}

                          {values?.footer_params > 0 && <div>
                            <p className="mt-20px fw-900 mb-n10px">Footer Parameter</p>
                            {
                              arrRange(1, values?.footer_params, 1).arr?.map(params => {
                                return <Colxx lg="12" xl="6" className="mt-10px">
                                  <label className="input-label fw-400 fs-14px">
                                    Footer Parameter - {params}
                                  </label>
                                  <input
                                    type={"text"}
                                    className="form-control br-10px"
                                    value={values.template_params[`footer${params}`]}
                                    onChange={e => setValues({
                                      ...values, template_params:
                                        { ...values.template_params, [`footer${params}`]: e.target.value }
                                    })}
                                  />
                                </Colxx>
                              })
                            }
                          </div>}

                        </div>
                      }
                    </Colxx>

                    <div className="d-flex justify-content-flex-end w-100percent">
                      <Button
                        onClick={() => {
                          setIsAdding(false);
                          setIsEditing(false);
                          setCurrentData(null);
                          setValues(defaultValues)
                        }}
                        size={"sm"}
                        color="dark"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onSubmitHandler}
                        size={"sm"}
                        color="secondry"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Create
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Card>
          </Colxx>
        )}
      </Row>
    </React.Fragment>
  );
};

export default WAPTemplates;
