function convertArrayOfObjectsToCSV(array) {
  const header = Object.keys(array[0]);
  console.log(header);
  const rows = array.map((obj) => {
    let allValues = [];
    header?.map((allElements) => {
        if(obj[allElements]?.toString().split(",")?.length > 1){
            obj[allElements] = obj[allElements]?.toString().split(",")?.join("_")
        }


      if (obj[allElements] === false) {
        allValues.push("FALSE");
      } else if (obj[allElements] === 0) {
        allValues.push(0);
      } else {
        allValues.push(obj[allElements] || "No DATA");
      }
    });
    return allValues.join(",");
  });

  return `${header.join(",")}\n${rows.join("\n")}`;
}

function downloadCSV(array) {
  const csv = convertArrayOfObjectsToCSV(array);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "data.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default downloadCSV;
