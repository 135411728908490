import React from "react";
import {
  Route,
  withRouter,
  Switch,
  Redirect,
  useHistory,
  Router,
} from "react-router-dom";
import Companies from "./companies";
import CourseCategory from "./course-categories";
import FaQs from "./faqs";
import Files from "./files";
import Institutes from "./institutes";
import JobsTags from "./jobtags";
import TeamMembers from "./teams";
import Technologies from "./technologies";
import Templates from "./templetes";
import Users from "./users";
import Enquires from "./enquire";
import BlogTopic from "./blogTopic";
import WAPTemplates from "./wapTemplates";
import WebTraffic from "./web-traffic";
import FBLeadForms from "./fbLeadForms";
import FBEnquires from "./fbLeads";
import CaApplications from "./caApplication";
import WebSetting from "./setting";
import UTMListDetails from "./utm-list";

const MasterData = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/companies`} />
      <Route path={`${match.url}/companies`} component={Companies} />
      <Route
        path={`${match.url}/course-categories`}
        component={CourseCategory}
      />
      <Route path={`${match.url}/technologies`} component={Technologies} />
      <Route path={`${match.url}/job-tags`} component={JobsTags} />
      <Route path={`${match.url}/faqs`} component={FaQs} />
      <Route path={`${match.url}/users`} component={Users} />
      <Route path={`${match.url}/institutes`} component={Institutes} />
      <Route path={`${match.url}/enquire`} component={Enquires} />
      <Route path={`${match.url}/team-members`} component={TeamMembers} />
      <Route path={`${match.url}/blog-topics`} component={BlogTopic} />
      <Route path={`${match.url}/web-traffic`} component={WebTraffic} />
      <Route path={`${match.url}/facebook-lead-form`} component={FBLeadForms} />
      <Route path={`${match.url}/facebook-leads`} component={FBEnquires} />
      <Route path={`${match.url}/ca-application`} component={CaApplications} />
      <Route path={`${match.url}/setting`} component={WebSetting} />
      <Route path={`${match.url}/utm`} component={UTMListDetails} />
      <Route
        path={`${match.url}/certificate-templetes`}
        component={Templates}
      />
      <Route
        path={`${match.url}/wap-templetes`}
        component={WAPTemplates}
      />
      <Route path={`${match.url}/all-files`} component={Files} />
    </Switch>
  );
};

export default MasterData;
