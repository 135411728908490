import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteWAPUserAction, getOneUserChatAction } from "../../../../../redux/whatsapp/actions";
const queryString = require("query-string");

const ChatHeading = (props) => {
  let { _id } = queryString.parse(props.location.search);
  const activeUser = useSelector((state) => state.wap.activeUser);
  const dispatch = useDispatch()
  const clearChat = () => {
    dispatch(deleteWAPUserAction({ _id: activeUser.id, isChat: true }));
    if (_id) {
      setTimeout(() => dispatch(getOneUserChatAction({ _id })), 200)
    }
  }
  return activeUser ? (
    <div className="d-none d-lg-block">
      <div className="d-flex flex-row mt-n40px">
        <div className="d-flex align-items-center justify-content-spacebetween w-100percent">
          <div className="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between">
            <div className="min-width-zero">
              <div>
                <p className="list-item-heading mb-1 truncate fw-900">
                  {activeUser.name}
                </p>
              </div>
              <p className={`mb-0 ${activeUser.name ? "text-muted text-small" : "list-item-heading mb-1 truncate fw-900"}`}>{activeUser.mobile}</p>
            </div>
          </div>
          <p className="mt-10px fw-900 c_FFA726 cursor-pointer" onClick={clearChat}>Clear Chat</p>
        </div>
      </div>
      <div className="separator mt-n5px" />
    </div>
  ) : null;
};

export default React.memo(ChatHeading);
