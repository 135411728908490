import { useEffect, useState } from "react";

const useAuthRole = (currentUser) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isStudent, setIsStudent] = useState(false);

  useEffect(() => {
    if (currentUser.is_active && currentUser.is_superuser) {
      setIsAdmin(true);
    } else if (
      currentUser.is_active &&
      currentUser.is_staff &&
      !currentUser.is_superuser
    ) {
      setIsStaff(true);
    } else if (
      currentUser.is_active &&
      !currentUser.is_staff &&
      !currentUser.is_superuser
    ) {
      setIsStudent(true);
    }
  }, [currentUser]);

  return { isAdmin, isStaff, isStudent };
};

export { useAuthRole };
