import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { getWAPGroupDataAction, getWAPUsersAction } from "../../../../../redux/whatsapp/actions";
import { processSearchParamsObj } from "../../../../../helpers/Utils";

const BlogFilterSection = (props) => {
  const { searchParams, setSearchParams, groupId, userSelectOptions  = []} = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWAPGroupDataAction())
  }, []);

  const fetchUsersOnParamsChange = () => {
    let obj = processSearchParamsObj(searchParams)
    if (
      (!obj.search || obj.search?.length > 2) &&
      Object.keys(searchParams).length > 0
    ) {
      console.log(obj)
      dispatch(getWAPUsersAction(obj));
    }
  }

  useEffect(() => {
    fetchUsersOnParamsChange()
  }, [searchParams]);


  return (
    <React.Fragment>
      <div className="d-flex">
        <div className="student-filter-section">
          <div className="w-250px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">
              Select Group
            </label>
            <Select
              options={userSelectOptions}
              isClearable
              value={userSelectOptions?.find(x => x.value === groupId)}
              onChange={(data) => {
                if (data) {
                  props.history?.push(`/dashboard/whatsapp/contact?groupId=${data.value}`)
                } else {
                  setSearchParams({ ...searchParams, groupId: undefined })
                  props.history?.push(`/dashboard/whatsapp/contact`)
                  // window.location.href = `/dashboard/whatsapp/contact`
                }
              }}
            />
          </div>



          <div className="w-200px mb-10px mr-10px">
            <label className="input-label fw-400 fs-14px">Search</label>
            <input
              type={"text"}
              className="form-control br-10px"
              placeholder="Search..."
              value={
                searchParams.search ? searchParams.search?.value : ""
              }
              onChange={(data) =>
                setSearchParams({
                  ...searchParams,
                  search: { value: data.target.value },
                })
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlogFilterSection;
