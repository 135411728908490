import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import Select from "react-select";
import {
  Colxx,
  Separator,
} from "../../../../components/common/CustomBootstrap";
import { useSelector, useDispatch } from "react-redux";
import Switch from "rc-switch";
import {
  deleteTodoDataAction,
  errorHandler,
  getAllUsersDataAction,
  getToDosDataAction,
} from "../../../../redux/master/actions";
import DatePicker from "react-datepicker";
import AlertMessage from "../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../components/common/deleteConfirmationPopup";
import { createNewToDoAPI, updateToDoAPI } from "../../../../services/master";
import UserFilterSection from "./todoFilter";
import { useAuthRole } from "../../../../helpers/hooks";
import CustomeTable from "../../../../components/common/table";
import DescriptionViewModel from "./descriptionPopup";

const queryString = require("query-string");

const ToDoApp = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [searchParams, setSearchParams] = useState({});
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const [searchObject, setSearchObject] = useState({});
  let defaultValues = {
    title: "",
    description: "",
    isDescriptionPopUp: false,
    startDate: "",
    isCompleted: false,
    assignedTo: "",
  };
  const { asComponent } = props;
  let { action } = queryString.parse(props.location.search);
  const [values, setValues] = useState(defaultValues);
  const [showDescription, setShowDescription] = useState(null);
  const dispatch = useDispatch();
  const { todos: fetchedData, totalCount } = useSelector(
    (state) => state.master.todos
  );
  const { users: allUsers } = useSelector((state) => state.master.allUsers);

  const currentUser = useSelector((state) => state.authUser.currentUser);
  const { isAdmin, isStaff } = useAuthRole(currentUser);

  const adminColumns = [
    {
      label: "Assigned To",
      field: "assignedTo",
      width: 200,
    },
    {
      label: "Is Completed",
      field: "done",
      width: 200,
    },
    {
      label: "Action",
      field: "action",
      width: 100,
      sort: "disabled",
    },
  ];
  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "#",
        field: "sn",
        width: 60,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },
      {
        label: "Title",
        field: "title",
        width: 250,
      },
      {
        label: "Start Date",
        field: "startDate",
        width: 200,
      },
    ],
    rows: [],
  });

  useEffect(() => {
    if (isAdmin && !asComponent) {
      setDatatable({
        ...datatable,
        columns: [...datatable.columns, ...adminColumns],
      });
    }
  }, [isAdmin]);

  const onPageChange = (e) => {
    setSearchParams({ ...searchParams, ...e });
  };

  const onSubmitHandler = () => {
    if (
      values.title === "" ||
      values.startDate === "" ||
      values.assignedTo === "" ||
      values.assignedTo === null
    ) {
      AlertMessage(
        "All Fields Required.",
        "error",
        "To create/update todo task you have to define all input fields"
      );
      return;
    }

    let newValues = { ...values, assignedTo: values.assignedTo.value };

    let finalMethod = createNewToDoAPI;
    if (isEditing) {
      finalMethod = updateToDoAPI;
    }

    finalMethod(newValues, { _id: isEditing ? currentData.id : 0 }).then(
      (res) => {
        setIsEditing(false);
        setIsAdding(false);
        setCurrentData(false);
        setValues(defaultValues);
        dispatch(getToDosDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  useEffect(() => {
    if (action === "add-new") {
      setIsAdding(true);
      setCurrentData(null);
    }
  }, [action]);

  useEffect(() => {
    let allOptions = [];
    allUsers?.map((user, index) => {
      if (user.is_active && user.is_staff) {
        allOptions.push({
          label: user.first_name + " " + user.last_name,
          value: user.id,
          key: index,
        });
      }
    });

    setUserSelectOptions(allOptions);
  }, [allUsers]);

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: index + 1,
        title: data.title,
        done: data.isCompleted ? "Yes" : "No",
        description: (
          <span className="tooltip_new">
            <i
              onClick={() => setShowDescription(data)}
              className="cursor-pointer simple-icon-note c_FFA726 fw-900"
            />
          </span>
        ),
        assignedTo: data.assignedTo.name,
        isCompleted: (
          <Switch
            className="custom-switch custom-switch-2-inverse custom-switch-small"
            checked={data.isCompleted}
            onChange={(status) =>
              onUserStatusChange(data, "isCompleted", status)
            }
          />
        ),
        isDescriptionPopUp: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.isDescriptionPopUp}
            onChange={(status) => {
              onUserStatusChange(data, "isDescriptionPopUp", status);
            }}
          />
        ),
        startDate: data.startDate
          ? new Date(data.startDate).toLocaleString("en-IN", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "Not LoggedIn",

        action: (
          <span>
            {isAdmin && !asComponent && (
              <i
                onClick={() => {
                  onEditClick(data);
                }}
                className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
              />
            )}

            {isAdmin && !asComponent && (
              <i
                onClick={() => {
                  if (!data.is_superuser && !data.is_staff) {
                    setIsDeleting(true);
                    setCurrentData(data);
                  }
                }}
                className={`simple-icon-trash ${
                  !data.is_superuser && !data.is_staff
                    ? "c_ed7117 cursor-pointer"
                    : "text-disabled fw-900"
                } mr-4px`}
              />
            )}
          </span>
        ),
        clickEvent: () => {
          if (data.description) setShowDescription(data);
        },
      };
      allRows.push(oneRow);
    });

    setDatatable({ ...datatable, rows: allRows });
  }, [fetchedData]);

  useEffect(() => {
    if (asComponent) {
      dispatch(getToDosDataAction({ isAssigned: asComponent, perPage: 100 }));
    }
    if (!asComponent) {
      dispatch(getAllUsersDataAction({ is_staff_and_superuser: true }));
    }
  }, []);

  const onDeleteClick = (_id) => {
    dispatch(deleteTodoDataAction(_id));
    setCurrentData(null);
    setIsDeleting(false);
  };

  const onUserStatusChange = (data, field, status) => {
    if (!data.is_superuser) {
      updateToDoAPI({ [field]: status }, { _id: data.id }).then(
        (res) => {
          dispatch(getToDosDataAction({ isAssigned: true, perPage: 100 }));
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
    } else {
      AlertMessage("This is Super User. You can't deactivate this.");
    }
  };

  const onEditClick = (data) => {
    let valuesObj = {
      title: data.title,
      description: data.description,
      isCompleted: data.isCompleted,
      isDescriptionPopUp: data.isDescriptionPopUp,
      startDate: data.startDate,
      assignedTo: userSelectOptions?.find(
        (user) => user.value === data.assignedTo.id
      ),
    };

    setValues(valuesObj);
    setIsEditing(true);
    setCurrentData(data);
  };

  useEffect(() => {
    if (isStaff) {
      setValues({ ...values, password: "12345678" });
    }
  }, [isStaff]);

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {showDescription && (
        <DescriptionViewModel
          toggleModal={() => setShowDescription(null)}
          modalOpen={showDescription !== null}
          content={showDescription}
          onUserStatusChange={onUserStatusChange}
        />
      )}
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row className={asComponent && "d-none"}>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              {!isAdding && !isEditing ? "List of To-Do's" : ""}
              {isAdding && "Add New ToDo"}
              {isEditing && "Edit ToDo"}
              {!isAdding && !isEditing && (
                <p className="fs-14px c_black-60p">This is the data of task.</p>
              )}
            </span>
            {!isAdding && !isEditing && (
              <div>
                <button
                  onClick={() => {
                    setIsAdding(true);
                  }}
                  className="shadow-box create-new-button"
                >
                  Add New ToDo
                </button>
              </div>
            )}
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!isAdding && !isEditing && !asComponent && (
        <Row className={asComponent && "d-none"}>
          <UserFilterSection
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setSearchObject={setSearchObject}
          />
        </Row>
      )}
      <Row>
        {!isAdding && !isEditing && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div
                  className="card-body accordion-content pt-0"
                  style={{ maxWidth: "100%" }}
                >
                  <CustomeTable
                    paging={!asComponent}
                    onPageChange={onPageChange}
                    datatable={datatable}
                    total={totalCount}
                  />
                </div>
              </div>
            </Card>
          </Colxx>
        )}

        {(isAdding || isEditing) && (
          <Colxx className={asComponent && "d-none"} lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div className="card-body accordion-content pt-0">
                  <Row className="mt-30px">
                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Assigned to <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Select
                        isClearable
                        options={userSelectOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                        value={values.assignedTo}
                        onChange={(data) =>
                          setValues({ ...values, assignedTo: data })
                        }
                      />
                    </Colxx>
                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Task Title <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. task title"
                        value={values.title}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            title: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="4" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Start Date <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <DatePicker
                        selected={
                          values.startDate ? new Date(values.startDate) : null
                        }
                        dateFormat="dd-MMM-yy"
                        onChange={(d) => {
                          setValues({ ...values, startDate: d });
                        }}
                      />
                    </Colxx>

                    <Colxx lg="12" xl="6" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Description<span className="c_B00020 ml-2px">*</span>
                      </label>
                      {/* <Editor
                        data={""}
                        onChange={(data) => {}}
                        onBlurAction={(data) => {}}
                      /> */}
                      <textarea
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="What User have to do in this task."
                        value={values.description}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            description: data.target.value,
                          })
                        }
                      />
                    </Colxx>
                    <Colxx lg="12" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Is Completed<span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Switch
                        className="custom-switch custom-switch-2-inverse custom-switch"
                        checked={values.isCompleted}
                        onChange={(status) => {
                          setValues({ ...values, isCompleted: status });
                        }}
                      />
                    </Colxx>

                    <Colxx lg="12" xl="3" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Is Description PopUp
                        <span className="c_B00020 ml-2px">*</span>
                      </label>
                      <Switch
                        className="custom-switch custom-switch-3-inverse custom-switch"
                        checked={values.isDescriptionPopUp}
                        onChange={(status) => {
                          setValues({ ...values, isDescriptionPopUp: status });
                        }}
                      />
                    </Colxx>

                    <div className="d-flex justify-content-flex-end w-100percent">
                      <Button
                        onClick={() => {
                          setIsAdding(false);
                          setIsEditing(false);
                          setCurrentData(null);
                          setValues(defaultValues);
                          props.history.push(
                            `${props.history.location.pathname}`
                          );
                        }}
                        size={"sm"}
                        color="dark"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onSubmitHandler}
                        size={"sm"}
                        color="secondry"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        {isEditing ? "Update" : "Create"}
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Card>
          </Colxx>
        )}
      </Row>
    </React.Fragment>
  );
};

export default ToDoApp;
