import React, { useState } from "react";
import parse from "html-react-parser";
import Switch from "rc-switch";
import { Modal, ModalHeader } from "reactstrap";

const DescriptionViewModel = ({
  modalOpen,
  toggleModal,
  content,
  onUserStatusChange,
}) => {
  const [buttonStatus, setButtonStatus] = useState(content.isCompleted);

  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName="modal-top"
      // backdrop="static"
    >
      <ModalHeader
        style={{ minHeight: 60, marginTop: -10, paddingBottom: 0 }}
        toggle={toggleModal}
      >
        <p className="fs-20px fw-900">{content?.title}</p>
      </ModalHeader>
      <div className="p-3 fw-500">
        {content?.description && parse(content?.description)}
        <div className="mt-20px d-flex align-items-center justify-content-flex-end">
          <label className="mt-10px mr-10px">Is Completed</label>
          <Switch
            className="custom-switch custom-switch-2-inverse custom-switch"
            checked={buttonStatus}
            onChange={(status) => {
              onUserStatusChange(content, "isCompleted", status);
              setButtonStatus(status);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DescriptionViewModel;
