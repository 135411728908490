import React from "react";
import { Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isAuthenticatedAPI } from "../services/auth";
import { loginUserSuccessAction, setUserTypeAction } from "../redux/actions";

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const subDomain = restOfProps.subDomain;
  const [isAuthenticated, setIsAuthenticated] = React.useState(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
    isAuthenticatedAPI()
      .then((res) => {
        let data = res.data.data;
        if (
          subDomain === process.env.REACT_APP_ADMIN_SUB_DOMAIN &&
          data.is_active &&
          data.is_staff &&
          data.is_superuser
        ) {
          dispatch(setUserTypeAction(process.env.REACT_APP_ADMIN_SUB_DOMAIN));
          dispatch(loginUserSuccessAction(data));
          setIsAuthenticated(true);
        } else if (
          subDomain === process.env.REACT_APP_STAFF_SUB_DOMAIN &&
          data.is_active &&
          data.is_staff &&
          !data.is_superuser
        ) {
          dispatch(setUserTypeAction(process.env.REACT_APP_STAFF_SUB_DOMAIN));
          dispatch(loginUserSuccessAction(data));
          setIsAuthenticated(true);
        } else if (
          subDomain === process.env.REACT_APP_STUDENT_SUB_DOMAIN &&
          data.is_active &&
          !data.is_staff &&
          !data.is_superuser
        ) {
          dispatch(setUserTypeAction(process.env.REACT_APP_STUDENT_SUB_DOMAIN));
          dispatch(loginUserSuccessAction(data));
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          console.log("Error Main");
        }
      })
      .catch((e) => {
        setIsAuthenticated(false);
      });
  }, []);

  if (isAuthenticated === false) {
    window.location.href = process.env.REACT_APP_LOGIN_URL;
  }

  return isAuthenticated === null ? null : (
    <Route
      {...restOfProps}
      render={(props) => (isAuthenticated ? <Component {...props} /> : null)}
    />
  );
};

export { ProtectedRoute };
