// eslint-disable-next-line import/no-cycle
import AlertMessage from "../../helpers/alerts";
import {
  createNewCompanyAPI,
  createNewCourseCategoriesAPI,
  createNewFaQsAPI,
  createNewJobTagsAPI,
  createNewTechnologiesAPI,
  deleteCompanyAPI,
  deleteCourseCategoriesAPI,
  deleteFaQsAPI,
  deleteJobTagsAPI,
  deleteTechnologiesAPI,
  getAllCompaniesListAPI,
  getAllCourseCategoriesListAPI,
  getAllFaQsListAPI,
  getAllJobTagsListAPI,
  getAllTechnologiesListAPI,
  updateCompanyAPI,
  updateCourseCategoriesAPI,
  updateFaQsAPI,
  updateJobTagsAPI,
  updateTechnologiesAPI,
  getAllTempletesListAPI,
  updateTempletesAPI,
  createNewTempletesAPI,
  deleteTempletesAPI,
  getAllFilesListAPI,
  deleteFileAPI,
  uploadFileAPI,
  getAllTeamMemberListAPI,
  createTeamMemberAPI,
  updateTeamMemberAPI,
  deleteTeamMemberAPI,
  getAllCoursesAPI,
  getAllBatchesAPI,
  getAllUsersAPI,
  deleteUserAPI,
  getAllInstitutesAPI,
  deleteInstitutesAPI,
  getAllStudentsAPI,
  deleteStudentsAPI,
  getAllStudentsEnrollAPI,
  deleteStudentsEnrollAPI,
  getAllEnquiresAPI,
  addNewEnquireAPI,
  updateEnquireAPI,
  deleteEnquiresAPI,
  generateBulkCertificatesAPI,
  getAllBlogTopicListAPI,
  createBlogTopicAPI,
  updateBlogTopicAPI,
  deleteBlogTopicAPI,
  getBlogsAPI,
  updateBlogsAPI,
  deleteBlogAPI,
  deleteBatcheAPI,
  getAllWAPTemplatesAPI,
  deleteWAPTemplatesAPI,
  getAllFBLeadFormAPI,
  deleteFBLeadFormAPI,
  getAllFBLeadsAPI,
  deleteFBLeadsAPI,
  getAllToDoListAPI,
  deleteToDoAPI,
} from "../../services/master";
import {
  GET_LIST_OF_COMPANIES,
  GET_LIST_OF_COURSE_CATEGORIES,
  GET_LIST_OF_FAQS,
  GET_LIST_OF_JOB_TAGS,
  GET_LIST_OF_TECHNOLOGIES,
  GET_LIST_OF_TEMPLETES,
  GET_LIST_OF_FILES,
  GET_LIST_OF_TEAM_MEMBERS,
  SET_ONE_COURSE_DETAILS,
  SET_ERRRO_MSG,
  SET_API_PROGRESS,
  SET_ALL_COURSES_LIST,
  SET_ALL_BATCH_LIST,
  SET_ONE_BATCH_DETAILS,
  SET_ALL_USER_LIST,
  SET_ONE_USER_DETAILS,
  SET_ALL_INSTITUTE_LIST,
  SET_ONE_INSTITUTE_DETAILS,
  SET_ALL_STUDENT_LIST,
  SET_ONE_STUDENT_DETAILS,
  SET_ALL_STUDENT_ENROLLMENT_LIST,
  SET_ONE_STUDENT_ENROLLMENT_DETAILS,
  SET_ONE_ENQUIRE_DETAILS,
  SET_ALL_ENQUIRES_LIST,
  GET_LIST_OF_BLOG_TOPICS,
  GET_ALL_BLOG_LIST,
  WAP_GET_ALL_TEMPLATES,
  FB_GET_ALL_LEAD_FORMS,
  FB_GET_ALL_LEADS,
  GET_LIST_OF_TODOS,
} from "../actions";

// --------- Error Handler Data action ------------- //
export const errorHandler = (error) => {
  let msg = { subTitle: "Internal Server Error", msg: "SERVER_ERROR" };
  if (error) {
    try {
      let mainError =
        error?.response?.data?.error || error?.response?.data?.msg;

      try {
        mainError = Array.isArray(mainError[0]) ? mainError[0] : mainError;
      } catch (e) {}

      msg = {
        subTitle: typeof mainError === "string" ? mainError : mainError.msg,
        msg: "",
      };

      if (mainError?.field) {
        msg = {
          ...msg,
          msg: "Field - " + mainError.field?.toUpperCase(),
        };
      }
    } catch {}
  } else {
    msg = error;
  }

  return (dispatch) => {
    dispatch({ type: SET_ERRRO_MSG, payload: msg });
  };
};

export const setAPISuccessAction = (progress) => {
  return (dispatch) => {
    dispatch({ type: SET_API_PROGRESS, payload: progress });
  };
};

// --------- List of Companies Data Actions ------------- //
export const getCompaniesDataAction = () => {
  return (dispatch) => {
    getAllCompaniesListAPI().then(
      (response) => {
        dispatch({ type: GET_LIST_OF_COMPANIES, payload: response.data.data });
      },
      (error) => {
        dispatch({ type: GET_LIST_OF_COMPANIES, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const addNewCompaniesDataAction = (data, setValues) => {
  return (dispatch) => {
    createNewCompanyAPI(data).then(
      (response) => {
        dispatch(getCompaniesDataAction());
        setValues({ category: "", name: "", image: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const updateCompaniesDataAction = (data, _id, setValues) => {
  return (dispatch) => {
    updateCompanyAPI(data, { _id: _id }).then(
      (response) => {
        dispatch(getCompaniesDataAction());
        setValues({ category: "", name: "", image: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteCompaniesDataAction = (_id) => {
  return (dispatch) => {
    deleteCompanyAPI(_id).then(
      (response) => {
        dispatch(getCompaniesDataAction());
      },
      (error) => {
        console.log("ERROR", error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

// --------- List of Course Categories Data Actions ------------- //
export const getCourseCategoriesDataAction = () => {
  return (dispatch) => {
    getAllCourseCategoriesListAPI().then(
      (response) => {
        dispatch({
          type: GET_LIST_OF_COURSE_CATEGORIES,
          payload: response.data.data,
        });
      },
      (error) => {
        dispatch({ type: GET_LIST_OF_COURSE_CATEGORIES, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const addNewCourseCategoriesDataAction = (data, setValues) => {
  return (dispatch) => {
    createNewCourseCategoriesAPI(data).then(
      (response) => {
        dispatch(getCourseCategoriesDataAction());
        setValues({ name: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const updateCourseCategoriesDataAction = (data, _id, setValues) => {
  return (dispatch) => {
    updateCourseCategoriesAPI(data, { _id: _id }).then(
      (response) => {
        dispatch(getCourseCategoriesDataAction());
        setValues({ name: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteCourseCategoriesDataAction = (_id) => {
  return (dispatch) => {
    deleteCourseCategoriesAPI(_id).then(
      (response) => {
        dispatch(getCourseCategoriesDataAction());
      },
      (error) => {
        console.log("ERROR", error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const getTechnologiesDataAction = () => {
  return (dispatch) => {
    getAllTechnologiesListAPI().then(
      (response) => {
        dispatch({
          type: GET_LIST_OF_TECHNOLOGIES,
          payload: response.data.data,
        });
      },
      (error) => {
        dispatch({ type: GET_LIST_OF_TECHNOLOGIES, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const addNewTechnologiesDataAction = (data, setValues) => {
  return (dispatch) => {
    createNewTechnologiesAPI(data).then(
      (response) => {
        dispatch(getTechnologiesDataAction());
        setValues({ name: "", image: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const updateTechnologiesDataAction = (data, _id, setValues) => {
  return (dispatch) => {
    updateTechnologiesAPI(data, { _id: _id }).then(
      (response) => {
        dispatch(getTechnologiesDataAction());
        setValues({ name: "", image: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteTechnologiesDataAction = (_id) => {
  return (dispatch) => {
    deleteTechnologiesAPI(_id).then(
      (response) => {
        dispatch(getTechnologiesDataAction());
      },
      (error) => {
        console.log("ERROR", error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

// *********** User ToDos Actions ********* //
export const getToDosDataAction = (obj = {}) => {
  return (dispatch) => {
    getAllToDoListAPI(obj).then(
      (response) => {
        dispatch({
          type: GET_LIST_OF_TODOS,
          payload: {
            todos: response?.data?.data ? response?.data?.data : [],
            totalCount: response.data.totalCount,
          },
        });
      },
      (error) => {
        dispatch({ type: GET_LIST_OF_TECHNOLOGIES, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteTodoDataAction = (_id) => {
  return (dispatch) => {
    deleteToDoAPI(_id).then(
      (response) => {
        dispatch(getToDosDataAction());
      },
      (error) => {
        console.log("ERROR", error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

/// ------- Job Tags Technologies -------- //

export const getJobTagsDataAction = () => {
  return (dispatch) => {
    getAllJobTagsListAPI().then(
      (response) => {
        dispatch({
          type: GET_LIST_OF_JOB_TAGS,
          payload: response.data.data,
        });
      },
      (error) => {
        dispatch({ type: GET_LIST_OF_JOB_TAGS, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const addNewJobTagsDataAction = (data, setValues) => {
  return (dispatch) => {
    createNewJobTagsAPI(data).then(
      (response) => {
        dispatch(getJobTagsDataAction());
        setValues({ name: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const updateJobTagsDataAction = (data, _id, setValues) => {
  return (dispatch) => {
    updateJobTagsAPI(data, { _id: _id }).then(
      (response) => {
        dispatch(getJobTagsDataAction());
        setValues({ name: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteJobTagsDataAction = (_id) => {
  return (dispatch) => {
    deleteJobTagsAPI(_id).then(
      (response) => {
        dispatch(getJobTagsDataAction());
      },
      (error) => {
        console.log("ERROR", error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

// ----- FaQ's Section ------ //
export const getFaQDataAction = () => {
  return (dispatch) => {
    getAllFaQsListAPI().then(
      (response) => {
        dispatch({
          type: GET_LIST_OF_FAQS,
          payload: response.data.data,
        });
      },
      (error) => {
        dispatch({ type: GET_LIST_OF_FAQS, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const addNewFaQDataAction = (data, setValues) => {
  return (dispatch) => {
    createNewFaQsAPI(data).then(
      (response) => {
        dispatch(getFaQDataAction());
        setValues({ question: "", answer: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const updateFaQDataAction = (data, _id, setValues) => {
  return (dispatch) => {
    updateFaQsAPI(data, { _id: _id }).then(
      (response) => {
        dispatch(getFaQDataAction());
        setValues({ question: "", answer: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteFaQDataAction = (_id) => {
  return (dispatch) => {
    deleteFaQsAPI(_id).then(
      (response) => {
        dispatch(getFaQDataAction());
      },
      (error) => {
        console.log("ERROR", error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

// ----- Templetes's Section ------ //
export const getTempletesDataAction = () => {
  return (dispatch) => {
    getAllTempletesListAPI().then(
      (response) => {
        dispatch({
          type: GET_LIST_OF_TEMPLETES,
          payload: response.data.data,
        });
      },
      (error) => {
        dispatch({ type: GET_LIST_OF_TEMPLETES, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const addNewTempletesDataAction = (data, setValues) => {
  return (dispatch) => {
    createNewTempletesAPI(data).then(
      (response) => {
        dispatch(getTempletesDataAction());
        setValues({ name: "", templete: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const updateTempletesDataAction = (data, _id, setValues) => {
  return (dispatch) => {
    updateTempletesAPI(data, { _id: _id }).then(
      (response) => {
        dispatch(getTempletesDataAction());
        setValues({ name: "", templete: "" });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteTempletesDataAction = (_id) => {
  return (dispatch) => {
    deleteTempletesAPI(_id).then(
      (response) => {
        dispatch(getTempletesDataAction());
      },
      (error) => {
        console.log("ERROR", error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

// ----- File 's Section ------ //
export const getFilesDataAction = (obj) => {
  return (dispatch) => {
    getAllFilesListAPI(obj).then(
      (response) => {
        dispatch({
          type: GET_LIST_OF_FILES,
          payload: {
            files: response.data.data,
            totalCount: response.data.totalCount,
          },
        });
      },
      (error) => {
        dispatch({ type: GET_LIST_OF_FILES, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const addNewFilesDataAction = (data, setValues) => {
  return (dispatch) => {
    uploadFileAPI(data).then(
      (response) => {
        dispatch(getFilesDataAction());
        setValues({
          category: "",
          file: "",
          file_name: "",
        });
      },
      (error) => {
        console.log(error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteFilesDataAction = (_id) => {
  return (dispatch) => {
    deleteFileAPI(_id).then(
      (response) => {
        dispatch(getFilesDataAction());
      },
      (error) => {
        console.log("ERROR", error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

// --------- List of Team Members Data Actions ------------- //
export const getTeamMemberDataAction = (obj = {}) => {
  return (dispatch) => {
    getAllTeamMemberListAPI(obj).then(
      (response) => {
        dispatch({
          type: GET_LIST_OF_TEAM_MEMBERS,
          payload: response.data.data,
        });
      },
      (error) => {
        dispatch({ type: GET_LIST_OF_TEAM_MEMBERS, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const addNewTeamMemberDataAction = (data, setValues) => {
  return (dispatch) => {
    createTeamMemberAPI(data).then(
      (response) => {
        dispatch(getTeamMemberDataAction());
        dispatch(setAPISuccessAction(true));
        setTimeout(() => {
          dispatch(setAPISuccessAction(false));
        }, 2000);
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

export const updateTeamMemberDataAction = (data, _id, setValues) => {
  return (dispatch) => {
    updateTeamMemberAPI(data, { _id: _id }).then(
      (response) => {
        dispatch(getTeamMemberDataAction());
        dispatch(setAPISuccessAction(true));
        setTimeout(() => {
          dispatch(setAPISuccessAction(false));
        }, 2000);
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteTeamMemberDataAction = (_id) => {
  return (dispatch) => {
    deleteTeamMemberAPI(_id).then(
      (response) => {
        dispatch(getTeamMemberDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

// --------- List of Blog Topic Data Actions ------------- //
export const getBlogTopicsDataAction = (obj = {}) => {
  return (dispatch) => {
    getAllBlogTopicListAPI(obj).then(
      (response) => {
        dispatch({
          type: GET_LIST_OF_BLOG_TOPICS,
          payload: [...response?.data?.data, ...response?.data?.series],
        });
      },
      (error) => {
        dispatch({ type: GET_LIST_OF_BLOG_TOPICS, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const addNewBlogTopicsDataAction = (data, setValues) => {
  return (dispatch) => {
    createBlogTopicAPI(data).then(
      (response) => {
        dispatch(getBlogTopicsDataAction());
        dispatch(setAPISuccessAction(true));
        setTimeout(() => {
          dispatch(setAPISuccessAction(false));
        }, 2000);
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

export const updateBlogTopicsDataAction = (data, _id, setValues) => {
  return (dispatch) => {
    updateBlogTopicAPI(data, { _id: _id }).then(
      (response) => {
        dispatch(getBlogTopicsDataAction());
        dispatch(setAPISuccessAction(true));
        setTimeout(() => {
          dispatch(setAPISuccessAction(false));
        }, 2000);
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteBlogTopicsDataAction = (_id) => {
  return (dispatch) => {
    deleteBlogTopicAPI(_id).then(
      (response) => {
        dispatch(getBlogTopicsDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

// --------- List of WAP Templates Data Actions ------------- //
export const getWAPTemplatesDataAction = (obj = {}) => {
  return (dispatch) => {
    getAllWAPTemplatesAPI(obj).then(
      (response) => {
        dispatch({
          type: WAP_GET_ALL_TEMPLATES,
          payload: response.data.data,
        });
      },
      (error) => {
        dispatch({ type: WAP_GET_ALL_TEMPLATES, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteWAPTemplatesDataAction = (_id) => {
  return (dispatch) => {
    deleteWAPTemplatesAPI(_id).then(
      (response) => {
        dispatch(getWAPTemplatesDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

// --------- List of Enquires Data Actions ------------- //
export const generateBulkCertificatesAction = (data, setValues) => {
  return (dispatch) => {
    generateBulkCertificatesAPI(data).then(
      (response) => {
        dispatch(setAPISuccessAction(true));
        setTimeout(() => {
          dispatch(setAPISuccessAction(false));
        }, 2000);
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

// --------- List of Enquires Data Actions ------------- //
export const getEnquireDataAction = (obj = {}) => {
  return (dispatch) => {
    getAllEnquiresAPI(obj).then(
      (response) => {
        dispatch({
          type: SET_ALL_ENQUIRES_LIST,
          payload: {
            data: response.data.data,
            count: response.data?.count || 0,
          },
        });
      },
      (error) => {
        dispatch({ type: SET_ALL_ENQUIRES_LIST, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const addEnquireDataAction = (data, setValues) => {
  return (dispatch) => {
    addNewEnquireAPI(data).then(
      (response) => {
        dispatch(getEnquireDataAction());
        dispatch(setAPISuccessAction(true));
        setTimeout(() => {
          dispatch(setAPISuccessAction(false));
        }, 2000);
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

export const updateEnquireDataAction = (data, _id) => {
  return (dispatch) => {
    updateEnquireAPI(data, { _id: _id }).then(
      (response) => {
        dispatch(setAPISuccessAction(true));
        setTimeout(() => {
          dispatch(setAPISuccessAction(false));
        }, 2000);
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteEnquireDataAction = (obj = {}) => {
  return (dispatch) => {
    deleteEnquiresAPI(obj).then(
      (response) => {},
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

// ----- Course Details ----- //

export const getAllCourseDataAction = (obj = {}) => {
  return (dispatch) => {
    getAllCoursesAPI(obj).then(
      (response) => {
        dispatch({
          type: SET_ALL_COURSES_LIST,
          payload: response?.data?.data ? response?.data?.data : [],
        });
      },
      (error) => {
        dispatch({ type: SET_ALL_COURSES_LIST, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const getOneCourseDataAction = (_id) => {
  return (dispatch) => {
    if (!_id) {
      dispatch({
        type: SET_ONE_COURSE_DETAILS,
        payload: null,
      });
    } else
      getAllCoursesAPI({ _id: _id }).then(
        (response) => {
          if (response.data.data?.length > 0) {
            dispatch({
              type: SET_ONE_COURSE_DETAILS,
              payload: response.data.data[0],
            });
          } else {
            dispatch({
              type: SET_ONE_COURSE_DETAILS,
              payload: null,
            });
          }
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
  };
};

export const getAllBatchDataAction = (obj = {}) => {
  return (dispatch) => {
    getAllBatchesAPI(obj).then(
      (response) => {
        dispatch({
          type: SET_ALL_BATCH_LIST,
          payload: response?.data?.data ? response?.data?.data : [],
        });
      },
      (error) => {
        dispatch({ type: SET_ALL_BATCH_LIST, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const getOneBatchDataAction = (_id) => {
  return (dispatch) => {
    if (!_id) {
      dispatch({
        type: SET_ONE_BATCH_DETAILS,
        payload: null,
      });
    } else
      getAllBatchesAPI({ _id: _id }).then(
        (response) => {
          if (response.data.data?.length > 0) {
            dispatch({
              type: SET_ONE_BATCH_DETAILS,
              payload: response.data.data[0],
            });
          } else {
            dispatch({
              type: SET_ONE_BATCH_DETAILS,
              payload: null,
            });
          }
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
  };
};

export const deleteBatchDataAction = (_id) => {
  return (dispatch) => {
    deleteBatcheAPI(_id).then(
      (response) => {
        dispatch(getAllBatchDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

// ------- Users API's -------- //
export const getAllUsersDataAction = (obj) => {
  return (dispatch) => {
    getAllUsersAPI(obj).then(
      (response) => {
        dispatch({
          type: SET_ALL_USER_LIST,
          payload: {
            users: response?.data?.data ? response?.data?.data : [],
            totalCount: response.data.totalCount,
          },
        });
      },
      (error) => {
        dispatch({ type: SET_ALL_USER_LIST, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const getOneUserDataAction = (_id) => {
  return (dispatch) => {
    if (!_id) {
      dispatch({
        type: SET_ONE_USER_DETAILS,
        payload: null,
      });
    } else
      getAllUsersAPI({ _id: _id }).then(
        (response) => {
          if (response.data.data?.length > 0) {
            dispatch({
              type: SET_ONE_USER_DETAILS,
              payload: response.data.data[0],
            });
          } else {
            dispatch({
              type: SET_ONE_USER_DETAILS,
              payload: null,
            });
          }
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
  };
};

export const deleteUserDataAction = (_id) => {
  return (dispatch) => {
    deleteUserAPI(_id).then(
      (response) => {
        dispatch(getAllUsersDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};
// ------- Institute API's -------- //
export const getAllInstitutesDataAction = () => {
  return (dispatch) => {
    getAllInstitutesAPI().then(
      (response) => {
        dispatch({
          type: SET_ALL_INSTITUTE_LIST,
          payload: response?.data?.data ? response?.data?.data : [],
        });
      },
      (error) => {
        dispatch({ type: SET_ALL_INSTITUTE_LIST, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const getOneInstituteDataAction = (_id) => {
  return (dispatch) => {
    if (!_id) {
      dispatch({
        type: SET_ONE_INSTITUTE_DETAILS,
        payload: null,
      });
    } else
      getAllInstitutesAPI({ _id: _id }).then(
        (response) => {
          if (response.data.data?.length > 0) {
            dispatch({
              type: SET_ONE_INSTITUTE_DETAILS,
              payload: response.data.data[0],
            });
          } else {
            dispatch({
              type: SET_ONE_INSTITUTE_DETAILS,
              payload: null,
            });
          }
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
  };
};

export const deleteInstituteDataAction = (_id) => {
  return (dispatch) => {
    deleteInstitutesAPI(_id).then(
      (response) => {
        dispatch(getAllUsersDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

// ------- Student API's -------- //
export const getAllBlogsDataAction = (obj) => {
  return (dispatch) => {
    getBlogsAPI(obj).then(
      (response) => {
        dispatch({
          type: GET_ALL_BLOG_LIST,
          payload: {
            blogs: response?.data?.data ? response?.data?.data : [],
            count: response?.data?.count,
          },
        });
      },
      (error) => {
        dispatch({ type: GET_ALL_BLOG_LIST, payload: { blogs: [], count: 0 } });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const updateBlogDataAction = (data, obj) => {
  return (dispatch) => {
    updateBlogsAPI(data, obj).then(
      (response) => {
        dispatch(getAllBlogsDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteBlogDataAction = (_id) => {
  return (dispatch) => {
    deleteBlogAPI(_id).then(
      (response) => {
        dispatch(getAllBlogsDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

// ------- Student API's -------- //
export const getAllStudentsDataAction = (obj) => {
  return (dispatch) => {
    getAllStudentsAPI(obj).then(
      (response) => {
        dispatch({
          type: SET_ALL_STUDENT_LIST,
          payload: response?.data?.data ? response?.data?.data : [],
        });
      },
      (error) => {
        dispatch({ type: SET_ALL_STUDENT_LIST, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const getOneStudentDataAction = (_id) => {
  return (dispatch) => {
    if (!_id) {
      dispatch({
        type: SET_ONE_STUDENT_DETAILS,
        payload: null,
      });
    } else
      getAllStudentsAPI({ _id: _id }).then(
        (response) => {
          if (response.data.data?.length > 0) {
            dispatch({
              type: SET_ONE_STUDENT_DETAILS,
              payload: response.data.data[0],
            });
          } else {
            dispatch({
              type: SET_ONE_STUDENT_DETAILS,
              payload: null,
            });
          }
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
  };
};

export const deleteStudentDataAction = (_id) => {
  return (dispatch) => {
    deleteStudentsAPI(_id).then(
      (response) => {
        dispatch(getAllStudentsDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

// ------- Student API's -------- //
export const getAllStudentEnrollmentDataAction = (obj) => {
  return (dispatch) => {
    getAllStudentsEnrollAPI(obj).then(
      (response) => {
        dispatch({
          type: SET_ALL_STUDENT_ENROLLMENT_LIST,
          payload: response?.data?.data ? response?.data?.data : [],
        });
      },
      (error) => {
        dispatch({ type: SET_ALL_STUDENT_ENROLLMENT_LIST, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const getOneStudentEnrollmentDataAction = (_id) => {
  return (dispatch) => {
    if (!_id) {
      dispatch({
        type: SET_ONE_STUDENT_ENROLLMENT_DETAILS,
        payload: null,
      });
    } else
      getAllStudentsEnrollAPI({ _id: _id }).then(
        (response) => {
          if (response.data.data?.length > 0) {
            dispatch({
              type: SET_ONE_STUDENT_ENROLLMENT_DETAILS,
              payload: response.data.data[0],
            });
          } else {
            dispatch({
              type: SET_ONE_STUDENT_ENROLLMENT_DETAILS,
              payload: null,
            });
          }
        },
        (error) => {
          dispatch(errorHandler(error));
        }
      );
  };
};

export const deleteStudentEnrollmentDataAction = (_id) => {
  return (dispatch) => {
    deleteStudentsEnrollAPI(_id).then(
      (response) => {
        dispatch(getAllStudentEnrollmentDataAction());
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};

/// ------- Facebook Lead Forms -------- //

export const getFBLeadFormDataAction = () => {
  return (dispatch) => {
    getAllFBLeadFormAPI().then(
      (response) => {
        dispatch({
          type: FB_GET_ALL_LEAD_FORMS,
          payload: response.data.data,
        });
      },
      (error) => {
        dispatch({ type: FB_GET_ALL_LEAD_FORMS, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteFBLeadFormDataAction = (_id) => {
  return (dispatch) => {
    deleteFBLeadFormAPI(_id).then(
      (response) => {
        dispatch(getFBLeadFormDataAction());
      },
      (error) => {
        console.log("ERROR", error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};

// ------------ Facebook Leads .---------- ...//

export const getFBLeadsDataAction = (obj = {}) => {
  return (dispatch) => {
    getAllFBLeadsAPI(obj).then(
      (response) => {
        dispatch({
          type: FB_GET_ALL_LEADS,
          payload: {
            data: response.data.data,
            count: response.data?.count || 0,
          },
        });
      },
      (error) => {
        dispatch({ type: FB_GET_ALL_LEADS, payload: {} });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteFBLeadsDataAction = (_id) => {
  return (dispatch) => {
    deleteFBLeadsAPI(_id).then(
      (response) => {
        dispatch(getFBLeadsDataAction());
      },
      (error) => {
        console.log("ERROR", error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};
