import React, { useState, useEffect } from "react";
import { Button, Card, Row } from "reactstrap";
import Switch from "rc-switch";
import {
  Colxx,
  Separator,
} from "../../../../../components/common/CustomBootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteEnquireDataAction,
  errorHandler,
  getAllCourseDataAction,
  getAllUsersDataAction,
  getEnquireDataAction,
  updateEnquireDataAction,
} from "../../../../../redux/master/actions";
import AlertMessage from "../../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../../components/common/deleteConfirmationPopup";
import {
  formateDate,
  processSearchParamsObj,
  requiredFieldValidation,
} from "../../../../../helpers/Utils";
import CustomeTable from "../../../../../components/common/table";
import { enquiriesToContactAPI } from "../../../../../services/whatsapp";
import {
  addNewEnquireAPI,
  assignEnquiryToUserAPI,
  updateEnquireAPI,
  uploadEnquireAPI,
} from "../../../../../services/master";
import { getWAPGroupDataAction } from "../../../../../redux/whatsapp/actions";
import { useAuthRole } from "../../../../../helpers/hooks";

const CaApplications = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [wapGroupSelectOptions, setWapGroupSelectOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [courseSelectOption, setCourseSelectOption] = useState([]);
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const [searchParams, setSearchParams] = useState({
    isCaApplication: { label: "", value: true },
  });
  const [assignedUser, setAssignedUser] = useState(null);

  let defaultValues = {
    name: "",
    email: "",
    mobile: "",
    wapMobile: "",
    institute: "",
    course_name: "",
    specialization: "",
    address: "",
    message: "",
    remark: "",
    passingYear: null,
    isCaApplication:true,
  };
  const [values, setValues] = useState(defaultValues);
  const [filePath, setFilePath] = useState({ filePath: "" });
  const [selectedIds, setSelectedIds] = useState([]);
  const dispatch = useDispatch();
  const { data: fetchedData, count: totalCount } = useSelector(
    (state) => state.master.allEnquires
  );
  const isApiSuccess = useSelector((state) => state.master.isApiSuccess);
  const allCourses = useSelector((state) => state.master.allCourses);
  const allGroups = useSelector((state) => state.wap.allGroups);
  const currentUser = useSelector((state) => state.authUser.currentUser);
  const { isAdmin, isStaff } = useAuthRole(currentUser);
  const { users: allUsers } = useSelector((state) => state.master.allUsers);

  useEffect(() => {
    let allOptions = [];
    allCourses.map((data, index) => {
      allOptions.push({ label: data.name, value: data.id, key: index });
    });

    setCourseSelectOption(allOptions);
  }, [allCourses]);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: <span># </span>,
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },
      {
        label: "Name",
        field: "name",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        width: 250,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },

      {
        label: "WhatsApp Number",
        field: "wapMobile",
        width: 250,
      },

      {
        label: "Institute",
        field: "institute",
        width: 250,
      },

      {
        label: "Institute Address",
        field: "address",
        width: 250,
      },
      {
        label: "Course",
        field: "course_name",
        width: 210,
      },
   
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  });

  const onSubmitHandler = () => {
    let [isExist, field] = requiredFieldValidation(values, [
      "course_name",
      "course",
      "is_closed",
      "institute",
      "message",
    ]);
    if (!isExist) {
      AlertMessage(`Field - ${field}`, "error", "This field required");
      return;
    }

    let newValues = { ...values };
    if (values.course?.value) {
      newValues = { ...newValues, course: values.course?.value };
    }
    var finalMethod = addNewEnquireAPI;
    if (isEditing) {
      finalMethod = updateEnquireAPI;
    }

    finalMethod(newValues, { _id: isEditing ? currentData.id : 0 }).then(
      (res) => {
        let obj = processSearchParamsObj(searchParams);
        dispatch(getEnquireDataAction(obj));
        setIsAdding(false);
        setIsEditing(false);
        setCurrentData(null);
      },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };

  const assignEnquiryToUser = async (isRemove = false) => {
    if (!assignedUser) {
      AlertMessage("Please select user", "error");
      return;
    }

    try {
      await assignEnquiryToUserAPI({
        userId: assignedUser,
        enquiryIds: selectedIds,
        isRemove: isRemove,
      });
      AlertMessage("Enquiry Assigned to Selected User");
      setSelectedIds([]);
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const uploadEnquiriesHandler = async () => {
    try {
      if (!filePath.filePath || filePath.filePath === "")
        AlertMessage("File Path Required", "error");
      await uploadEnquireAPI(filePath);
      setFilePath({ filePath: "" });

      setTimeout(() => {
        let obj = processSearchParamsObj(searchParams);
        dispatch(getEnquireDataAction(obj));
      }, 1000);
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const processChatIds = (_id, isAll = false) => {
    if (!isAll) {
      if (selectedIds.includes(_id)) {
        let remainIds = selectedIds.filter((x) => x !== _id);
        setSelectedIds(remainIds);
      } else {
        setSelectedIds([...selectedIds, _id]);
      }
    } else {
      if (selectedIds?.length > 0) {
        setSelectedIds([]);
      } else {
        let allIds = [];
        fetchedData?.map((x) => allIds.push(x.id));
        setSelectedIds(allIds);
      }
    }
  };

  const onEnquiryStatusChange = (status, _id) => {
    dispatch(updateEnquireDataAction({ is_closed: status }, _id));
    setTimeout(() => {
      let obj = processSearchParamsObj(searchParams);
      dispatch(getEnquireDataAction(obj));
    }, 1000);
  };

  useEffect(() => {
    let allRows = [];
    allUsers?.map((x) => {
      allRows.push({
        label: (
          <span className="">
            <strong>
              {x.first_name} {x.last_name}
            </strong>
            <br /> {x.username}
          </span>
        ),
        value: x.id,
        email: x.username,
        name: x.first_name + " " + x.last_name,
      });
    });
    setUserSelectOptions(allRows);
  }, [allUsers]);

  useEffect(() => {
    let allRows = [];
    allGroups?.map((x) => {
      allRows.push({
        label: x.name,
        value: x.id,
      });
    });
    setWapGroupSelectOptions(allRows);
  }, [allGroups]);

  useEffect(() => {
    let allRows = [];
    fetchedData?.map((data, index) => {
      let oneRow = {
        sn: <span>{index + 1} </span>,
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        institute: data.institute,
        msg: data.message,
        remark: data.remark,
        course_name: data?.course_name,
        course: data.course?.name,
        date: formateDate(data.date),
        next_followup_date: formateDate(data.next_followup_date),
        action: (
          <span>
            <i
              onClick={() => {
                onEditClick(data);
              }}
              className="simple-icon-pencil cursor-pointer c_ed7117 mr-4px"
            />
            {isAdmin && (
              <i
                onClick={() => {
                  setIsDeleting(true);
                  setCurrentData(data);
                }}
                className="simple-icon-trash cursor-pointer c_ed7117 mr-4px"
              />
            )}
          </span>
        ),
        is_closed: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={!data.is_closed}
            onChange={(status) => onEnquiryStatusChange(!status, data.id)}
          />
        ),
        is_student: (
          <Switch
            className="custom-switch custom-switch-3-inverse custom-switch-small"
            checked={data.is_student}
            disabled
          />
        ),
      };
      allRows.push(oneRow);
    });

    let allColumns = datatable.columns;
    let oneColumn = allColumns[0];
    oneColumn = {
      ...oneColumn,
      label: <span># </span>,
    };

    allColumns[0] = oneColumn;

    setDatatable({
      ...datatable,
      rows: allRows,
      columns: allColumns,
    });
  }, [fetchedData, courseSelectOption, selectedIds]);

  useEffect(() => {
    dispatch(getAllCourseDataAction());
    dispatch(getWAPGroupDataAction());
    if (isAdmin)
      dispatch(
        getAllUsersDataAction({ perPage: 1000, page_number: 1, is_staff: true })
      );
  }, [isAdmin]);

  const onDeleteClick = (_id) => {
    if (!_id && selectedIds?.length > 0) {
      selectedIds.map((_id) => {
        dispatch(deleteEnquireDataAction({ _id }));
      });
      setSelectedIds([]);
    } else {
      dispatch(deleteEnquireDataAction({ _id }));
    }

    setCurrentData(null);
    setIsDeleting(false);
    setTimeout(
      () => {
        let obj = processSearchParamsObj(searchParams);
        dispatch(getEnquireDataAction(obj));
      },
      !_id && selectedIds?.length > 0 ? 1000 : 200
    );
  };

  const onEnquiriesToWAPAccount = async () => {
    try {
      if (!selectedGroup) {
        AlertMessage("Whatsapp Group Required", "error");
        return;
      }

      let values = { enquiryIds: selectedIds, selectedGroup, model: "enquiry" };
      await enquiriesToContactAPI(values);
      setSelectedIds([]);
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const onPageChange = (e) => {
    setSearchParams({ ...searchParams, ...e });
  };

  const fetchUsersOnParamsChange = () => {
    let obj = processSearchParamsObj(searchParams);
    if (
      (!obj.search || obj.search?.length > 2) &&
      Object.keys(searchParams).length > 0
    ) {
      dispatch(getEnquireDataAction(obj));
    }
  };

  useEffect(() => {
    fetchUsersOnParamsChange();
  }, [searchParams]);

  const onEditClick = (data) => {
    let valuesObj = {
      name: data.name,
      email: data.email,
      mobile: data.mobile,
      wapMobile:data.wapMobile,
      institute: data.institute,
      address:data.address,
      message: data.message,
      remark: data.remark,
      passingYear:data.passingYear,
      specialization:data.specialization,
      course_name: data.course_name,
    };

    setValues(valuesObj);
    setIsEditing(true);
    setCurrentData(data);
  };

  props = {
    ...props,
    isDeleting,
    setIsDeleting,
    currentData,
    setCurrentData,
    onDeleteClick,
  };

  return (
    <React.Fragment>
      {isDeleting && <DeleteConfirmationPopUp {...props} />}
      <Row>
        <Colxx xxs="12">
          <div className="table-header-container">
            <span className="table-header-title">
              List of Enquires
            </span>
            <div className="d-flex align-items-center">
              {/* <i
                onClick={() => {
                  setEnqueryStatus(!enqueryStatus);
                  dispatch(getEnquireDataAction({ is_closed: !enqueryStatus }));
                }}
                className={`${!enqueryStatus
                  ? "simple-icon-close "
                  : "iconsminds-radioactive"
                  } cursor-pointer fs-32px mr-10px`}
              /> */}
              {selectedIds.length === 0 && (
                <button
                  onClick={() => {
                    setIsAdding(true);
                  }}
                  className="shadow-box create-new-button"
                >
                  Add New 
                </button>
              )}

              {selectedIds.length > 0 && (
                <div>
                  <button
                    className="shadow-box create-new-button bg-black-60p ml-10px"
                    onClick={() => {
                      onDeleteClick();
                    }}
                  >
                    Delete Selected Enquires
                  </button>
                </div>
              )}
            </div>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <div className="d-flex mb-20px mt-n20px align-items-center flex-wrap">
        <div className="w-332px mr-10px">
          <label className="input-label fw-400 fs-14px">Search</label>
          <input
            type={"text"}
            className="form-control br-10px"
            placeholder="Search..."
            value={searchParams.search ? searchParams.search?.value : ""}
            onChange={(data) =>
              setSearchParams({
                ...searchParams,
                search: { value: data.target.value },
              })
            }
          />
        </div>
      </div>


      <Row>
        {!isAdding && !isEditing && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div
                  className="card-body accordion-content pt-0"
                  style={{ maxWidth: "100%" }}
                >
                  {selectedIds?.length > 0 && (
                    <div className="mr-10px d-flex justify-content-flex-end mb-n50px mt-10px">
                      <Select
                        placeholder="Select Group"
                        className="w-303px mr-10px"
                        isClearable
                        options={wapGroupSelectOptions}
                        onChange={(data) => {
                          setSelectedGroup(data?.value || null);
                        }}
                      />
                      <button
                        className="shadow-box create-new-button bg-ed7117"
                        onClick={onEnquiriesToWAPAccount}
                      >
                        Add to Whatsapp
                      </button>
                    </div>
                  )}
                  <CustomeTable
                    onPageChange={onPageChange}
                    datatable={datatable}
                    total={totalCount}
                    searchParams={searchParams}
                  />
                </div>
              </div>
            </Card>
          </Colxx>
        )}
        {(isAdding || isEditing) && (
          <Colxx lg="12" xl="12">
            <Card className="d-flex mb-3 br-20px" key={`faqItem_1`}>
              <div className="d-flex flex-grow-1 min-width-zero">
                <div className="card-body accordion-content pt-0">
                  <Row className="mt-30px">

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">Name</label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="Prateek Mishra"
                        value={values.name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Email
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. training@techsimplus.com"
                        value={values.email}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            email: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Mobile Number
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. +91 9893762256"
                        value={values.mobile}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            mobile: data.target.value,
                          })
                        }
                      />
                    </Colxx>


                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        WhatsApp Mobile Number
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="ex. +91 9893762256"
                        value={values.wapMobile}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            wapMobile: data.target.value,
                          })
                        }
                      />
                    </Colxx>


                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Institute Name
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="TechSimPlus"
                        value={values.institute}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            institute: data.target.value,
                          })
                        }
                      />
                    </Colxx>


                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Institute Address
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="Bhoapl, MP"
                        value={values.address}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            address: data.target.value,
                          })
                        }
                      />
                    </Colxx>


                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Course Name
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="Course Name"
                        value={values.course_name}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            course_name: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Course Specialization
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="CSE"
                        value={values.specialization}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            specialization: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        Passing Year
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="Passing Year"
                        value={values.passingYear}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            passingYear: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    <Colxx xs = "12"  className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                      About You ? *
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="About You ?"
                        value={values.message}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            message: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                 
                    <Colxx sm="12" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                      Why do you want to join TechSimPlus Learnings as Campus Ambassador?
                      </label>
                      <input
                        type={"text"}
                        className="form-control br-10px"
                        placeholder="Why you want to join ?"
                        value={values.remark}
                        onChange={(data) =>
                          setValues({
                            ...values,
                            remark: data.target.value,
                          })
                        }
                      />
                    </Colxx>

                    {/* <Colxx lg="12" xl="4" className="mt-10px">
                      <label className="input-label fw-400 fs-14px">
                        {!values.is_closed ? "Close Enquire" : "Re-Open Again"}
                      </label>
                      <Switch
                        className="custom-switch custom-switch-3-inverse custom-switch"
                        checked={!values.is_closed}
                        onChange={(status) =>
                          setValues({ ...values, is_closed: !status })
                        }
                      />
                    </Colxx> */}

                    <div className="d-flex justify-content-flex-end w-100percent">
                      <Button
                        onClick={() => {
                          setIsAdding(false);
                          setIsEditing(false);
                          setCurrentData(null);
                          setValues(defaultValues);
                        }}
                        size={"sm"}
                        color="dark"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onSubmitHandler}
                        size={"sm"}
                        color="secondry"
                        className="br-20px c_white shadow-box create-new-button mt-30px"
                      >
                        Create
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Card>
          </Colxx>
        )}
      </Row>
    </React.Fragment>
  );
};

export default CaApplications;
