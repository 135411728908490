import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Row } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Switch from "rc-switch";
import { QRCode } from "react-qrcode-logo";
import Select from "react-select";
import {
  errorHandler,
  getAllBatchDataAction,
  getAllCourseDataAction,
} from "../../../../redux/master/actions";
import {
  addAttendanceStudentAPI,
  createAttendanceQRAPI,
  deleteAttendanceAPI,
  getAttendanceAPI,
  updateAttendanceAPI,
  updateStudentAttendanceAPI,
} from "../../../../services/master";
import { formateDate, getRandomString } from "../../../../helpers/Utils";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import AlertMessage from "../../../../helpers/alerts";
import DeleteConfirmationPopUp from "../../../../components/common/deleteConfirmationPopup";

const StudentAttendance = () => {
  const [courseSelectOption, setCourseSelectOptions] = useState([]);
  const [courseBatchSelectOptions, setCourseBatchSelectOptions] = useState([]);
  const [attendanceDateOptions, setAttendanceDateOptions] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [qrDelete, setQrDelete] = useState(false);
  const [viewAttendance, setViewAttendance] = useState(false);
  const [studentAttendance, setStudentAttendance] = useState([]);
  const [notShownStudents, setNotShownStudent] = useState([]);

  const dispatch = useDispatch();
  const allBatch = useSelector((state) => state.master.allBatch);
  const allCourses = useSelector((state) => state.master.allCourses);

  useEffect(() => {
    if (allCourses?.length && allBatch?.length) {
      let allRows = [];
      allCourses?.map((x) => {
        const isBatch = allBatch.find(
          (batch) => batch.course.id === x?.id && !batch.is_ended
        );
        isBatch && allRows.push({ label: x.name, value: x.id });
      });
      setCourseSelectOptions(allRows);
    }
  }, [allCourses, allBatch]);

  useEffect(() => {
    dispatch(getAllCourseDataAction());
    dispatch(getAllBatchDataAction());
  }, []);

  useEffect(() => {
    let allRows = studentAttendance
      ?.toSorted((a, b) => a.student.name.localeCompare(b.student.name))
      .map((student, index) => {
        return {
          sn: index + 1,
          name: student.student.name,
          email: student.student.email,
          institute_name: student.student.institute_name,
          isPresent: (
            <>
              <Switch
                className="custom-switch custom-switch-2-inverse custom-switch-small"
                checked={student.isPresent}
                onChange={(status) =>
                  updateAttendanceStatus(status, student.id)
                }
              />
            </>
          ),
          remove: (
            <i
              onClick={() => {
                removeStudentFromAttendance(student._id);
              }}
              className="simple-icon-trash cursor-pointer ml-20px fw-900 c_ed7117 fs-20px"
            />
          ),
        };
      });

    setDatatable({ ...datatable, rows: allRows });
  }, [studentAttendance]);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: <span># </span>,
        field: "sn",
        width: 50,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "id",
        },
        sort: "disabled",
      },

      {
        label: "Name",
        field: "name",
        width: 200,
      },

      {
        label: "Email",
        field: "email",
        width: 250,
      },
      {
        label: "Institute Name",
        field: "institute_name",
        width: 280,
      },

      {
        label: "Is Present",
        field: "isPresent",
        width: 120,
        sort: "disabled",
      },
      {
        label: "Remove",
        field: "remove",
        width: 120,
        sort: "disabled",
      },
    ],
    rows: [],
  });

  const takeAttendance = async () => {
    try {
      setViewAttendance(false);
      var newObj = {};
      Object.keys(searchParams).map((key) => {
        if (searchParams[key]) {
          if (searchParams[key].value.toString().length > 0)
            newObj[key] = searchParams[key].value;
        }
      });
      let { data } = await createAttendanceQRAPI(newObj);
      await getAttendance();
      AlertMessage("QR Code Generated Successfully");
      data = {
        label: formateDate(data.data.date),
        value: data.data.id,
        id: data.data.id,
        unique_number: data.data.unique_number,
        isActive: data.data.isActive,
      };
      setSelectedDate(data);
      if (data) {
        setQrCodeUrl(
          `${process.env.REACT_APP_ATTENDANCE_URL}?qrId=${data.unique_number}`
        );
      } else {
        setQrCodeUrl(null);
      }
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const getAttendance = async () => {
    try {
      var newObj = {};
      Object.keys(searchParams).map((key) => {
        if (searchParams[key]) {
          if (searchParams[key].value.toString().length > 0)
            newObj[key] = searchParams[key].value;
        }
      });
      let attendance = await getAttendanceAPI(newObj);
      if (attendance.data?.data?.length) {
        let allDates = [];
        attendance.data?.data?.map((x) =>
          allDates.push({
            label: formateDate(x.date),
            value: x.id,
            id: x.id,
            unique_number: x.unique_number,
            isActive: x.isActive,
          })
        );

        setAttendanceDateOptions(allDates);
      } else {
        AlertMessage("No Class Found for this Batch.");
      }
    } catch (error) {
      dispatch(errorHandler(error));
    }
    setViewAttendance(false);
  };

  const updateTheQRCodeStatus = async (isActive) => {
    try {
      let obj = { isActive };
      await updateAttendanceAPI(obj, { _id: selectedDate.value });
      AlertMessage("Status Update Successfully");
      setSelectedDate({ ...selectedDate, isActive });
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const updateAttendanceStatus = async (isPresent, _id) => {
    try {
      let obj = { isPresent };
      await updateStudentAttendanceAPI(obj, { _id });
      await getOneDayAttendance();
      AlertMessage("Attendance Update Successfully");
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const deleteAttendance = async (_id) => {
    try {
      await deleteAttendanceAPI({ _id });
      setQrCodeUrl(null);
      setQrDelete(false);
      AlertMessage("QR Code Delete Successfully");
      setAttendanceDateOptions([]);
      setSelectedDate(null);
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const removeStudentFromAttendance = async (_id) => {
    try {
      await deleteAttendanceAPI({ _id, isStudent: true });
      AlertMessage("Student Removed Successfully");
      getOneDayAttendance();
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  const getOneDayAttendance = async () => {
    let attendance = await getAttendanceAPI({ _id: selectedDate.id });
    let enrolledStudents = attendance?.data?.data?.enrolledStudents || [];
    let currentStudents = attendance?.data?.data?.students || [];

    let filteredArray = enrolledStudents.filter(
      (item1) =>
        !currentStudents.some(
          (item2) => item2.student._id === item1.student._id
        )
    );

    filteredArray = filteredArray.map((student) => {
      return {
        label: student?.student?.name,
        value: student._id,
        _id: student._id,
        sId: student?.student?._id,
      };
    });
    setNotShownStudent(filteredArray || []);

    if (attendance?.data?.data?.students?.length) {
      setStudentAttendance(attendance?.data?.data?.students);
      setViewAttendance(true);
    }
  };

  const addStudentToAttendance = async (data) => {
    try {
      await addAttendanceStudentAPI({ _id: data.sId, aId: selectedDate.value });
      AlertMessage("Student Added Successfully");
      getOneDayAttendance();
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };

  return (
    <React.Fragment>
      {qrDelete && (
        <DeleteConfirmationPopUp
          isDeleting={qrDelete}
          setIsDeleting={setQrDelete}
          currentData={selectedDate}
          setCurrentData={setSelectedDate}
          onDeleteClick={deleteAttendance}
        />
      )}
      <Row>
        <div className="d-flex">
          <div className="student-filter-section">
            <div className="w-250px mb-10px mr-10px">
              <label className="input-label fw-400 fs-14px">
                Select Course
              </label>
              <Select
                options={courseSelectOption}
                isClearable
                value={searchParams.course}
                onChange={(data) => {
                  setSearchParams({
                    ...searchParams,
                    course: data,
                    batch: null,
                    query: "",
                  });
                  let allOpt = [];
                  allBatch.map((x) => {
                    if (x.course.id === data?.value) {
                      allOpt.push({ label: x.name, value: x.id });
                    }
                  });
                  setCourseBatchSelectOptions(allOpt);
                }}
              />
            </div>

            <div className="w-250px mb-10px mr-10px">
              <label className="input-label fw-400 fs-14px">Select Batch</label>
              <Select
                options={courseBatchSelectOptions}
                value={searchParams.batch}
                onChange={(data) => {
                  setSearchParams({
                    ...searchParams,
                    batch: data,
                    query: "",
                  });
                  setAttendanceDateOptions([]);
                  setSelectedDate(null);
                  setStudentAttendance([]);
                  setViewAttendance(false);
                }}
              />
            </div>

            {searchParams?.course && searchParams?.batch && (
              <>
                <div>
                  <button
                    onClick={takeAttendance}
                    className="shadow-box create-new-button mt-16px"
                  >
                    Take Attendance
                  </button>
                </div>

                <div>
                  <button
                    onClick={getAttendance}
                    className="shadow-box create-new-button mt-16px ml-10px bg-primary-variant"
                  >
                    Check Attendance
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Row>
      {attendanceDateOptions?.length > 0 && (
        <Row>
          <Colxx lg="3" className="">
            <div className="w-250px mb-10px mr-10px">
              <label className="input-label fw-400 fs-14px">
                Total Classes - {attendanceDateOptions?.length}
              </label>
              <Select
                options={attendanceDateOptions}
                isClearable
                value={selectedDate}
                onChange={(data) => {
                  setSelectedDate(data);
                  setViewAttendance(false);
                  if (data) {
                    setQrCodeUrl(
                      `${process.env.REACT_APP_ATTENDANCE_URL}?qrId=${data.unique_number}`
                    );
                  } else {
                    setQrCodeUrl(null);
                  }
                }}
              />
            </div>
          </Colxx>
          <Colxx lg="2" className="">
            <button
              onClick={getOneDayAttendance}
              className="shadow-box create-new-button mt-26px"
            >
              View Attendance
            </button>
          </Colxx>
        </Row>
      )}

      <Row>
        {qrCodeUrl && selectedDate && !viewAttendance && (
          <Colxx
            sm="12"
            className="display-flex flex-direction-column align-items-center"
          >
            <p className="text-center fs-20px fw-700">
              Please scan this QR code to mark your attendance.
            </p>
            <QRCode
              qrStyle="dots"
              eyeRadius={10}
              bgColor="#ffffff"
              style={{ borderRadius: 20 }}
              fgColor="#00508b"
              size={350}
              removeQrCodeBehindLogo={true}
              logoPadding={0}
              logoPaddingStyle="circle"
              logoImage="https://dev.techsimplus.com/uploads/info/Logos/2024/6/6/e4b0e096-d-qr_logo.png"
              value={qrCodeUrl}
            />

            <div className="mt-20px display-flex align-items-center justify-content-spacebetween">
              <Switch
                className="custom-switch custom-switch-2-inverse custom-switch-small"
                checked={selectedDate.isActive}
                onChange={(status) => updateTheQRCodeStatus(status)}
              />
              <i
                onClick={() => setQrDelete(true)}
                className="simple-icon-trash cursor-pointer ml-20px fw-900 c_ed7117 fs-20px"
              />
            </div>
          </Colxx>
        )}
      </Row>

      {studentAttendance?.length > 0 && viewAttendance && (
        <Row>
          <Card className="d-flex mt-3 mb-3 br-20px" key={`faqItem_1`}>
            <div className="d-flex flex-grow-1 min-width-zero">
              <div
                className="card-body accordion-content pt-0"
                style={{ maxWidth: "100%" }}
              >
                {notShownStudents?.length > 0 && (
                  <div className="d-flex justify-content-flex-end">
                    <div className="w-250px mb-n50px mt-10px">
                      <Select
                        options={notShownStudents}
                        isClearable
                        onChange={(data) => {
                          data && addStudentToAttendance(data);
                        }}
                      />
                    </div>
                  </div>
                )}
                <MDBDataTable
                  fixed
                  hover
                  entriesOptions={[25, 50, 100, 500, 1000]}
                  entries={25}
                  pagesAmount={4}
                  data={datatable}
                  materialSearch
                  // searchTop
                  fullPagination
                  striped
                  theadColor="dark"
                  searching={false}
                  pagingTop
                  scrollX={true}
                  // scrollY
                  maxHeight={1000}
                  searchBottom={false}
                  // barReverse={true}
                />
              </div>
            </div>
          </Card>
        </Row>
      )}
    </React.Fragment>
  );
};

export default StudentAttendance;
