import {
  WAP_GET_ALL_USER,
  WAP_GET_ONE_USER,
  WAP_GET_ONE_USER_CHAT, WAP_GET_ALL_GROUPS, WAP_GET_ALL_BROADCASTS
} from "../actions";

const INIT_STATE = {
  allUsers: {},
  activeUser: null,
  oneUserChat: [],
  allGroups: [],
  allBroadcasts: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WAP_GET_ALL_USER:
      return {
        ...state,
        allUsers: action.payload,
      };
    case WAP_GET_ALL_BROADCASTS:
      return {
        ...state,
        allBroadcasts: action.payload,
      };
    case WAP_GET_ALL_GROUPS:
      return {
        ...state,
        allGroups: action.payload,
      };
    case WAP_GET_ONE_USER:
      return {
        ...state,
        activeUser: action.payload,
      };
    case WAP_GET_ONE_USER_CHAT:
      return {
        ...state,
        oneUserChat: action.payload,
      };

    default:
      return { ...state };
  }
};
