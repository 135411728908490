import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { NotificationContainer } from "./components/common/react-notifications";
import axios from "axios";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import MainApp from "./views/app/index";
import { getSubDomain } from "./helpers/Utils";

const App = (props) => {
  const [isSubDomain, subDomain] = getSubDomain(window.location.href);
  if (
    !isSubDomain ||
    (subDomain !== process.env.REACT_APP_STUDENT_SUB_DOMAIN &&
      subDomain !== process.env.REACT_APP_ADMIN_SUB_DOMAIN &&
      subDomain !== process.env.REACT_APP_INSTITUTE_SUB_DOMAIN &&
      subDomain !== process.env.REACT_APP_STAFF_SUB_DOMAIN)
  ) {
    window.location.href = process.env.REACT_APP_LOGIN_URL;
  }

  return (
    <Provider store={configureStore()}>
      <NotificationContainer />
      {/* <SideOptions /> */}
      <Suspense fallback={<div className="loading" />}>
        <Router>
          <Switch>
            <Route
              path="/dashboard/"
              component={() => <MainApp subDomain={subDomain} />}
            />
            <Redirect to="/dashboard/" />
          </Switch>
        </Router>
      </Suspense>
    </Provider>
  );
};

export default App;
