// eslint-disable-next-line import/no-cycle

import {
  createWAPGroupAPI,
  deleteWAPBroadCastsAPI,
  deleteWAPGroupAPI,
  deleteWAPUsersAPI,
  getAllWAPBroadCastsAPI,
  getAllWAPGroupAPI,
  getAllWAPUsersAPI,
  getOneUserChatAPI,
  updateWAPGroupAPI,
} from "../../services/whatsapp";
import {
  WAP_GET_ALL_USER,
  WAP_GET_ONE_USER,
  WAP_GET_ONE_USER_CHAT, WAP_GET_ALL_GROUPS, WAP_GET_ALL_BROADCASTS
} from "../actions";
import { errorHandler } from "../master/actions";




export const getWAPBroadcastsAction = (obj = {}) => {
  return (dispatch) => {
    getAllWAPBroadCastsAPI(obj).then(
      (response) => {
        dispatch({
          type: WAP_GET_ALL_BROADCASTS,
          payload: response.data?.data || [],
        });
      },
      (error) => {
        dispatch({ type: WAP_GET_ALL_BROADCASTS, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};


export const deleteWAPBroadcastAction = (obj = {}) => {
  return (dispatch) => {
    deleteWAPBroadCastsAPI(obj).then(
      (response) => { },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};



export const getWAPUsersAction = (obj = {}) => {
  return (dispatch) => {
    getAllWAPUsersAPI(obj).then(
      (response) => {
        dispatch({
          type: WAP_GET_ALL_USER,
          payload: response.data,
        });
      },
      (error) => {
        dispatch({ type: WAP_GET_ALL_USER, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const getOneUserChatAction = (obj = {}) => {
  return (dispatch) => {
    getOneUserChatAPI(obj).then(
      (response) => {
        dispatch({
          type: WAP_GET_ONE_USER_CHAT,
          payload: response.data.chats,
        });
        dispatch({
          type: WAP_GET_ONE_USER,
          payload: response.data.user,
        });
      },
      (error) => {
        dispatch({ type: WAP_GET_ONE_USER_CHAT, payload: [] });
        dispatch({ type: WAP_GET_ONE_USER, payload: {} });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteWAPUserAction = (obj = {}) => {
  return (dispatch) => {
    deleteWAPUsersAPI(obj).then(
      (response) => { },
      (error) => {
        dispatch(errorHandler(error));
      }
    );
  };
};






// --------- List of Companies Data Actions ------------- //
export const getWAPGroupDataAction = (obj = {}) => {
  return (dispatch) => {
    getAllWAPGroupAPI(obj).then(
      (response) => {
        dispatch({ type: WAP_GET_ALL_GROUPS, payload: response.data.data });
      },
      (error) => {
        dispatch({ type: WAP_GET_ALL_GROUPS, payload: [] });
        dispatch(errorHandler(error));
      }
    );
  };
};

export const deleteWAPGroupDataAction = (_id) => {
  return (dispatch) => {
    deleteWAPGroupAPI(_id).then(
      (response) => {
        dispatch(getWAPGroupDataAction());
      },
      (error) => {
        console.log("ERROR", error.response);
        dispatch(errorHandler(error));
      }
    );
  };
};